import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { AuthContext } from "src/context/Auth";
import { localeFunction } from "src/utils";
import Apiconfigs from "src/apiConfig/config";

const useStyles = makeStyles((theme) => ({
  RadiusButton: {
    margin: "5px",
  },
  coutryDropDown: {
    marginTop: "10px",
    marginBottom: "5px",
    "& h5": {
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  ButtonDefault: {
    margin: "5px",
    background: "#6FCFB9",
    color: "#fff",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    border: "1px solid #6FCFB9",
  },
  ActiveClass: {
    margin: "5px",
    background: "#fff",
    color: "#6FCFB9",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    border: "1px solid #6FCFB9",
  },
}));
function CommonFilter({
  setRadius,
  selectRadius,
  clearData,
  setCountry,
  setState,
  setCity,
  selectCountry,
  selectState,
  city,
  type,
  breed,
  setSelectState,
  setBreed,
  handleClose,
}) {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [showStates, setShowStates] = useState([]);
  const [showCities, setShowCities] = useState([]);
  const [petBreedList, setPetBreedList] = useState([]);
  const [cities, setCities] = useState([]);
  const auth = useContext(AuthContext);
  const PathVariableName = window.location?.pathname.slice(1);

  const changeCountry = (e) => {
    const name = e.target.value;
    changeCountryList(name);
  };
  const changeState = (e) => {
    const name = e.target.value;
    changeStateList(name);
  };
  const changeCountryList = (name) => {
    const selectted = countries?.filter((cont) => {
      return cont.name === name;
    });

    const contId = selectted[0]?.id;
    const allState = states?.filter((state) => {
      return state.country_id === contId;
    });
    setShowStates(allState);
  };
  const changeStateList = (name) => {
    const selectted = states.filter((cont) => {
      return cont.name === name;
    });

    if (selectted.length !== 0) {
      const contId = selectted[0]?.id;
      const allCity = cities.filter((city) => {
        return city.state_id === contId;
      });
      setShowCities(allCity);
    }
  };
  const PetBreedType = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: Apiconfigs.petBreedList,
        data: { petCategoryId: auth?.userData?.petCategoryId?._id }
      });
      if (res.data.responseCode === 200) {
        setPetBreedList(res?.data?.result);
      }
    } catch (error) { }
  };

  useEffect(() => {
    window.localStorage.setItem("_country", "");
    window.localStorage.setItem("_state", "");
    window.localStorage.setItem("_city", "");
    window.localStorage.setItem("_miles", "");
    window.localStorage.setItem("_breed", "");
    setCountry("");
    setState("");
    setCity("");
    axios.get("/json/countries.json").then(function (response) {
      setCountries(response.data.countries);
      axios.get("/json/states.json").then(function (response) {
        setStates(response.data.states);

        axios.get("/json/cities.json").then(function (response) {
          setCities(response.data.cities);
        });
      });
    });
    PetBreedType();
  }, []);
  const ClearFilter = () => {
    setCountry("");
    setState("");
    setCity("");
    setRadius("0");
    setRadius("0");
    setBreed("0");
    auth.setBreed("0");
    window.localStorage.setItem("_breed", "0");
    window.localStorage.setItem("_state", "");
    window.localStorage.setItem("_city", "");
    window.localStorage.setItem("_country", "");
    window.localStorage.setItem("_miles", "");

  };
  return (
    <Box>
      <Box className={classes.coutryDropDown}>
        <Typography variant="h5">{localeFunction("Filter_text_1")}</Typography>
      </Box>  
      <Box>
        <Button
          className={
            selectRadius === "10"
              ? `${classes.ButtonDefault}`
              : `${classes.ActiveClass}`
          }
          onClick={(e) => {
            setRadius("10");
            window.localStorage.setItem("_miles", 10);
          }}
        >
          {localeFunction("Filter_common_text_1")}
        </Button>{" "}
        <Button
          className={
            selectRadius === "20"
              ? `${classes.ButtonDefault}`
              : `${classes.ActiveClass}`
          }
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setRadius("20");
            window.localStorage.setItem("_miles", 20);
          }}
        >
          {localeFunction("Filter_common_text_2")}
        </Button>{" "}
        <Button
          className={
            selectRadius === "30"
              ? `${classes.ButtonDefault}`
              : `${classes.ActiveClass}`
          }
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setRadius("30");
            window.localStorage.setItem("_miles", 30);
          }}
        >
          {localeFunction("Filter_common_text_3")}
        </Button>{" "}
        <Button
          className={
            selectRadius === "40"
              ? `${classes.ButtonDefault}`
              : `${classes.ActiveClass}`
          }
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setRadius("40");
            window.localStorage.setItem("_miles", 40);
          }}
        >
          {localeFunction("Filter_common_text_4")}
        </Button>{" "}
        <Button
          className={
            selectRadius === "50"
              ? `${classes.ButtonDefault}`
              : `${classes.ActiveClass}`
          }
          variant="contained"
          color="secondary"
          onClick={(e) => {
            setRadius("50");
            window.localStorage.setItem("_miles", 50);
          }}
        >
          {localeFunction("Filter_common_text_5")}
        </Button>{" "}
        <Button
          className={
            selectRadius === "All Followers"
              ? `${classes.ButtonDefault}`
              : `${classes.ActiveClass}`
          }
          variant="contained"
          color="secondary"
          onClick={(e) => {
            if (auth._filterTypePage === "FAVOURITE") {
              setRadius("All Favourites");
              window.localStorage.setItem("_miles", "All Favourites");
            } else if (auth._filterTypePage === "PETS") {
              setRadius("All Pets");
              window.localStorage.setItem("_miles", "All Pets");
            } else if (auth._filterTypePage === "INTRESTED") {
              setRadius("All intrested");
              window.localStorage.setItem("_miles", "All Intrested");
            } else if (auth._filterTypePage === "EVENT") {
              setRadius("All Event");
              window.localStorage.setItem("_miles", "All Event");
            } else if (auth._filterTypePage === "MISSINGPET") {
              setRadius("All Missing Pets");
              window.localStorage.setItem("_miles", "All Missing Pets");
            } else if (auth._filterTypePage === "MARKET") {
              setRadius("All Vendors");
              window.localStorage.setItem("_miles", "All Vendors");
            } else {
              setRadius("All Followers");
              window.localStorage.setItem("_miles", "All Followers");
            }
          }}
        >
          {PathVariableName === "market-doctor" ? "ALL Doctor/VET" : PathVariableName === "market" ? "ALL VENDOR" : PathVariableName === "market-product" ? "ALL VENDOR" : PathVariableName === "market-services" ? "ALL VENDOR" : PathVariableName === "market-pets" ? "ALL VENDOR" : localeFunction("Filter_common_text_6")}
        </Button>
      </Box>
      <Box className={classes.coutryDropDown} mt={3}>
        <Typography variant="h5">
          {auth._filterTypePage === "HOME" &&
            `${localeFunction("Filter_common_text_7")}`}
          {auth._filterTypePage === "FAVOURITE" &&
            `${localeFunction("Filter_common_text_8")}`}
          {auth._filterTypePage === "PETS" &&
            `${localeFunction("Filter_common_text_9")}`}
          {auth._filterTypePage === "INTRESTED" &&
            `${localeFunction("Filter_common_text_10")}`}
          {auth._filterTypePage === "EVENT" &&
            `${localeFunction("Filter_common_text_11")}`}
          {auth._filterTypePage === "MARKET" &&
            `${localeFunction("Filter_common_text_12")}`}
          {auth._filterTypePage === "DOCTORVET" &&
            `${localeFunction("Filter_common_text_16")}`}
          {auth._filterTypePage === "MISSINGPET" &&
            `${localeFunction("Filter_common_text_13")}`}
          {auth._filterTypePage === "MARKETPRODUCT" &&
            `${localeFunction("Filter_common_text_14")}`}
          {auth._filterTypePage === "MARKETSERVICE" &&
            `${localeFunction("Filter_common_text_15")}`}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          sm={4}
          md={type === "global-filter" ? 6 : 3}
          lg={type === "global-filter" ? 6 : 3}
        >
          <Box>
            <Typography variant="body2">
              {localeFunction("signUp_text_22")}
            </Typography>
          </Box>
          <Select
            variant="outlined"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            fullWidth
            style={{ padding: "4px" }}
            className="countryField"
            margin="dense"
            name="country"
            value={selectCountry}
            onChange={(e) => {
              setCountry(e.target.value);

              changeCountry(e);
            }}
          >
            <MenuItem value="">{localeFunction("signUp_text_23")}</MenuItem>

            {countries.map((countries) => {
              return (
                <MenuItem
                  key={countries.name + countries.id}
                  value={countries.name}
                >
                  {countries.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={type === "global-filter" ? 6 : 3}
          lg={type === "global-filter" ? 6 : 3}
        >
          <Box>
            <Typography variant="body2">
              {localeFunction("signUp_text_24")}
            </Typography>
          </Box>
          <Select
            variant="outlined"
            style={{ padding: "4px" }}
            fullWidth
            className="stateField"
            name="state"
            margin="dense"
            value={selectState}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            onChange={(e) => {
              setState(e.target.value);
              changeState(e);
            }}
          >
            <MenuItem value="">{localeFunction("signUp_text_25")}</MenuItem>
            {showStates.length !== 0 &&
              showStates.map((state) => {
                return (
                  <MenuItem key={state?.name + state?.id} value={state?.name}>
                    {state?.name}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={type === "global-filter" ? 6 : 3}
          lg={type === "global-filter" ? 6 : 3}
        >
          <Box>
            <Typography variant="body2">
              {localeFunction("signUp_text_26")}
            </Typography>
          </Box>
          <Select
            variant="outlined"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            style={{ padding: "4px" }}
            fullWidth
            name="city"
            className="cityField"
            margin="dense"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          >
            <MenuItem value="">{localeFunction("Select_city")}</MenuItem>
            {showCities.length !== 0 &&
              showCities.map((city) => {
                return (
                  <MenuItem key={city.name + city.id} value={city.name}>
                    {city.name}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        {(PathVariableName !== "market" &&PathVariableName !== "market-services" &&PathVariableName !== "market-product" &&PathVariableName !== "market-pets" &&PathVariableName !== "market-doctor" && PathVariableName !== "events") && (<>
          <Grid
            item
            xs={6}
            sm={4}
            md={type === "global-filter" ? 6 : 3}
            lg={type === "global-filter" ? 6 : 3}
          >
            <Box>
              <Typography variant="body2">
                {localeFunction("signUp_text_36")}
              </Typography>
            </Box>
            <Select
              variant="outlined"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{ padding: "4px" }}
              fullWidth
              name="breed"
              className="cityField"
              margin="dense"
              value={breed}
              onChange={(e) => {
                setBreed(e.target.value);
                window.localStorage.setItem("_breed", "")
                auth.setBreed(e.target.value)
              }}
            >
              <MenuItem value="0">{localeFunction("Select_breed")}</MenuItem>
              {petBreedList.length !== 0 &&
                petBreedList.map((breed) => {
                  return (
                    <MenuItem key={breed._id} value={breed._id}>
                      {breed.petBreedName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </>)}
        <Grid
          item
          xs={6}
          sm={3}
          md={type === "global-filter" ? 3 : 1}
          lg={type === "global-filter" ? 3 : 1}
        >
          <Box>
            <Typography variant="body2" style={{ visibility: "hidden" }}>
              {localeFunction("signUp_text_26")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2} display={"flex"}>
        <Button
          className={classes.ButtonDefault}
          variant="contained"
          fullWidth
          style={{ height: "50px" }}
          onClick={(item) => ClearFilter()}
        >
          {localeFunction("Add_Missing_Pets_80")}
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          className={classes.ButtonDefault}
          variant="contained"
          fullWidth
          style={{ height: "50px" }}
          onClick={(item) => {
            // if (
            //   window.localStorage.getItem("_country") !== "" ||
            //   window.localStorage.getItem("_state") !== "" ||
            //   window.localStorage.getItem("_city") !== "" ||
            //   window.localStorage.getItem("_miles") !== ""
            // ) {
            auth.ApplyFunction();
            handleClose();
            // }
          }}
        >
          {localeFunction("Apply_filter")}
        </Button>
      </Box>
    </Box>
  );
}

export default CommonFilter;
