export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "break-word",
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "break-word",
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "break-word",
    "@media (max-width:767px)": {
      fontSize: "16px",
    },
  },
  h4: {
    fontWeight: 400,
    fontSize: 20,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "break-word",
    "@media (max-width:767px)": {
      fontSize: "16px",
    },
  },
  h5: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "break-word",
    "@media (max-width:767px)": {
      fontSize: "14px",
    },
  },
  h6: {
    fontWeight: 400,
    fontSize: 16,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "break-word",
    "@media (max-width:767px)": {
      fontSize: "13px",
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "break-word",

    "@media (max-width:767px)": {
      fontSize: "12px",
    },
  },
  body1: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "'Inter', sans-serif",
    wordBreak: "initial",

    "@media (max-width:767px)": {
      fontSize: "10px",
    },
  },

  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Inter', sans-serif",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "'Inter', sans-serif",
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: 11,
    fontFamily: "'Inter', sans-serif",
  },
};
