/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Divider,
  Typography,
  Button,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory, Link } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { BiLogOutCircle } from "react-icons/bi";
import { HiOutlineLockClosed } from "react-icons/hi";
import { MdContactMail, MdHelpOutline, MdSettings } from "react-icons/md";
import { AuthContext } from "src/context/Auth";
import { FaUserLock } from "react-icons/fa";
import { getDataHandlerAPI } from "src/apiConfig/service/index";
import { toast } from "react-toastify";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
// import { async } from "@firebase/util";
// import Apiconfigs from "src/apiConfig/config";
// import Axios from "axios";
import DeactivateModal from "src/component/DeactivateModal";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { localeFunction } from "src/utils";
function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}
function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 280,
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
    "@media (max-width: 767px)": {
      width: "37px",
      height: "37px",
    },
  },

  UserNameClass: {
    display: "flex",
    alignItems: "center",
    "& .UserProfile": {
      width: "50px",
      position: "relative",
      "& .smallImage": {
        margin: "0px",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        position: "absolute",
        bottom: "0px",
        right: "10px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "2px solid #6FCFB9",
        "& img": {
          mixWidth: "100%",
          width: "100%",
          minHeight: "100%",
        },
      },
    },
    "& h4": {
      fontWeight: "600",
      marginLeft: "10px",
    },
  },
  avtarBox: {
    "& .MuiAvatar-img": {
      width: "auto ",
      height: "auto ",
      objectFit: "cover"
    }
  },
  termsPrivay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "10px",
    "& p": {
      "& Button": {
        color: "#918E8E",
        textDecoration: "underline",
        fontSize: "11px",
      },
    },
  },
  menuButtons: {
    justifyContent: "left",
    textTransform: "capitalize",
    color: "#404040",
    fontSize: "14px",
    lineHeight: "30px",
  },
  privateAccountBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h6": {
      fontSize: "15px",
      paddingLeft: "5px",
      fontWeight: "500",
    },
    "& svg": {
      paddingLeft: "12px",
      fontSize: "28px",
      color: "rgb(187,184,184)",
    },
    "& .lockButton": {
      display: "flex",
      alignItems: "center",
    },
  },
  StaticCntentLink: {
    fontSize: " 12px",
    fontWeight: "600",
    color: "#555555a1",
  },
}));

const UseProfileNavBar = ({
  handleClickOpenEdit,
  open,
  setOpen,
  setRightBar,
  rightBar,
  _isloading,
  setIsLoading,
  setDelete,
  open_activate,
  setOpenActivate,
  DeActivate,
  handleActivateAccount,
}) => {
  const auth = useContext(AuthContext);
  const userData = auth?.userData;
  const classes = useStyles();
  const history = useHistory();
  const [switched, setSwitch] = useState(false);
  useEffect(() => {
    if (switched) {
      accountPrivateHandle();
    }
  }, [switched]);
  const accountPrivateHandle = async () => {
    try {
      const data = window.sessionStorage.getItem("token");
      const response = await getDataHandlerAPI({ endPoint: "privateOrPublicAccount", token: data });
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          auth.getUserProfileDatahandler(
            window.sessionStorage.getItem("token")
          );
          toast.success(response.data.responseMessage);
        } else {
          toast.error(response.data.responseMessage);
        }
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) { }
  };
  const sections = [
    {
      title: `${localeFunction("RightDrawer_text_1")}`,
      href: "/view-profile",
      icon: MdContactMail,
    },
    {
      title: `${localeFunction("RightDrawer_text_2")}`,
      href: "/select-language",
      icon: MdSettings,
    },
    {
      title: `${localeFunction("RightDrawer_text_3_1")}`,
      href: "/help-support",
      icon: MdHelpOutline,
    },
    {
      title: `${localeFunction("RightDrawer_text_3")}`,
      href: "/change-password",
      icon: HiOutlineLockClosed,
    },
    {
      title: `${localeFunction("RightDrawer_text_4")}`,
      href: "/dashboard",
      icon: BiLogOutCircle,
    },
  ];

  const SecIcon = sections[4].icon;

  function sectionFil(itms) {
    return itms.filter((val, i) =>
      val?.title === localeFunction("RightDrawer_text_3")
        ? !auth?.userData?.isSocial && val?.title
        : val?.title !== localeFunction("RightDrawer_text_4") && val?.title
    );
  }
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={localeFunction("logout_text1")}
          desc={localeFunction("logout_text")}
        />
      )}
      {open_activate && (
        <DeactivateModal
          open={open_activate}
          _isloading={_isloading}
          handleClose={() => setOpenActivate(false)}
          title={localeFunction("Deactivate_Account")}
          desc={localeFunction("Activate_account_messgae")}
          handleActivateAccount={handleActivateAccount}
        />
      )}
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className="walletbox"
      >
        <Box>
          <Box className={classes.UserNameClass}>
            <Box className="UserProfile">
              <Box
                className={
                  userData?.petPic === undefined
                    ? `${classes.avtarBox}  avatarbox1`
                    : `${classes.avtarBox} avatarbox1 `
                }
              >
                <Avatar
                  style={{
                    border: "2px solid #6fcfb9 !important",
                    borderRadius: "20px  !important",
                    objectFit: "contain !important",
                  }}
                  src={
                    userData?.petPic
                      ? userData?.petPic
                      : "images/dog-placeholder.png"
                  }
                /></Box>
              <figure className="smallImage">
                <img
                  alt=""
                  src={
                    userData?.profilePic
                      ? userData?.profilePic
                      : "/images/user_placeholder.jpg"
                  }
                />
              </figure>
            </Box>
            <Typography variant="h4">
              {userData?.name ? userData?.name : "N/A"}
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box>
          {sectionFil(sections) &&
            sectionFil(sections).map((section, i) => {
              const Icon = section.icon;
              return (
                <>
                  <Button
                    fullWidth
                    className={classes.menuButtons}
                    key={i}
                    onClick={() => {
                      history.push(section.href);
                    }}
                  >
                    <Icon color="#BBB8B8" fontSize="20px" />
                    &nbsp;&nbsp;{section.title}
                  </Button>
                </>
              );
            })}
        </Box>
        <Box>
          <Button
            fullWidth
            className={classes.menuButtons}
            onClick={() => {
              handleClickOpenEdit();
              setDelete("");
            }}
          >
            <MdHelpOutline color="#BBB8B8" style={{ fontSize: "20px" }} />
            &nbsp;&nbsp;
            {localeFunction("RightDrawer_text_5")}
          </Button>
        </Box>
        <Box>
          <Box>
            <Button
              fullWidth
              className={classes.menuButtons}
              onClick={() => DeActivate()}
            >
              <FaUserLock style={{ fontSize: "20px", color: "#bbb8b8" }} />
              &nbsp;&nbsp;
              {localeFunction("Deactivate_Account")}
              {_isloading && <ButtonCircularProgress />}
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth
              className={classes.menuButtons}
              onClick={() => handleClickOpenEdit("DELETE")}
            >
              <DeleteSweepIcon style={{ fontSize: "20px", color: "#bbb8b8" }} />{" "}
              &nbsp;&nbsp;
              {localeFunction("RightDrawer_text_6")}{" "}
              {_isloading && <ButtonCircularProgress />}
            </Button>
          </Box>

          <Box>
            <Button
              fullWidth
              className={classes.menuButtons}
              onClick={() => {
                setOpen(true);
              }}
            >
              <SecIcon color="#BBB8B8" fontSize="20px" />
              &nbsp;&nbsp;{sections[4].title}
            </Button>
          </Box>
          <Box className={classes.privateAccountBox}>
            <Box className="lockButton">
              <FaUserLock />
              &nbsp;
              <Typography variant="h6" style={{ fontSize: "14px" }}>
                {localeFunction("RightDrawer_text_7")}{" "}
                {auth?.userData?.privacyType === "PRIVATE"
                  ? `${localeFunction("RightDrawer_text_7_1")}`
                  : `${localeFunction("RightDrawer_text_7_2")}`}
              </Typography>
            </Box>

            <Switch
              checked={auth?.userData?.privacyType === "PRIVATE"}
              onChange={() => setSwitch(!switched)}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Box>
          <Box style={{ paddingLeft: "35px" }}>
            <Typography variant="body1" style={{ fontSize: "11px" }}>
              {localeFunction("RightDrawer_text_8")}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.termsPrivay}>
          <Link
            className={classes.StaticCntentLink}
            to={{
              pathname: "/privacy&policy",
              state: "home",
              target: "_blank",
            }}
          >
            {localeFunction("RightDrawer_text_9")}
          </Link>
          <Link
            className={classes.StaticCntentLink}
            to={{
              pathname: "/terms&conditions",
              state: "home",
              target: "_blank",
            }}
          >
            {localeFunction("RightDrawer_text_10")}
          </Link>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Box
        className={
          userData?.profilePic === undefined
            ? `${classes.avtarBox}  `
            : `${classes.avtarBox} avatarbox1 `
        }
      >
        <Avatar
          src={
            userData?.profilePic
              ? userData?.profilePic
              : "/images/user_placeholder.jpg"
          }
          className={classes.avatar}
          onClick={() => {
            setRightBar(!rightBar);
          }}
        />
      </Box>
      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
    </>
  );
};

UseProfileNavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default UseProfileNavBar;
