export const socketNotification = process.env.REACT_APP_SOCKET_URL;
export const baseurl = process.env.REACT_APP_BASE_URL;
export const vendorUrl = process.env.REACT_APP_VENDOR_URL;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;
export const agoraAppId = process.env.REACT_APP_AGORA_APP_ID;
let user = `${baseurl}/api/v1/user`;
let admin = `${baseurl}/api/v1/admin`;
let product = `${baseurl}/api/v1/product`;
let services = `${baseurl}/api/v1/service`;
let event = `${baseurl}/api/v1/event`;
let pet = `${baseurl}/api/v1/pet`;
let statics = `${baseurl}/api/v1/static`;
let statics1 = `${baseurl}/api/v1/static/static`;
let category = `${baseurl}/api/v1/category`;
let post = `${baseurl}/api/v1/post`;
let contact = `${baseurl}/api/v1/contact`;
let socket = `${baseurl}/api/v1/socket`;

const Apiconfigs = {
  profile: `${user}/profile`,
  agoraTokenGenerator: `${user}/agoraTokenGenerator`,
  callUser: `${user}/callUser`,
  signup: `${user}/signup`,
  verifyOTP: `${user}/verifyOTP`,
  ignoredUser: `${user}/ignoredUser`,
  resendOTP: `${user}/resendOTP`,
  login: `${user}/login`,
  forgotPassword: `${user}/forgotPassword`,
  resetPassword: `${user}/resetPassword`,
  deactivateUserAccount: `${user}/deactivateUserAccount`,
  editProfile: `${user}/editProfile`,
  changePassword: `${user}/changePassword`,
  listSearchUser: `${user}/listSearchUser`,
  socialLogin: `${user}/socialLogin`,
  addToInterested: `${user}/addToInterested`,
  listInterested: `${user}/listInterested`,
  addMissingPet: `${user}/addMissingPet`,
  missingPetList: `${user}/missingPetList`,
  redeemPoints: `${user}/redeemPoints`,
  redeemPointsList: `${user}/redeemPointsList`,
  notificationList: `${user}/notificationList`,
  isSeen: `${user}/isSeen`,
  clearNotification: `${user}/clearNotification`,
  sharePost: `${user}/sharePost`,
  othersProfile: `${user}/othersProfile`,
  listUser: `${user}/listUser`,
  logOut: `${user}/logOut`,
  viewMissingPet: `${user}/viewMissingPet`,
  editMissingPet: `${user}/editMissingPet`,
  deleteMissingPet: `${user}/deleteMissingPet`,
  listMyRewards: `${user}/listMyRewards`,
  globalSearch: `${user}/globalSearch`,
  privateOrPublicAccount: `${user}/privateOrPublicAccount`,
  listSuggestedUser: `${user}/listSuggestedUser`,
  requestList: `${user}/requestList`,
  acceptOrDenyRequest: `${user}/acceptOrDenyRequest`,
  viewStatus: `${user}/viewStatus`,
  ipGet: `${user}/ip-get`,
  updateStatus: `${user}/updateStatus`,
  addUserProfile: `${user}/addUserProfile`,
  setDefaultUserLanguage: `${user}/setDefaultUserLanguage`,

  canceleAppointment: `${user}/canceleAppointment`,
  userProfileList: `${user}/userProfileList`,
  setDefaultUserProfile: `${user}/setDefaultUserProfile`,
  userProfileUpdate: `${user}/userProfileUpdate`,
  deleteUserAccount: `${user}/deleteUserAccount`,
  googlePlaces: `${user}/googlePlaces`,
  getCurrencyOne: `${user}/getCurrencyOne`,
  listVendor: `${user}/listVendor`,
  viewUser: `${user}/viewUser`,
  addAppointment: `${user}/addAppointment`,
  availableSlot: `${user}/availableSlot`,
  listUserAppointment: `${user}/listUserAppointment`,
  viewAppointment: `${user}/viewAppointment`,
  listBlockDoctorUser: `${user}/listBlockDoctorUser`,
  addBlockDoctorUser: `${user}/addBlockDoctorUser`,
  removeBlockDoctorUser: `${user}/removeBlockDoctorUser`,
  addFeedbacknrating: `${user}/addFeedbacknrating`,

  //Admin
  viewRewardsPoints: `${admin}/viewRewardsPoints`,
  listBanner: `${admin}/listBanner`,
  languageList: `${admin}/languageList`,
  languageUpdate: `${admin}/languageUpdate`,
  listExplore: `${admin}/listExplore`,
  listAppointment: `${admin}/listAppointment`,

  // follow unfollow

  followUnfollowUser: `${user}/followUnfollowUser/`,
  followerUserList: `${user}/followerUserList`,
  followingUserList: `${user}/followingUserList`,
  tagPeopleList: `${user}/tagPeopleList`,
  otherfollowerUserList: `${user}/otherfollowerUserList`,
  otherfollowingUserList: `${user}/otherfollowingUserList`,

  // product
  // listProduct: `${product}/listProduct`,
  viewProduct: `${product}/viewProduct`,
  listProduct: `${product}/listProductV2`,

  // like product
  likeUnlikeProduct: `${product}/likeUnlikeProduct`,
  myLikesProduct: `${product}/myLikesProduct`,

  //services
  listService: `${services}/listServiceV2`,
  viewService: `${services}/viewService`,
  myLikesServices: `${services}/myLikesServices`,
  likeUnlikeServices: `${services}/likeUnlikeServices`,

  //event
  listEvent: `${event}/listEvent`,
  viewEvent: `${event}/viewEvent`,
  addEvent: `${event}/addEvent`,
  myListEvent: `${event}/myListEvent`,
  deleteEvent: `${event}/deleteEvent`,

  //pet
  listPet: `${pet}/listPet`,
  petBreedList: `${pet}/petBreedList`,
  viewPet: `${pet}/viewPet`,
  pet: `${pet}/pet`,
  trackPet: `${pet}/trackPet`,
  removePetToMarketPlace: `${pet}/removePetToMarketPlace`,
  removePet: `${pet}/removePet`,
  updatepet: `${pet}/updatepet`,
  addPetToMarketPlace: `${pet}/addPetToMarketPlace`,
  myPetList: `${pet}/myPetList`,
  favUnfavPet: `${pet}/favUnfavPet`,
  myFavPets: `${pet}/myFavPets`,
  otherUserPetList: `${pet}/otherUserPetList`,
  petCategoryList: `${pet}/petCategoryList`,
  petCategoryDetails: `${pet}/petCategoryDetails`,
  // STATIC
  viewStaticContent: `${statics}/static`,
  listFaq: `${statics}/faqList`,
  contact_us_details: `${statics1}/contact_us_details`,
  //addpost
  addPost: `${post}/addPost`,
  // story post
  checkPlan: `${user}/checkPlan`,
  addStory: `${post}/addStoryMobile`,
  myPostList: `${post}/myPostList`,
  listMyStory: `${post}/listMyStory`,
  uploadMultipleFile: `${post}/uploadMultipleFile`,
  userPostHide: `${post}/userPostHide`,
  storyList: `${post}/storyList`,
  homePagePostList: `${post}/homePagePostList`,
  addLike: `${post}/addLike`,
  createCheckoutSession: `${user}/create-checkout-session`,
  listSubscription: `${admin}/listSubscription`,
  addComment: `${post}/addComment`,
  deleteComment: `${post}/deleteComment`,
  updateComment: `${post}/updateComment`,
  repliesList: `${post}/repliesList`,
  commentList: `${post}/commentList`,
  createReport: `${post}/createReport`,
  viewPost: `${post}/viewPost`,
  otherUserPostList: `${post}/otherUserPostList`,
  removePost: `${post}/removePost`,
  // sub-category
  listSubCategory: `${category}/listSubCategory`,
  // category
  listCategory: `${category}/listCategory`,
  // contact us
  createContactUs: `${contact}/createContactUs`,
  // socket
  oneToOneChatApi: `${socket}/oneToOneChatApi`,
  chatHistory: `${socket}/chatHistory`,
  deleteChatHistory: `${socket}/deleteChatHistory`,
  readChat: `${socket}/readChat/`,
};

export const socketConfig = {
  connectSocket: `connect`,
  getOnline: `onlineUser`,
  getOffline: `offlineUser`,
  checkOnline: `checkOnlineUser`,
  StartChat: `oneToOneChat`,
  chatHistory: `chatHistory`,
  chatList: `chatList`,
  disconnect: `disconnect`,
};
export default Apiconfigs;
