import React, { useEffect, useState, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  Box,
  makeStyles,
  Typography,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { useHistory } from "react-router-dom";
import { localeFunction } from "src/utils";
const useStyles = makeStyles((theme) => ({
  mainSearchiconBox: {
    display: "flex",
    alignItems: "center",
  },

  titleSubtilte: {
    flexDirection: "column",
    paddingLeft: "15px",
    "& span": {
      fontSize: "18px",
      color: "#017c6b",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  SearchBox: {
    position: "relative",
  },
  SearchIcon: {
    color: "#656565",
    position: "absolute",
    right: "9px",
    top: "15px",
    background: "rgba(255, 255, 255, 0.4)",
  },

  dflex: {
    display: "flex",
    alignItems: "unset",
    cursor: "pointer",
    marginBottom: "10px",
    height: "54px",

    "&:hover": {
      borderRadius: "12px",
    },
    "& h6": {
      cursor: "pointer",
      fontWeight: "500",
      width: "300px",
      textOverflow: "ellipsis",
      maxWidth: "300px",
      overflow: "hidden",
    },
    "& body2": {
      cursor: "pointer",
    },
  },
  dflex1: {
    display: "flex",
    alignItems: "self-start",
    justifyContent: "space-between",
  },

  imageBox: {
    "& img": {
      width: "25px",
      height: "25px",
      position: "relative",
      bottom: "15px",
      zIndex: "1",
      left: "23px",
      border: "1px solid #fff",
      borderRadius: "100px",
    },
  },

  UserBox: {
    maxHeight: "250px",
    position: "absolute",
    background: "#f0f2f5",
    overflow: "scroll",
    width: "100%",
    padding: "10px 7px",
    borderRadius: "10px",
  },
  boxShadow: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  },
  paper: {
    boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.35)",
  },
}));

const top100FilmOptions = [
  {
    icon: (
      <img
        src="./images/PetsIMage2.svg"
        style={{ color: "#017c6b", fontSize: "22px" }}
      />
    ),
    title: `${localeFunction("favourite_text_2")}`,
    subtitle: `${localeFunction("top_serach_field_text_1")}`,
    pathname: "/favorites",
  },
  {
    icon: (
      <img
        src="./images/intrerest.png"
        style={{ color: "#017c6b", fontSize: "22px" }}
      />
    ),
    title: `${localeFunction("interested_text_1")}`,
    subtitle: `${localeFunction("top_serach_field_text_2")}`,
    pathname: "/interested",
  },
  {
    icon: (
      <img
        src="./images/PetSearch.svg"
        style={{ color: "#017c6b", fontSize: "22px" }}
      />
    ),
    title: `${localeFunction("missing_pets_texr1")}`,
    subtitle: `${localeFunction("missing_pets_texr1")}`,
    pathname: "/missing-pets",
  },

  {
    icon: (
      <img
        src="./images/Market.png"
        style={{ color: "#017c6b", fontSize: "20px" }}
      />
    ),
    title: `${localeFunction("nav_drawer_text_1")}`,
    subtitle: `${localeFunction("top_serach_field_text_3")}`,
    pathname: "/market",
  },
];
const filter = createFilterOptions();

export default function FreeSoloCreateOption() {
  const classes1 = useStyles();
  const {
    imageBox,
    dflex,
    dflex1,
    mainSearchiconBox,
    SearchBox,
    SearchIcon,
    titleSubtilte,
    UserBox,
    boxShadow,
    paper,
  } = useStyles();
  const history = useHistory();
  const getPathName = history.location.pathname;
  const [value, setValue] = useState(null);
  const [top100Films, setTop100Films] = useState(top100FilmOptions);
  const [_searchLoading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [searchData, setSearchData] = useState([]);
  const cancelTokenSourceRef = useRef();

  const globalSearch = async (value) => {
    try {
      setSearchLoading(true);
      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource; // Save the current cancel token

      const res = await axios({
        method: "POST",
        headers: {
          token: sessionStorage.getItem("token"),
        },
        url: Apiconfigs.globalSearch,
        data: {
          search: value,
        },
        cancelToken: cancelTokenSource.token,
      });

      if (res) {
        console.log("asdfjlasfkafb========", res?.data?.result?.users);
        setSearchData([]);
        setSearchData(res?.data?.result?.users);
        setSearchLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // Request was canceled, so no need to handle the error
        return;
      }

      setSearchLoading(false);
      // Handle other errors if needed
    }
  };

  useEffect(() => {
    if (searchValue?.length > 0) {
      // Cancel previous API call if it exists
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel("Request canceled");
      }

      globalSearch(searchValue);
      setTop100Films([]);
    } else {
      setTop100Films(top100FilmOptions);
    }
  }, [searchValue]);
  return (
    <Autocomplete
      style={{ width: "70%" }}
      value={value}
      classes={{ paper: classes1.paper }}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
          // setSearchValue();
        }
        return filtered;
      }}
      onInputChange={(e) => {
        if (e.target.value === undefined) setSearchValue();
      }}
      selectOnFocus
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={top100Films}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(option, pathname) => (
        <>
          {searchValue?.length > 0 ? (
            <React.Fragment>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <>
                  {searchData?.length > 0 ? (
                    <>
                      {searchData &&
                        searchData?.map((data) => {
                          return (
                            <Box className={dflex1}>
                              <Box
                                className={dflex}
                                onClick={() => {
                                  history.push({
                                    pathname: "user-profile",
                                    search: data?._id,
                                    state: { userId: data?._id },
                                  });
                                }}
                              >
                                <Box pr={2}>
                                  <Avatar src={data?.petPic}></Avatar>
                                  <Box className={imageBox}>
                                    <img
                                      src={
                                        data?.profilePic
                                          ? data?.profilePic
                                          : "images/User.png"
                                      }
                                      alt=""
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box>
                                  <Typography variant="h6">
                                    {data?.name ? data?.name : ""}
                                  </Typography>
                                  <Typography variant="body2">
                                    {data?.userName ? data?.userName : ""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                    </>
                  ) : (
                    <Box>
                      {!_searchLoading && (
                        <Typography>
                          {localeFunction("top_serach_field_text_4")}
                        </Typography>
                      )}
                    </Box>
                  )}
                </>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box
                onClick={() => history.push(`${option.pathname}`)}
                className={mainSearchiconBox}
                style={{ width: "100%" }}
              >
                {option.icon} &nbsp;&nbsp;
                <Box className={titleSubtilte}>
                  <span>{option.title}</span>
                  <br />
                  {option.subtitle}
                </Box>
              </Box>
            </React.Fragment>
          )}
        </>
      )}
      freeSolo
      renderInput={(params) => (
        <>
          <Box className={SearchBox}>
            <TextField
              {...params}
              placeholder={localeFunction("top_serach_field_text_5")}
              variant="outlined"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              fullWidth
            />
            {searchValue === undefined && value === null && (
              <SearchIcon className={SearchIcon} />
            )}
          </Box>
          {_searchLoading && (
            <img
              style={{
                position: "absolute",
                right: "6.5%",
                top: "7%",
                width: "40px",
              }}
              src="/images/LoaderImage.gif"
            />
          )}
        </>
      )}
    />
  );
}
