import React, { useState } from "react";
import { Button, makeStyles, Box } from "@material-ui/core";
import { localeFunction } from "src/utils";
const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
  },
  toggleButton: {
    marginRight: "20px",
    fontSize: "13px !important",
  },
}));
function ReadMore({ children, setReadMore }) {
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  return (
    <>
      <Box className={isHidden ? classes.hidden : null}>{children}</Box>
      <Box style={{ textAlign: "right", marginTop: "0px" }}>
        <Button
          style={{ color: "#6FCFB9", fontWeight: "800" }}
          size="small"
          onClick={() => {
            setIsHidden(!isHidden);
            setReadMore(!isHidden);
          }}
          className={classes.toggleButton}
        >
          {isHidden ? (
            <>⬇{localeFunction("otherUser_profile_8")}</>
          ) : (
            <>⬆{localeFunction("otherUser_profile_10")}</>
          )}
        </Button>
      </Box>
    </>
  );
}

export default ReadMore;
