import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  Collapse,
  ListItem,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: "#000000",
    padding: "10px 10px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    padding: "8px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    marginBottom: "8px",

    opacity: 1,
    borderRadius: "10px",
    "&:hover": {
      color: "#6FCFB9",
      background: "#c4c7cc4d",
      borderRadius: "10px",
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "14px",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: "#6FCFB9",
    width: "32px",
    objectFit: "none",
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: "#6FCFB9",
    background: "#FFFFFF",
    borderRadius: "10px",
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
  IconCLass: {
    fontSize: "30px",
    padding: "2px",
    border: "2px solid #6fcfb9",
    borderRadius: "29px",
    color: "white",
    background: "#6fcfb9",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 10;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          // style={{ color: "#ccc" }}
        >
          {Icon && <img src={Icon} className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }
  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {href === "/feeds" ? (
          <Icon className={classes.icon} style={{ fontSize: "29px" }} />
        ) : href === "/view-profile" ? (
          <>
            <Avatar
              className={classes.IconCLass}
              style={{
                background: "none",
                padding: "0",
                objectFit: "cover",
                width: "35px",
                height: "35px",
              }}
              src={Icon ? Icon : "images/profile-placeholder.jpeg"}
            />
          </>
        ) : (
          <>{Icon && <img src={Icon} className={classes.icon} size="20" />}</>
        )}

        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  // icon: PropTypes.elementType,
  icon: PropTypes.string,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
