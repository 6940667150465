import {
  Typography,
  Box,
  Button,
  TextField,
  Grid,
  Avatar,
  makeStyles,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import moment from "moment";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { AuthContext } from "src/context/Auth";
import SendIcon from "@material-ui/icons/Send";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import DeleteModal from "src/component/DeleteModal";
import { localeFunction } from "src/utils";
const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: "auto",
    display: "inline-block",
    width: "auto !important",
    "&$expanded": {
      minHeight: "auto",
      display: "inline-block",
      width: "auto !important",
    },
  },
  content: {
    margin: "0px 0 ",
    justifyContent: "center",
    "&$expanded": {
      margin: "0px 0 ",
    },
  },
  expanded: {},
})(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "block !important",
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0 !important",
    },
  },
}))(MuiAccordionDetails);
const useStyles = makeStyles((theme) => ({
  UserBox: {
    display: "flex",
    marginBottom: "10px",
    "& h6": {
      fontWeight: "500",
      fontSize: "14px",
      color: "#000",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& span": {
        fontWeight: "600",
        color: "#656565",
        fontSize: "12px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
      },
    },
    "& small": {
      color: "rgb(0 0 0 / 60%)",
      fontSize: "12px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    "& div": {
      backgroundColor: "transparent",
    },
    "& figure": {
      margin: "0",
      marginRight: "15px",
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      overflow: "hidden",
      backgroundColor: "#F5F5F5",
      [theme.breakpoints.down("xs")]: {
        height: "30px",
        width: "30px",
        marginRight: "8px",
      },
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
        height: "100%",
      },
    },
    "& .searchaddress": {
      paddingTop: "16px",
      "& .figure": {
        margin: "0",
        marginRight: "15px",
        position: "relative",
        "& .profileimage": {
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          overflow: "hidden",
          backgroundColor: "#F5F5F5",
          [theme.breakpoints.down("xs")]: {
            height: "40px",
            width: "40px",
          },
          "& img": {
            width: "100% !important",
          },
        },
      },
      "& button": {
        backgroundColor: "#373636",
        height: "44px",
        borderRadius: "7px",
        [theme.breakpoints.down("xs")]: {
          height: "40px",
        },
      },
    },
  },
}));
const ReplyComment = ({
  data,
  commentHandler,
  commentData,
  handleKeyPress,
  handleFormSubmit,
  commentListHandler,
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [expand, setExpand] = useState(false);
  const [isLoadings, setIsLoading] = useState(false);
  const [replyComment, setReplyComment] = useState(
     ""
  );
  const [openReport, setOpenReport] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const deleteHandler = async (commentId, postId) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.deleteComment,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          typeOfPost: "POST",
          commentId: commentId,
          postId: postId,
        },
      });
      if (res.data.responseCode === 200) {
        setIsLoading(false);
        auth.homePagePostListHandler();
        handleFormSubmit(commentData?._id);
        handleCloseReport();
        commentListHandler();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleKeyPressEdit = (e, id) => {
    if (e.key === "Enter") {
      editHandler(id);
    }
  };
  const editHandler = async (commentId) => {
    if (replyComment !== "") {
      setIsLoading(true);
      try {
        const res = await Axios({
          method: "POST",
          url: Apiconfigs.updateComment,
          headers: {
            token: sessionStorage.getItem("token"),
          },
          data: {
            typeOfPost: "REPLY",
            reply: replyComment,
            commentId: commentId,
          },
        });
        if (res.data.responseCode === 200) {
          setIsLoading(false);
          setExpand(!expand);
          handleFormSubmit(commentData?._id);
          commentListHandler();
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      toast.warn(localeFunction("feed_comment_warn_text"));
    }
  };
  const handleCloseReport = () => {
    setOpenReport(false);
  };
  const handleClickOpenReport = () => {
    setOpenReport(true);
    setAnchorEl(false);
  };
  return (
    <div>
      <Box className={classes.UserBox}>
        <Box className={classes.UserBox}>
          <Avatar src={data?.userId?.profilePic} />
          &nbsp;&nbsp;
          <Box>
            <Typography variant="h6">
              {data?.userId?.name} <span>{data?.reply}</span>
            </Typography>
            <Box mt={1}>
              {data?.userId?._id === auth?.userData?._id?(<>
              <Accordion square expanded={expand}>
                <Button color="primary" size="large" variant="text">
                  <small>{moment(data?.createdAt).startOf("").fromNow()}</small>
                  &nbsp;&nbsp;
                  <small>
                    <Tooltip title={localeFunction("feed_comment_edit_text")}>
                      <IconButton
                        className={classes.menuShare}
                        variant="contained"
                        onClick={() => setExpand(!expand)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </small>
                  &nbsp;&nbsp;
                  <small>
                    <Tooltip title={localeFunction("feed_comment_delete_text")}>
                      <IconButton
                        className={classes.menuShare}
                        variant="contained"
                        onClick={handleClickOpenReport}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </small>
                </Button>
                <AccordionDetails>
                  <Box>
                    <Box className="">
                      <Grid container spacing={1} alignItems="center">
                        <Grid
                          item
                          xs={3}
                          sm={2}
                          alignItems="center"
                          display={"flex"}
                        >
                          <Avatar src={auth?.userData?.profilePic} />
                          &nbsp; &nbsp;
                        </Grid>
                        <Grid item xs={6} sm={9}>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            name="Text Field"
                            placeholder={localeFunction(
                              "feed_comment_write_text"
                            )}
                            type="text"
                            inputProps={{ maxLength: 900 }}
                            value={replyComment}
                            onChange={(e) => setReplyComment(e.target.value)}
                            onKeyPress={(e) => handleKeyPressEdit(e, data?._id)}
                            fullWidth
                            multiline
                          />
                        </Grid>
                        <Grid item xs={3} sm={1} align="center">
                          <Button
                            size="large"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              editHandler(data?._id); //editing own repy
                            }}
                            style={{ background: "#6FCFB9" }}
                          >
                            <SendIcon style={{ color: "#fff" }} />
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              </>):(<>
                <Accordion square expanded={expand}>
                <Button color="primary" size="large" variant="text">
                  <small>{moment(data?.createdAt).startOf("").fromNow()}</small>                  
                </Button>
                <AccordionDetails>
                  <Box>
                    <Box className="">
                      <Grid container spacing={1} alignItems="center">
                        <Grid
                          item
                          xs={3}
                          sm={2}
                          alignItems="center"
                          display={"flex"}
                        >
                          <Avatar src={auth?.userData?.profilePic} />
                          &nbsp; &nbsp;
                        </Grid>
                        <Grid item xs={6} sm={9}>
                          <TextField
                            id={data?._id}
                            variant="outlined"
                            name={data?.id}
                            type="text"
                            disabled={true}
                            inputProps={{ maxLength: 900 }}
                            value={data?.reply}
                            fullWidth
                            multiline
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              </>)}
            </Box>
          </Box>
        </Box>
        <DeleteModal
          openReport={openReport}
          handleCloseReport={handleCloseReport}
          type={"Comment"}
          deleteHandler={deleteHandler}
          Id={data?._id}
          postId={commentData?.postId?._id}
        />
        {data?.type === "REPLY" ? (
          ""
        ) : (
          <Box className="searchaddress">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2} sm={2}>
                <Avatar src={auth?.userData?.profilePic} />
              </Grid>
              <Grid item xs={8} sm={9}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="Text Field"
                  placeholder={localeFunction("feed_comment_write_text")}
                  type="text"
                  inputProps={{ maxLength: 900 }}
                  value={replyComment}
                  onChange={(e) => setReplyComment(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, commentData?._id)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sm={1} align="center">
                <Button
                  size="large"
                  color="primary"
                  fullWidth
                  onClick={() => commentHandler(commentData?._id)}
                >
                  <SendIcon style={{ color: "#fff" }} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ReplyComment;
