import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Avatar,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { RiShareForwardLine } from "react-icons/ri";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { MdLocationOn } from "react-icons/md";
import { FiMoreVertical } from "react-icons/fi";
import Slider from "react-slick";
import Comment from "src/views/pages/Dashboard/Comment";
import ShareModal from "./ShareModal";
import ReportModal from "./ReportModal";
import moment from "moment";
import { AuthContext } from "src/context/Auth";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoad from "react-lazyload";
import ReadMore from "./ReadMore";
import { localeFunction } from "src/utils";

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    minWidth: "85px !important",
    boxShadow: "0px 0px 11px rgb(0 0 0 / 16%)",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF !important",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const useStyles = makeStyles((theme) => ({
  PostBox: {
    backgroundColor: "theme",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& p": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    "& .price": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .text": {
        display: "flex",
        alignItems: "center",
        "& h6": {
          fontWeight: "700",
        },
      },
    },
    "& .typo": {
      padding: "0 25px",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 0px",
      },
      "& h6": {
        overflow: "hidden",

        color: "#000",
        fontWeight: "600",
      },
      "& p": {
        wordBreak: "break-all",
      },
    },

    "& .postImg": {

    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    filter: "blur(10px)",
      width: "100%",  
      margin: "16px 0",
      overflow: "hidden",
      cursor: "pointer",

      [theme.breakpoints.down("xs")]: {
        margin: "5px 0",
      },
      "& img": {
        width: "100%",
        height: "auto",
        objectFit: "fill",
      },
    },
    "& .postImg2": {
      width: "100%",
      margin: "16px 0",
      overflow: "hidden",
      cursor: "pointer",
      height: "400px",
      background: "#000",

      position: "relative",
      display: "flex !important",
      alignItems: "center",
      justifyContent: "center",
      maxHeight: "700px",
      minHeight: "270px",
      [theme.breakpoints.down("xs")]: {
        margin: "5px 0",
        borderRadius: "10px",
      },
      "& img": {
        width: "100%",
        height: "auto",
        objectFit: "fill",
      },
    },

    "& .postImg1": {
      width: "100%",
      margin: "16px 0",
      maxHeight: "700px",

      minHeight: "270px",
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        margin: "5px 0",
      },
      "& img": {
        width: "100%",
      },
    },
    "& label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    "& .commentBox": {
      borderTop: "0.5px solid #E1E1E1",
      borderBottom: "0.5px solid #E1E1E1",
      marginTop: "16px",
      padding: "5px 0",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 0",
        marginTop: "10px",
      },
      "& button": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px !important",
        },
        "& svg": {
          fontSize: "20px",
          marginRight: "5px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
          },
        },
      },
    },
    "& .searchaddress": {
      paddingTop: "16px",
      "& .figure": {
        margin: "0",
        marginRight: "15px",
        position: "relative",
        "& .profileimage": {
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          overflow: "hidden",
          backgroundColor: "#F5F5F5",
          [theme.breakpoints.down("xs")]: {
            height: "30px",
            width: "30px",
          },
          "& img": {
            width: "auto",
            maxWidth: "100%",
            maxHeight: "50px",
            [theme.breakpoints.down("xs")]: {
              maxHeight: "30px",
            },
          },
        },
      },
      "& button": {
        backgroundColor: "#6FCFB9",
        height: "49px",
        borderRadius: "7px",
        [theme.breakpoints.down("xs")]: {
          height: "30px",
          "& svg": {
            fontSize: "16px",
          },
        },
      },
    },
    "& .UserBox": {
      "& .MuiAvatar-root": {
        // cursor: "pointer",
      },
      paddingTop: "25px",
      display: "flex",
      alignItems: "center",
      marginBottom: "16px",
      padding: "0 25px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
        padding: "0px 0px",
      },
      "& a": {
        textDecoration: "none",
        "& h6": {
          fontWeight: "700",
          color: "#202020",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
          },
        },
      },
      "& small": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
      },
      "& figure": {
        margin: "0",
        marginRight: "15px",
        height: "60px",
        width: "60px",
        borderRadius: "50%",
        overflow: "hidden",
        backgroundColor: "#F5F5F5",
        [theme.breakpoints.down("xs")]: {
          height: "40px",
          width: "40px",
        },
        "& img": {
          width: "auto",
          maxWidth: "100%",
          maxHeight: "60px",
          [theme.breakpoints.down("xs")]: {
            maxHeight: "40px",
          },
        },
      },
    },
    "& .UserBox1": {
      display: "flex",
      alignItems: "center",
      "& p": {
        color: "#404040",
      },
    },
  },
  menuShare: {
    position: "absolute",
    right: "10px",
    top: "32px",
    [theme.breakpoints.down("xs")]: {
      right: "0px",
      top: "0px",
    },
  },

  sharemodal: {
    "& button": {
      textAlign: "center",
      "& svg": {
        fontSize: "25px",
      },
    },
  },

  cancelBtn: {
    position: "absolute",
    top: 0,
    color: "#6fcfb9",
    right: 0,
  },
  arrowBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function (props) {
  const { data, showHideMore } = props;
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportData, setReportData] = useState({});
  const [_readMore, setReadMore] = useState(true);
  const [openShare, setOpenShare] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [_typeEvent, setTypeEvent] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpenShare = () => {
    setOpenShare(true);
    setAnchorEl(false);
  };

  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleClickOpenReport = () => {
    setOpenReport(true);
    setAnchorEl(false);
  };
  const handleCloseReport = () => {
    setOpenReport(false);
  };
  const reportdata = (data) => {
    setReportData(data);
  };
  const settings = {
    dots: false,
    slidesToShow: 1,
    arrows: true,
    centerMode: true,
    centerPadding: 0,
    autoplay: false,
    autoplaySpeed: false,
    infinite: true,
  };
  const OpenHandler = (data) => {
    setModalData(data);
    setOpenModal(true);
  };
  const CloseHandler = () => {
    setOpenModal(false);
  };
  return (
    <>
      <>
        <Paper elevation={0} style={{ marginTop: "16px" }}>
          <LazyLoad height={200} offset={100}>
            <Box className={classes.PostBox}>
              <IconButton
                onClick={(e) => {
                  handleClick(e);
                  reportdata(data);
                }}
                aria-controls="customized-menu"
                aria-haspopup="true"
                className={classes.menuShare}
              >
                <FiMoreVertical />
              </IconButton>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem onClick={handleClickOpenShare}>
                  <ListItemText primary={localeFunction("feed_share_text")} />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleClickOpenReport}>
                  <ListItemText primary={localeFunction("feed_report_text")} />
                </StyledMenuItem>
              </StyledMenu>

              <Box className="UserBox">
                <Avatar
                  onClick={() => {
                    history.push({
                      pathname: "/user-profile",
                      search: data?.userId?._id,
                    });
                  }}
                  src={data?.userId?.profilePic}
                  style={{
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                    background: "#000",
                  }}
                />
                &nbsp;&nbsp;
                <Box ml={1}>
                  <Typography
                    style={{
                      cursor: "pointer",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: "/user-profile",
                        search: data?.userId?._id,
                      });
                    }}
                    variant="h6"
                  >
                    {data?.userId?.name}
                  </Typography>

                  <Typography variant="body2" component="small">
                    {moment(data?.createdAt).local().fromNow()}
                  </Typography>
                </Box>
              </Box>
              <Box className="typo">
                <Typography variant="h6">{data?.eventName}</Typography>

                {showHideMore !== true && data?.description?.length > 250 ? (
                  <ReadMore setReadMore={setReadMore}>
                    <Typography
                      variant="body2"
                      style={{
                        wordBreak: "break-word",
                        fontWeight: "700",
                      }}
                    >
                      {_readMore && _readMore ? (
                        <>{data?.description.slice(0, 270)}...</>
                      ) : (
                        <>{data?.description}</>
                      )}
                    </Typography>
                  </ReadMore>
                ) : (
                  <>
                    <Typography
                      variant="body2"
                      style={{
                        wordBreak: "break-word",
                        fontWeight: "700",
                      }}
                    >
                      {data?.description}
                    </Typography>
                  </>
                )}
              </Box>
              <Grid container spacing={1}>
                {data?.image?.length > 1 ? (
                  <>
                    <Slider {...settings}>
                      {data?.image &&
                        data?.image.map((data1) => {
                          return (
                            <figure
                              className="postImg2"
                              onClick={() => {
                                setOpenModal(true);
                                setModalData(data?._id);
                                setTypeEvent("Event");
                              }}
                            >
                              <LazyLoad>
                                <LazyLoadImage
                                  width={"100%"}
                                  effect="blur"
                                  alt=""
                                  src={data1}
                                />
                              </LazyLoad>
                            </figure>
                          );
                        })}
                    </Slider>
                  </>
                ) : (
                  <figure
                    className="postImg"
                    onClick={() => {
                      setOpenModal(true);
                      setModalData(data?._id);
                      setTypeEvent("Event");
                    }}
                  >
                    <LazyLoad>
                      <LazyLoadImage
                        width={"100%"}
                        effect="blur"
                        alt=""
                        src={data?.image[0]}
                      />
                    </LazyLoad>
                  </figure>
                )}
              </Grid>
              <Box display={"flex"} justifyContent="space-between">
                <Box className="UserBox1" p={"0 10px"}>
                  <MdLocationOn
                    style={
                      data?.address?.length > 25
                        ? {
                            fontSize: "30px",
                            color: "#6FCFB9",
                            fontSize: "20px",
                            marginTop: "10px",
                          }
                        : { color: "#6FCFB9", fontSize: "20px" }
                    }
                  />
                  <Box
                    ml={1.5}
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      // width: "150px",
                    }}
                  >
                    <Typography variant="body2">{data?.address}</Typography>
                  </Box>
                </Box>
                <Box className={classes.arrowBox}>
                  <Button
                    color="primary"
                    size="large"
                    onClick={handleClickOpenShare}
                  >
                    <RiShareForwardLine
                      style={{ fontSize: "22px", paddingRight: "5px" }}
                    />
                    {localeFunction("feed_share_text")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </LazyLoad>

          <ShareModal
            openShare={openShare}
            handleCloseShare={handleCloseShare}
            url={window.location.href}
          />
          <ReportModal
            openReport={openReport}
            handleCloseReport={handleCloseReport}
            reportData={reportData}
          />
          {_typeEvent && openModal && (
            <Dialog
              open={openModal}
              onClose={CloseHandler}
              fullScreen={true}
              fullWidth
            >
              <Box className={classes.commentfullBox}>
                {/* <IconButton
                className="canelIcon"
                onClick={() => CloseHandler(false)}
              >
                <MdCancel />
              </IconButton> */}
              </Box>
              <DialogContent>
                <Comment
                  modalData={modalData}
                  typeEvent={_typeEvent}
                  setOpenModal={setOpenModal}
                />
              </DialogContent>
            </Dialog>
          )}
        </Paper>
      </>
    </>
  );
}
