import {
  Typography,
  Box,
  Button,
  TextField,
  Grid,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { getParticularObjectDataHanndler } from "src/apiConfig/service";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import DeleteModal from "src/component/DeleteModal";
import ReplyComment from "src/component/ReplyComment";
import { useHistory } from "react-router-dom";
import { localeFunction } from "src/utils";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: "auto",
    display: "inline-block",
    width: "auto !important",
    "&$expanded": {
      minHeight: "auto",
      display: "inline-block",
      width: "auto !important",
    },
  },
  content: {
    margin: "0px 0 ",
    justifyContent: "center",
    "&$expanded": {
      margin: "0px 0 ",
    },
  },
  expanded: {},
})(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "block !important",
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0 !important",
    },
  },
}))(MuiAccordionDetails);
const useStyles = makeStyles((theme) => ({
  UserBox: {
    display: "flex",
    margin: "7px 0",
    "& h6": {
      fontWeight: "600",
      fontSize: "14px",
      color: "#000",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "& span": {
        fontWeight: "400",
        color: "#656565",
        fontSize: "12px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
      },
    },
    "& small": {
      color: "rgb(0 0 0 / 60%)",
      fontSize: "12px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: "14px",
      marginTop: "5px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px",
      },
    },
    "& div": {
      backgroundColor: "transparent",
    },
    "& figure": {
      margin: "0",
      marginRight: "15px",
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      overflow: "hidden",
      backgroundColor: "#F5F5F5",
      [theme.breakpoints.down("xs")]: {
        height: "30px",
        width: "30px",
        marginRight: "8px",
      },
      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
        height: "100%",
      },
    },
    "& .searchaddress": {
      paddingTop: "16px",
      "@media(max-width:375px)": {
        marginLeft: "-40px",
      },
      "& .figure": {
        margin: "0",
        marginRight: "15px",
        position: "relative",
        "& .profileimage": {
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          overflow: "hidden",
          backgroundColor: "#F5F5F5",
          [theme.breakpoints.down("xs")]: {
            height: "40px",
            width: "40px",
          },
          "& img": {
            width: "100% !important",
          },
        },
      },
      "& button": {
        backgroundColor: "#373636",
        height: "44px",
        borderRadius: "7px",
        [theme.breakpoints.down("xs")]: {
          height: "40px",
        },
      },
    },
  },
}));

export default function ({
  commentData,
  commentListHandler,
  commitiddd,
  comment,
  _height,
  setHeight,
  MyProfileId,
}) {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [isLoading, setLoader] = useState(false);
  const [productdata, setProductdata] = useState([]);
  const [replyComment, setReplyComment] = useState(
    commentData?.userId?._id === auth?.userData?._id ? commentData?.comment : ""
  );
  const [isLoadings, setIsLoading] = useState(false);
  const [expanded, setExpanded] = React.useState("newExpanded");
  const [expand, setExpand] = React.useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (comment !== "") {
      setExpand(false);
    }
  }, [comment !== ""]);

  const commentHandler = async (id) => {
    if (replyComment !== "") {
      setIsLoading(true);
      try {
        const res = await Axios({
          method: "POST",
          url: Apiconfigs.addComment,
          headers: {
            token: sessionStorage.getItem("token"),
          },
          data: {
            typeOfPost: "COMMENT",
            reply: replyComment,
            commentId: id,
          },
        });
        if (res.data.responseCode === 200) {
          setIsLoading(false);
          // toast.success(res.data.responseMessage);
          auth.homePagePostListHandler();
          handleFormSubmit(commentData?._id);
          commentListHandler();
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      toast.warn(localeFunction("feed_comment_warn_text"));
    }
  };
  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      commentHandler(id);
    }
  };
  const handleFormSubmit = async (id) => {
    if (commentData?.userId?._id !== auth?.userData?._id) {
      setReplyComment("");
    }
    setLoader(true);
    try {
      const data = {
        commentId: id,
      };
      const endpoint = "repliesList";
      const response = await getParticularObjectDataHanndler(endpoint, data);
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          setProductdata(response.data.result.docs);
          setLoader(false);
        } else if (response) {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const editHandler = async (commentId, postId) => {
    if (replyComment !== "") {
      setIsLoading(true);
      try {
        const res = await Axios({
          method: "POST",
          url: Apiconfigs.updateComment,
          headers: {
            token: sessionStorage.getItem("token"),
          },
          data: {
            typeOfPost: "POST",
            comment: replyComment,
            commentId: commentId,
            postId: postId,
          },
        });
        if (res.data.responseCode === 200) {
          setIsLoading(false);
          setExpand(!expand);
          handleFormSubmit(commentData?._id);
          commentListHandler();
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      toast.warn(localeFunction("feed_comment_warn_text"));
    }
  };
  const deleteHandler = async (commentId, postId) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.deleteComment,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          typeOfPost: "POST",
          commentId: commentId,
          postId: postId,
        },
      });
      if (res.data.responseCode === 200) {
        setIsLoading(false);
        // toastx.success(res.data.responseMessage);
        auth.homePagePostListHandler();
        handleFormSubmit(commentData?._id);
        handleCloseReport();
        commentListHandler();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const likeHandler = async (id) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.addLike,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          typeOfPost: "COMMENT",
          commentId: id,
        },
      });
      if (res.data.responseCode === 200) {
        setIsLoading(false);
        commentListHandler();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCloseReport = () => {
    setOpenReport(false);
  };
  const handleClickOpenReport = () => {
    setOpenReport(true);
    setAnchorEl(false);
  };
  return (
    <>
      <Box className={classes.UserBox}>
        <Avatar
          src={commentData?.userId?.profilePic}
          onClick={() => {
            if (MyProfileId === commentData?.userId?._id) {
              history.push({
                pathname: "/view-profile",
              });
            } else {
              history.push({
                pathname: "/user-profile",
                search: commentData?.userId?._id,
              });
            }
          }}
        />
        &nbsp;&nbsp;
        <Box>
          <Typography variant="h6">
            <Box
              onClick={() => {
                if (MyProfileId === commentData?.userId?._id) {
                  history.push({
                    pathname: "/view-profile",
                  });
                } else {
                  history.push({
                    pathname: "/user-profile",
                    search: commentData?.userId?._id,
                  });
                }
              }}
              style={{ cursor: "pointer" }}
            >
              {commentData?.userId?.name}
            </Box>{" "}
            <span>{commentData?.comment}</span>
          </Typography>

          <Accordion square expanded={expand}>
            <Box>
              <small>
                {moment(commentData?.createdAt).startOf("").fromNow()}
              </small>
              &nbsp;&nbsp;
              <small>
                <Tooltip
                  title={
                    commentData?.isLiked
                      ? `${localeFunction("feed_unLike_text")}`
                      : `${localeFunction("feed_like_text")}`
                  }
                >
                  <IconButton onClick={() => likeHandler(commentData?._id)}>
                    {commentData?.isLiked ? (
                      <AiFillHeart style={{ color: "red" }} />
                    ) : (
                      <AiOutlineHeart />
                    )}
                  </IconButton>
                </Tooltip>
              </small>
              {commentData?.userId?._id === auth?.userData?._id && (
                <>
                  <small>
                    <Tooltip title={localeFunction("feed_comment_edit_text")}>
                      <IconButton
                        className={classes.menuShare}
                        variant="contained"
                        onClick={() => setExpand(!expand)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </small>
                  <small>
                    <Tooltip title={localeFunction("feed_comment_delete_text")}>
                      <IconButton
                        className={classes.menuShare}
                        variant="contained"
                        onClick={handleClickOpenReport}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </small>
                </>
              )}
              {commentData?.userId?._id !== auth?.userData?._id && (
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  onClick={() => setExpand(!expand)}
                >
                  <small onClick={() => handleFormSubmit(commentData?._id)}>
                    {localeFunction("feed_comment_reply_text")}
                  </small>
                  &nbsp;
                  {commentData?.repliesCount !== 0
                    ? commentData?.repliesCount
                    : ""}
                </AccordionSummary>
              )}
            </Box>
            <DeleteModal
              openReport={openReport}
              handleCloseReport={handleCloseReport}
              type={"Comment"}
              deleteHandler={deleteHandler}
              Id={commentData?._id}
              postId={commentData?.postId?._id}
            />
            <AccordionDetails>
              {productdata &&
                productdata?.map((data) => {
                  return (
                    <>
                      <ReplyComment
                        data={data}
                        replyComment={replyComment}
                        commentData={commentData}
                        commentHandler={commentHandler}
                        setReplyComment={setReplyComment}
                        handleKeyPress={handleKeyPress}
                        likeHandler={likeHandler}
                        editHandler={editHandler}
                        handleFormSubmit={handleFormSubmit}
                        commentListHandler={commentListHandler}
                        myId={auth?.userData?._id}
                      />
                    </>
                  );
                })}
              <>
                <Box>
                  <Box className="searchaddress">
                    <Grid container spacing={1} alignItems="center">
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        alignItems="center"
                        display={"flex"}
                      >
                        <Avatar src={auth?.userData?.profilePic} />
                        &nbsp; &nbsp;
                      </Grid>
                      <Grid item xs={6} sm={9}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          name="Text Field"
                          placeholder={localeFunction(
                            "feed_comment_write_text"
                          )}
                          type="text"
                          inputProps={{ maxLength: 900 }}
                          value={replyComment}
                          onChange={(e) => setReplyComment(e.target.value)}
                          onKeyPress={(e) =>
                            handleKeyPress(e, commentData?._id)
                          }
                          fullWidth
                          multiline
                        />
                      </Grid>
                      <Grid item xs={3} sm={1} align="center">
                        <Button
                          size="large"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            if (
                              commentData?.userId?._id === auth?.userData?._id
                            ) {
                              editHandler(
                                commentData?._id,
                                commentData?.postId?._id
                              ); //editing own comment
                            } else {
                              commentHandler(commentData?._id); //add comment
                            }
                          }}
                        >
                          <SendIcon style={{ color: "#fff" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                {/* {productdata?.length === 0 ||
                productdata?.length === undefined ? (
                  
                ) : (
                  ""
                )} */}
              </>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
}
