import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "src/scss/main.css";
import { SettingsProvider } from "src/context/SettingsContext";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
// replace [] with your dependencies if necessary
ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </Provider>
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.register();
