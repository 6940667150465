import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  UserBox: {
    display: "flex",
    alignItems: "center",
    "& figure": {
      margin: "0",
      marginLeft: "-10px",
      height: "35px",
      width: "35px",
      borderRadius: "50%",
      overflow: "hidden",
      backgroundColor: "#F5F5F5",
      position: "relative",
      transition: "0.3s",
      cursor: "pointer",
      border: "1px solid #6fcfb9",

      [theme.breakpoints.down("xs")]: {
        height: "25px",
        width: "25px",
      },
      "&:first-child": {
        marginLeft: "0px",
        height: "35px",
        width: "35px",
        border: "1px solid #6fcfb9",
      },
      "&:hover": {
        zIndex: "2",
        transform: "scale(1.2)",
      },
      "& img": {
        width: "100%",
        maxWidth: "100%",
        maxHeight: "40px",
        height: "100%",
      },
    },
    "& div": {
      height: "30px",
      width: "30px",
      borderRadius: "50%",
      overflow: "hidden",
      marginLeft: "-10px",
      fontWeight: 500,
      backgroundColor: "#6FCFB9",
      color: "#f0f2f5",
      fontSize: "14px",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        height: "30px",
        width: "30px",
        fontSize: "12px",
      },
    },
  },
}));

export default function ({ like, MyProfileId }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.UserBox}>
      {like &&
        like?.topLiked?.map((data, index) => {
          if (index < 3) {
            return (
              <figure>
                <Tooltip title={data?.userId?.name}>
                  <img
                  alt="profile-pic"
                    src={data?.userId?.profilePic}
                    onClick={() => {
                      if (MyProfileId === data?.userId?._id) {
                        history.push({
                          pathname: "/view-profile",
                        });
                      } else {
                        history.push({
                          pathname: "/user-profile",
                          search: data?.userId?._id,
                        });
                      }
                    }}
                  />
                </Tooltip>
              </figure>
            );
          }
          return null;
        })}
      &nbsp;
      {like?.likeCount > 3 && <Box>+{like?.likeCount - 3}</Box>}
    </Box>
  );
}
