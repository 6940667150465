import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  makeStyles,
} from "@material-ui/core";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { AiFillTwitterCircle } from "react-icons/ai";
import { getDataHandlerAPI } from "src/apiConfig/service/index";
import { localeFunction } from "src/utils";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  InstapaperShareButton,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#6fcfb9",
  },
  sharemodal: {
    "& button": {
      textAlign: "center",
      "& svg": {
        fontSize: "25px",
        color: "#6fcfb9",
      },
    },
  },
}));

export default function ShareModal({
  openShare,
  handleCloseShare,
  url,
  _shareId,
  type,
}) {
  const classes = useStyles();

  const ShareSocialIconsHandle = async (values) => {
    try {
      const data = window.sessionStorage.getItem("token");
      const response = await getDataHandlerAPI({ endPoint: "sharePost", token: data });
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
      }
    } catch (error) { }
  };

  return (
    <Box>
      <Dialog
        open={openShare}
        onClose={handleCloseShare}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
        >
          {localeFunction("feed_sharePost_text")}{" "}
          {type === "SERVICE" ? "Service" : type === "PET" ? "Pet" : "Product"}
        </DialogTitle>
        <DialogContent>
          <IconButton className={classes.cancelBtn} onClick={handleCloseShare}>
            <MdCancel />
          </IconButton>
          <Box className={classes.sharemodal} mb={2} align="center" mt={3}>
            <Button>
              <Box>
                <FacebookShareButton
                  url={url}
                  title={`Share ${type}`}
                  hashtag=""
                  onShareWindowClose={ShareSocialIconsHandle}
                >
                  <BsFacebook /> <br />
                  {localeFunction("feed_facebook_text")}
                </FacebookShareButton>
              </Box>
            </Button>
            <Button>
              <Box>
                <InstapaperShareButton
                  url={url}
                  title={`Share ${type}`}
                  hashtag=""
                  onShareWindowClose={ShareSocialIconsHandle}
                >
                  <AiFillInstagram /> <br />
                  {localeFunction("feed_instagram_text")}
                </InstapaperShareButton>
              </Box>
            </Button>
            <Button>
              <Box>
                <TelegramShareButton
                  url={url}
                  title={`Share ${type}`}
                  hashtag=""
                  onShareWindowClose={ShareSocialIconsHandle}
                >
                  <RiSendPlaneFill /> <br />
                  {localeFunction("feed_telegram_text")}
                </TelegramShareButton>
              </Box>
            </Button>
            <Button>
              <Box>
                <TwitterShareButton
                  url={url}
                  title={`Share ${type}`}
                  hashtag=""
                  onShareWindowClose={ShareSocialIconsHandle}
                >
                  <AiFillTwitterCircle /> <br />
                  {localeFunction("feed_twitter_text")}
                </TwitterShareButton>
              </Box>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
