/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  Divider,
  List,
  ListSubheader,
  makeStyles,
  Slide,
  Avatar,
  Typography,
} from "@material-ui/core";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Logo from "src/component/Logo";
import MarketFilterIconHeading from "src/component/MarketFilterIconHeading";
import { MdDashboard } from "react-icons/md";
import { FaDog } from "react-icons/fa";
import { FaCat } from "react-icons/fa";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { GiDogBowl } from "react-icons/gi";
import { localeFunction } from "src/utils";
import NavItem from "./NavItem";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
// import { localeFunction } from "src/utils";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}
function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem depth={depth} info={item.info} key={key}>
        <img src={item.icon} alt="" /> {item.title}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 200,
    top: 72,
    left: "30px",
    height: "100%",
    background: theme.palette.background.default,
    borderRight: "0",
    maxHeight: "92vh",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
  serviceItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px 8px",
    cursor: "pointer",
    marginBottom: "5px",
    "& h6": {
      paddingLeft: "10px",
    },
    "&:hover": {
      color: "#6FCFB9",
      background: "#FFFFFF",
      borderRadius: "10px",
    },
  },
  serviceItemActive: {
    marginBottom: "5px",
    display: "flex",
    alignItems: "center",
    padding: "10px 8px",
    background: "#FFFFFF",
    borderRadius: "10px",
    cursor: "pointer",
    "& h6": {
      paddingLeft: "10px",
    },
    "&:hover": {
      color: "#6FCFB9",
      background: "#FFFFFF",
      borderRadius: "10px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const sections = [
    {
      subheader: `${localeFunction("Top_Categories")}`,
      items: [
        {
          title: `${localeFunction("Browse_All")}`,
          icon: MdDashboard,
          href: "/market",
        },
        {
          title: `${localeFunction("favourite_text_5")}`,
          icon: GiDogBowl,
          href: "/market-product",
        },
        {
          title: `${localeFunction("favourite_text_3")}`,
          icon: FaCat,
          href: "/market-pets",
        },
        {
          title: `${localeFunction("favourite_text_4")}`,
          icon: FaDog,
          href: "/market-services",
        },
        {
          title: `${localeFunction("marketDoctor")}`,
          icon: LocalHospitalIcon,
          href: "/market-doctor",
        },
      ],
    },
  ];
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [servicelist, setServiceList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [active, setActive] = useState("");
  const [activeServie, setActiveServie] = useState("");
  const listCategoryHandler = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: Apiconfigs.listCategory,
        params: {
          type: "SERVICE",
        },
      });
      if (res.data.responseCode === 200) {
        setServiceList(res.data.result.docs);
      }
    } catch (error) {}
  };
  const listCategoryProductHandler = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: Apiconfigs.listCategory,
        params: {
          type: "PRODUCT",
        },
      });
      if (res.data.responseCode === 200) {
        setProductList(res.data.result.docs);
      }
    } catch (error) {}
  };
  useEffect(() => {
    listCategoryHandler();
    listCategoryProductHandler();
  }, []);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <MarketFilterIconHeading
          heading={localeFunction("pet_description_text_8")}
          type="MarketNavbar"
        />
        <Box mt={1}>
          <Divider />
        </Box>
        <Box>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}

          <List
            subheader={
              <ListSubheader disableGutters disableSticky>
                {localeFunction("market_product_text_2")}
              </ListSubheader>
            }
          >
            {productList &&
              productList.map((data, i) => {
                return (
                  <>
                    <Box
                      className={
                        active === i
                          ? classes.serviceItemActive
                          : classes.serviceItem
                      }
                      onClick={() => {
                        history.push({
                          pathname: "/market-product",
                          search: data?._id,
                        });
                        setActive(i);
                      }}
                    >
                      <Avatar
                        style={{ width: "25px", height: "25px" }}
                        src={data?.categoryImage}
                      />
                      <Typography
                        variant="h6"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                        h
                      >
                        {data?.categoryName}
                      </Typography>
                    </Box>
                  </>
                );
              })}
          </List>

          <List
            subheader={
              <ListSubheader disableGutters disableSticky>
                Services Categories
              </ListSubheader>
            }
          >
            {servicelist &&
              servicelist.map((data, i) => {
                return (
                  <>
                    <Box
                      className={
                        activeServie === i
                          ? classes.serviceItemActive
                          : classes.serviceItem
                      }
                      onClick={() => {
                        history.push({
                          pathname: "/market-services",
                          search: data?._id,
                        });
                        setActiveServie(i);
                      }}
                    >
                      <Avatar
                        style={{ width: "30px", height: "30px" }}
                        src={data?.categoryImage}
                      />
                      <Typography
                        variant="h6"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        {data?.categoryName}
                      </Typography>
                    </Box>
                  </>
                );
              })}
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={1} align="center">
            <Logo width="175" />
          </Box>
          <Divider />
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
