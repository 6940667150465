import React, { useState, useEffect } from "react";
import { makeStyles, Button, Box } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import axios from "axios";
import { localeFunction } from "src/utils";
import { map } from "src/utils";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "30%",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "@media(max-width:514px)": {
      width: "60%",
    },
  },
  ClassLoation: {
    color: "#ff0000bd",
    fontWeight: "600",
    marginTop: "15px",
  },
  blockButton: {
    background: "#8080805e",
    width: "70%",
    color: "#333",
    marginTop: "12px",
    padding: "10px",
    borderRadius: "50px",
  },
  blockButton1: {
    background: "#017c6b",
    width: "70%",
    color: "#fff",
    marginTop: "12px",
    padding: "10px",
    borderRadius: "50px",
    "&:hover": {
      background: "#017c6b",
      width: "70%",
      color: "#fff",
      marginTop: "12px",
      padding: "10px",
      borderRadius: "50px",
    },
  },
  imgClass: {
    width: "15px",
    height: "15px",
  },
  cromeSection: {
    display: "flex",
    alignItems: "center",
    "@media(max-width:514px)": {
      fontSize: "10px",
    },
  },
  spanClass: {
    fontSize: "17px",
    fontWeight: "600",
    "@media(max-width:514px)": {
      fontSize: "10px",
    },
  },
  OrderClass: {
    lineHeight: "30px",
    padding: "0",
    "@media(max-width:514px)": {
      fontSize: "12px",
    },
  },
  arrowIcons: {
    fontSize: "12px",
    dot: {
      fontSize: "15px",
    },
    kanabix: {
      color: "#017c6b",
      fontSize: "30px",
      fontWeight: "600",
    },
  },
}));

export default function ServerModal({
  setCheckLocation,
  checkLocation,
  getLocation,
  showPosition,
}) {
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const [locAllowopen, setlocAllowOpen] = useState(true);

  const getData = async () => {
    const res = await axios.post(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${map.googleMapKey}`
    );

    let position = {
      coords: {
        latitude: res?.data?.location?.lat,
        longitude: res?.data?.location?.lng,
      },
    };
    await showPosition(position);
    setlocAllowOpen(false);
  };
  useEffect(() => {
    const element = document.getElementById("id");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className={classes.root} ref={rootRef}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={locAllowopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h2
            style={{
              color: "#017c6b",
              fontSize: "24px",
              fontWeight: "600",
            }}
            id="server-modal-title"
          >
            {localeFunction("landing_heading_text_1")}
          </h2>
          <h2
            id="server-modal-title"
            style={{
              color: "#017c6b",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {`${window.location.origin} ${localeFunction("device_allow_8")}`}
          </h2>
          <p
            id="server-modal-description"
            style={{ color: "black", fontSize: "14px", fontWeight: "600" }}
          >
            {localeFunction("Location_allow_text")}
          </p>
          <Box display={"flex"} justifyContent="" className={classes.kanabix}>
            <Button
              className={classes.blockButton}
              onClick={() => getData()}
              variant="outline"
            >
              {localeFunction("Location_allow_text_1")}
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              className={classes.blockButton1}
              variant="outline"
              onClick={() => {
                // getLocation();
                getData();
                setlocAllowOpen(false);
              }}
            >
              {localeFunction("device_allow_7")}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}
