import React, { createContext, useEffect, useState } from "react";
import _ from "lodash";
const defaultSettings = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: "DARK",
};
export const restoreSettings = () => {
  let settings = null;
  try {
    const storedData = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.log("error", err);
  }
  return settings;
};
export const storeSettings = (settings) => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );
  const [fireToken, setToken] = useState("");

  const handleSaveSettings = (update = {}) => {
    const mergedSettings = _.merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  const handleFireToken = (token) => {
    setToken(token);
  };
  useEffect(() => {
    const restoredSettings = restoreSettings();
    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);
  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);
  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
        fireToken: fireToken,
        handleFireToken: handleFireToken,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
