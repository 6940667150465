import React, { Suspense, Fragment, useContext, useState, useRef } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import SettingsContext from "src/context/SettingsContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchToken, onMessageListener } from "src/apiConfig/service/firebase";
import NitificationPopUp from "src/component/NotificationAlertPopUp";
import NotificationAllow from "src/component/NotificationAllow";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeFireToken } from "src/Redux/Auth/action";
const history = createBrowserHistory();

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  const [isNotificationErr, setNotificationErr] = useState(false);
  const dispatch = useDispatch();
  const themeSeeting = useContext(SettingsContext);
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState();
  const [notificationData, setNotificationData] = useState();
  const myContainer = useRef(null);
  useEffect(() => {

    async function fetchData() {
      // You can await here
      dispatch(
        storeFireToken({
          firetoken: await fetchToken(setTokenFound, setNotificationErr),
        })
      );
      // ...
    }
    fetchData();

  }, []);

  const theme = createTheme({
    theme: themeSeeting.settings.theme,
  });
  onMessageListener()
    .then((payload) => {
      if (window.sessionStorage.getItem("token")) {
        setOpen(true);
        setNotification(payload.notification);
        setNotificationData(payload.data);
      }
    })
    .catch((err) => console.log("failed: ", err));
  useEffect(() => {
    if (isNotificationErr) {
      ReactDOM.render(
        <>
          <NotificationAllow />
        </>,
        document.getElementById("rootid")
      );
    }
  }, [isNotificationErr]);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <ToastContainer />
            <Router history={history}>
              <div ref={myContainer} id="rootid"></div>
              <NitificationPopUp
                data={notificationData}
                notification={notification}
                open={open}
                setOpen={setOpen}
              />
              <RenderRoutes data={routes} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
