import React, { useContext } from "react";
import { Container, Box, IconButton, Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CommonFilter from "./CommonFilter";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "src/context/Auth";
import { localeFunction } from "src/utils";

export default function DraggableDialog({
  _openfilter,
  setOpenFilter,
  setRadius,
  selectCountry,
  setCountry,
  selectState,
  setSelectState,
  city,
  setCity,
  selectRadius,
  setState,
  breed,
  setBreed
}) {
  const auth = useContext(AuthContext);
  const handleClose = () => {
    setOpenFilter(false);
    setRadius("");
  };
  return (
    <div>
      <Dialog
        fullWidth
        open={_openfilter}
        onClose={() => {
          handleClose();
          auth.CloseApplyFilterFUnction();
        }}
        maxWidth="sm"
        aria-labelledby="draggable-dialog-title"
      >
        <Box display={"flex"} justifyContent="space-between">
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {localeFunction("Filter_text")}
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
              auth.CloseApplyFilterFUnction();
            }}
          >
            <CloseIcon style={{ color: "red" }} />
          </IconButton>
        </Box>
        <Divider />

        <DialogContent>
          <DialogContentText>
            <Container>
              <CommonFilter
                type="global-filter"
                setRadius={setRadius}
                selectCountry={selectCountry}
                setCountry={setCountry}
                selectState={selectState}
                setSelectState={setSelectState}
                city={city}
                setCity={setCity}
                selectRadius={selectRadius}
                setState={setState}
                handleClose={handleClose}
                breed={breed}
                setBreed={setBreed}
              />
            </Container>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
