import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";
const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiPickersClock: {
      clock: {
        background: "rgb(111 207 185) !important",
      },
      pin: {
        background: "#0a0b0b !important",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        background: "#0a0b0b !important",
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        background: "#fff !important",
      },
    },
    MuiMenu: {
      list: {
        // maxHeight: "250px",
        // overflow: "scroll",
      },
    },
    MuiSkeleton: {
      wave: {
        "&::after": {
          background:
            "linear-gradient(90deg, transparent, rgb(147 20 20 / 4%), #4242420f) !important",
        },
      },
    },
    MuiAvatar: {
      img: {
        objectFit: "contain",
        // background: "#000",
      },
      colorDefault: {
        backgroundColor: "transparent !important",
        color: "#6fcfb9",
      },
    },
    MuiDropzoneArea: {
      root: {
        background: "#e5e5e5 !important",
        position: "initial !important",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "none !important",
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#000",
      },
    },
    MuiPickersDay: {
      dat: {
        color: "#000",
      },
      hidden: {
        color: "rgb(64 64 64 / 47%)",
        opacity: "1",
      },
      dayDisabled: {
        color: "rgb(64 64 64 / 47%)",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&.Mui-disabled": {
          color: "#404040 !important",
        },
      },
    },

    MuiCircularProgress: {
      svg: {
        color: "#fff !important",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "0px 0px",
      },
    },
    MuiListSubheader: {
      root: {
        color: "#404040",
        fontSize: "18px",
        boxSizing: "border-box",
        listStyle: "none",
        fontWeight: "500",
        lineHeight: "48px",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#EDEDED",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "transparent",
        color: "#6fcfb9",
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: "30px",
      },
    },
    MuiDialog: {
      paper: {
        boxShadow:
          "rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px !important",
      },
      paperWidthSm: {
        padding: "15px",
      },
    },
    MuiSelect: {
      icon: {
        color: "#535353",
      },
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        paddingLeft: "15px",
        borderRadius: "18px",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#6FCFB9",
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "separate",
        borderSpacing: "0px 2px",
      },
    },
    MuiTableCell: {
      body: {
        padding: "10px 15px",
      },
      root: {
        borderBottom: "none",
        background: "#F6F6F6",
      },
      head: {
        background: "#6FCFB9",
        color: "#FFFFFF",
        wordBreak: "break-word",
      },
    },
    MuiPaginationItem: {
      root: {
        backgroundColor: "#F1F1F1",
      },
    },
    MuiPaginationItem: {
      page: {
        "&.Mui-selected": {
          backgroundColor: "rgb(111 207 185) !important",
          color: "#fff !important",
        },
      },
    },
    MuiInput: {
      root: {
        borderRadius: "10px",
        padding: "5px 10px",
      },
      underline: {
        "&:hover": {
          borderBottom: "none",
        },
        "&::before": {
          borderBottom: "none",
          display: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
    MuiInputBase: {
      input: {
        minHeight: "2.1876em !important",
        "@media (max-width:767px)": {
          height: "18px !important",
        },
        "&.Mui-disabled": {
          color: "#000 !important",
        },
      },
      root: {
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "12px",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "'Inter', sans-serif",
        fontWeight: "500",
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em",
        backgroundColor: "#ffffff",
        border: "1px solid #D2D2D2",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        "&.Mui-disabled": {
          background: "#f0f2f5 !important",
        },
      },
    },
    MuiFormGroup: {
      root: {
        display: "flex",
        flexWrap: "inherit",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    },
    MuiFormControlLabel: {
      label: {
        "&.Mui-disabled": {
          color: "#404040 !important",
        },
      },
      root: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: "1px",
        verticalAlign: "middle",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "0px",
      },
      marginNormal: {
        marginTop: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "#ACACAC",
        padding: "8px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "none",
        },
        "&.Mui-disabled": {
          color: "#f0f2f5",
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: "14px",
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      contained: {
        boxShadow: "none",
        "&.Mui-disabled": {
          backgroundColor: "#6FCFB9 !important",
        },
      },
      textPrimary: {
        color: "#404040",
        fontWeight: 400,
        fontSize: "14px !important",
        lineHeight: "17px",
      },
      containedSizeSmall: {
        padding: "4px 10px",
        fontSize: "0.8125rem",
      },
      containedPrimary: {
        color: "#404040",
        backgroundColor: "#DEDEDE",
      },
      containedSecondary: {
        color: "#fff",
        backgroundColor: "#6FCFB9",
        border: "1px solid transparent",
        minWidth: "100px",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#404040",
          border: "1px solid #6FCFB9",
        },
      },

      outlinedSecondary: {
        color: "#6FCFB9",
        border: "1px solid #6FCFB9",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#6FCFB9",
          color: "#fff",
          border: "1px solid #6FCFB9",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiPaper: {
      elevation24: {
        boxShadow: "none",
      },
      elevation4: {
        boxShadow: "none !important",
      },
      rounded: {
        borderRadius: "10px",
        "@media (max-width:767px)": {
          borderRadius: "5px",
        },
      },
      elevation0: {
        // boxShadow: "none"
      },
      elevation1: {
        boxShadow: "none",
      },
    },

    MuiOutlinedInput: {
      multiline: {
        height: "100%",
        padding: "14px 14px 10px 14px",
        minHeight: "50px",
        maxHeight: "100%",
      },
      inputAdornedEnd: {
        "@media (max-width:767px)": {
          padding: "6px 8px",
          fontSize: "10px",
        },
      },
      root: {
        position: "relative",
        borderRadius: "10px",
        height: "50px",
        "@media (max-width:599.9px)": {
          borderRadius: "5px",
          height: "35px",
        },
      },
      input: {
        padding: "11px 11px 11px 14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
        "@media (max-width:767px)": {
          padding: " 6px 8px",
          fontSize: "10px",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "& .Mui-error": {
          fontSize: " 12px !important",
        },
        "& p": {
          fontSize: " 12px !important",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "none",
        paddingBottom: "none",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiSnackbarContent: {
      root: {
        color: "#000",
        flexDirection: "column",
      },
      message: {
        fontSize: "18px",
      },
      action: {
        paddingLeft: "0px",
        marginLeft: "0px",
        marginRight: "0px",
        display: "block",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiInputBase: {
        root: {
          backgroundColor: " #FFFFFF !important",
        },
        input: {
          minHeight: "2.1876em !important",
          "&::placeholder": {
            opacity: 1,
            color: "#BDBDBD",
          },
          "&.Mui-disabled": {
            background: "#f0f2f5 !important",
            borderRadius: "10px !important",
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "rgba(255, 255, 255, 1);",
        dark: "#f4f6f8",
        paper: "#ECECEC",
      },
      primary: {
        main: "#000080",
      },
      secondary: {
        main: "#FF9933",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",

        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#F5F5F5;",
        dark: "#1c2025",
        paper: "#ffffff",
      },
      primary: {
        main: "#6FCFB9",
      },
      secondary: {
        main: "#6FCFB9",
      },
      text: {
        primary: "#404040",
        secondary: "#202020",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
