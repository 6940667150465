import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Avatar,
  IconButton,
  FormControl,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import { handleMultipleFileUpload } from "src/utils";
import ModalEmoji from "src/component/ModalEmoji";
import { IoClose } from "react-icons/io5";
import { HiUserAdd } from "react-icons/hi";
import { DropzoneArea } from "material-ui-dropzone";
import Axios from "axios";
import LiniarProgressBar from "src/component/LinearProgressBar";
import Apiconfigs from "src/apiConfig/config";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";
import LinearProgress from "@material-ui/core/LinearProgress";

import StoryPreview from "src/views/pages/Dashboard/Story/StoryPreview";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import FbImageGrid from "react-fb-image-grid";
import { localeFunction } from "src/utils";

const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& .addphotos": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      padding: "20px 20px",
      background: "#FFFFFF",
      border: "1px dashed #DFDFDF",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "7px",
      cursor: "pointer",
      position: "relative",
      "& input": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
      },
      "& svg": {
        fontSize: "30px",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    color: "#6fcfb9",
    right: 0,
    "& svg": {
      color: "#6fcfb9",
      fontWeight: "700",
    },
  },
  imgPickerBox: {
    "& p": {
      fontSize: "14px",
      lineHeight: "17px",
      color: "#9E9E9E",
      paddingTop: "20px",
    },
  },
  imageBox: {
    width: "50px",
    height: "50px ",
    paddingRight: " 8px",
  },
  btn1: {
    height: "47px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  minbox: {
    background: "#EEEEEE",
    width: "67px",
    height: "63px",
    borderRadius: "10px",
    marginTop: "12px",
  },
  borderClass: {
    borderBottom: "0.5px solid #E1E1E1",
    padding: "2px 0 14px",
    "& h6": {
      fontSize: "20px",
      fontWeight: "600",
    },
  },
  imageBox: {
    width: "50px",
    height: "50px ",
    paddingRight: " 8px",
  },
  boxUser: {
    background: "#e4e6eb",
    borderRadius: "5px",
    height: "25px",
  },
  emojiParent: {
    position: "relative",
    alignItems: "center",
    "& h4": {
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  flexbox: {
    display: "flex",
    alignItems: "center",
  },

  lableText: {
    margin: " 0 auto",
    display: " flex",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // border: "1px dotted black",
    borderRadius: "8px",
    // width: "10rem",
    // height: "10rem",
    cursor: "pointer",
    fontSize: "large",

    position: "absolute",
    zIndex: 1,
    background: "#fff",
    left: "34%",
    top: "30px",
    padding: "5px 10px",
    // [theme.breakpoints.down("xs")]: {
    //   left: "21%",
    // },
    "@media(max-width:600px)": {
      left: "21%",
    },
    "@media(max-width:600px)": {
      left: "10%",
    },
  },
  spanText: {
    fontWeight: "lighter",
    fontSize: "small",
    paddingTop: "0.5rem",
  },
  inputFile: {
    display: "none !important",
  },
  images: {
    display: " flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "-20px",
  },
  image: {
    margin: "1rem 0.5rem",
    position: "relative",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
  },
  deleteBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    cursor: "pointer",
    border: "none",
    color: "white",
    backgroundColor: "lightcoral",
  },
  addPhotoIcon: {
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    background: "#e4e6eb",
  },
  photoCancelBtn: {
    background: "#e4e6eb",
    right: 0,
    position: "absolute",
    zIndex: 11,
  },
  dropzoneBox: {
    padding: "5px",
    border: "1px solid #bfbfbf !important",
    borderRadius: "10px",
    position: "relative",
    minHeight: "25vh",
    // display: "flex",
    // justifyContent: "center",
  },
}));

export default function Addstory({
  closeAddStoryHandler,
  openAddStory,
  setOpenAddStory,
  handleClickFriendModal,
  finaltagpeope,
  handleFormSubmit,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [images, setImages] = useState([]);
  const [allUploaded, setAllUploaded] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading1, setIsUploading1] = useState(false);
  const [openStoryPreview, setStoryPreview] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [imageIssubmit, setImageIssubmit] = useState(false);
  // New Add Images
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedAll, setSelectedAll] = useState([]);
  const [selectedUpload, setSelectedUpload] = useState([]);

  const onSelectFile = async (event) => {
    if (selectedAll.length < 4) {
      const selectedSize = event.target.files[0];
      if (selectedSize.size < 25000000) {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        handlefilechange1(selectedFilesArray);
        const imagesArray = selectedFilesArray.map((file) => {
          setSelectedAll((selectedAll) => [...selectedAll, file]);
          return URL.createObjectURL(file);
        });
        const selectedFileType = event.target.files;
        const electedFileArray = Array.from(selectedFileType);
        const getFileType = electedFileArray.map(
          (data) => data.type.split("/")[1]
        );

        getFileType.map((type) => {
          if (
            type === "png" ||
            type === "jpeg" ||
            type === "gif" ||
            type === "gif" ||
            type === "webp"
          ) {
            setSelectedImages((previousImages) =>
              previousImages.concat(imagesArray)
            );
          } else {
            setSelectedVideos((previousImages) =>
              previousImages.concat(imagesArray)
            );
          }
        });
      } else {
        toast.error(localeFunction("fedd_story_validation_text"));
      }

      // FOR BUG IN CHROME
      event.target.value = "";
    } else {
      toast.error(localeFunction("fedd_story_validation_text1"));
    }
  };

  function deleteHandlerImage(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }
  function deleteHandlerVideo(image) {
    setSelectedVideos(selectedVideos.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  function DeleteAllFiles() {
    setSelectedImages([]);
    setSelectedAll([]);
    setSelectedVideos([]);
    setSelectedUpload([]);
  }

  function PushArray(newValue) {
    setSelectedUpload((selectedUpload) => [...selectedUpload, newValue]);
  }

  const handlefilechange1 = async (e) => {
    PushArray(e);
  };
  const onEmojiClick = (event, emojiObject) => {
    setInputStr((data) => data + event.emoji);
  };
  const postStoryHandler = async () => {
    var data = new FormData();
    Object.values(selectedUpload).forEach((res) => {
      data.append("files", res[0]);
    });
    data.append("caption",inputStr);
    if (selectedImages.length > 0 || selectedVideos.length > 0) {
      try {
        setIsLoading(true);
        const res = await Axios({
          method: "POST",
          url: Apiconfigs.addStory,
          headers: {
            token: sessionStorage.getItem("token"),
          },
          data: data,
        });
        if (res.data.responseCode === 200) {
          setIsLoading(false);

          handleFormSubmit();
          auth.handleStyorylistApi();
          closeAddStoryHandler();
          setImageIssubmit(false);
          toast.success(res.data.responseMessage);
          setIsLoading(false);
          closeStoryPreview();
          DeleteAllFiles();
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        setImageIssubmit(false);
      }
    } else {
      setImageIssubmit(true);
    }
  };

  const closeStoryPreview = () => {
    setStoryPreview(false);
  };

  return (
    <Box>
      <Dialog
        open={openAddStory}
        onClose={() => setOpenAddStory(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        className={classes.root}
      >
        <Box align="center">
          <Box className={classes.borderClass} mb={2}>
            <Typography variant="h6">
              {localeFunction("create_story_text")}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" pt={1} pb={2} className={classes.emojiParent}>
          <Box className={classes.imageBox}>
            <Box>
              <Avatar src={auth?.userData?.profilePic} />
            </Box>
            &nbsp;&nbsp;
          </Box>{" "}
          <Box>
            <Typography variant="h4">{auth?.userData?.name}</Typography>
          </Box>
        </Box>

        <IconButton
          className={classes.cancelBtn}
          onClick={closeAddStoryHandler}
        >
          <IoClose />
        </IconButton>
        <DialogContent>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Box width={"100%"}>
            <FormControl fullWidth className={classes.commentControl}>
              <TextareaAutosize
                className={classes.TextFiles}
                aria-label="minimum height"
                value={inputStr}
                minRows={3}
                maxLength={300}
                onChange={(e) => setInputStr(e.target.value)}
                placeholder={localeFunction("storycaption_placeholder_text")}
              />
            </FormControl>
          </Box>
        </Box>
          <Box
            className={classes.mainmodalBox}
            style={{ maxHeight: "40vh", overflowY: "scroll" }}
          >
            <Box
              className={classes.dropzoneBox}
              style={{
                background:
                  (selectedAll.length > 0 && selectedImages.length > 0) ||
                  selectedVideos.length > 0
                    ? "#000"
                    : "unset",
              }}
            >
              <IconButton
                className={classes.photoCancelBtn}
                onClick={DeleteAllFiles}
              >
                <IoClose color="#000" />
              </IconButton>
              <label
                className={classes.lableText}
                style={{
                  flexDirection:
                    (selectedAll.length > 0 && selectedImages.length > 0) ||
                    selectedVideos.length > 0
                      ? "row"
                      : "column",
                }}
              >
                <span className={classes.addPhotoIcon}>
                  <AddPhotoAlternateIcon />
                </span>
                {localeFunction("feed_story_text")}
                <input
                  type="file"
                  name="images"
                  onChange={onSelectFile}
                  accept="image/png , image/jpeg, image/webp,video/*"
                  className={classes.inputFile}
                  // multiple
                />
              </label>
              <br />
              <Box className={classes.images}>
                <FbImageGrid
                  onClickEach={({ src, index }) => {
                    deleteHandlerImage(src);
                  }}
                  width={100}
                  images={selectedImages ? selectedImages : ""}
                  // hideOverlay={true}
                  overlayBackgroundColor="#6fcfb9"
                  renderOverlay={() => (
                    <Button className={classes.deleteBtn}>
                      <DeleteIcon />
                    </Button>
                  )}
                />

                <Grid container justifyContent="center">
                  {selectedVideos &&
                    selectedVideos.map((image, index) => {
                      return (
                        <>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box
                              className={classes.image}
                              style={{ marginTop: "4rem" }}
                            >
                              <video
                                controls
                                style={{
                                  width: "100%",
                                  // maxHeight: "50vh",
                                  background: "#000",
                                  height: "40vh",
                                }}
                              >
                                <source type="video/mp4" src={image} />
                              </video>
                              <Button
                                onClick={() => deleteHandlerVideo(image)}
                                className={classes.deleteBtn}
                              >
                                <DeleteIcon />
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
              </Box>
            </Box>
            {isUploading1 && <LinearProgress />}
          </Box>
          <Box className={classes.mainmodalBox}>
            {imageIssubmit && (
              <Typography style={{ color: "red", marginTop: "20px" }}>
                {localeFunction("feed_story_text2")}
              </Typography>
            )}
            {finaltagpeope && finaltagpeope?.length !== 0 && (
              <>
                &nbsp;
                <Box className={classes.flexbox} mt={2}>
                  <HiUserAdd />
                  &nbsp;
                  <Typography variant="body2">
                    {finaltagpeope?.length}&nbsp;
                    {localeFunction("feed_story_text3")}
                  </Typography>
                </Box>
              </>
            )}
            {isUploading1 && <LinearProgress />}
            <Box mt={3} align="center">
              <Button
                disabled={isLoading || isUploading || isUploading1}
                variant="contained"
                color="secondary"
                style={{ padding: "10px 60px" }}
                onClick={postStoryHandler}
              >
                {localeFunction("create_story_text")}
                {isLoading && <ButtonCircularProgress color="#fff" />}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <StoryPreview
        openStoryPreview={openStoryPreview}
        closeStoryPreview={closeStoryPreview}
        allUploaded={allUploaded}
        setAllImages={setAllImages}
        setImages={setImages}
        handlePostsubmit={() => postStoryHandler()}
        isLoading={isLoading}
        closeAddStoryHandler={closeAddStoryHandler}
        images={images}
      />
    </Box>
  );
}
