import React, { useState, useEffect, useContext, useRef } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  withStyles,
  Typography,
  MenuItem,
  Menu,
  Grid,
  Avatar,
  Button,
  Badge,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import Addstory from "src/component/Addstory";
import CreatePostModal from "src/component/CreatePostModal";
import ProfileModal from "src/component/ProfileModal";
import AddPetProfile from "src/component/AddPetProfile";
import FilterListIcon from "@material-ui/icons/FilterList";
import TagFriend from "src/component/TagFriend";
import Addlocation from "src/component/Addlocation";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import UseProfileNavBar from "./RightDrawer";
import Logo from "src/component/Logo";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { AuthContext } from "src/context/Auth";
import NodatafoundImage from "../../component/NoDataFound";
import { getDataHandlerAPI } from "src/apiConfig/service/index";
import { toast } from "react-toastify";
import SearchBoxField from "src/component/SearchBoxField";
import ModalComponent from "src/component/ModalComponent";
import Comment from "src/views/pages/Dashboard/Comment";
import LocationModalComponent from "src/component/LocationModalComponent";
import ReactDOM from "react-dom";
import { getAPIdata, map } from "src/utils";
import { localeFunction } from "src/utils";
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#FFFFFF !important",
    minWidth: "120px !important",
    boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
    borderRadius: "5px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    fontWeight: "600",
    borderRadius: 0,
    minWidth: "auto",
    color: "#404040",
    padding: "0px 14px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    lineHeight: "54px",
    display: "flex",
    "& span": {
      display: "flex",
      alignItems: "center",
    },
    "@media (max-width: 1280px)": {
      fontStyle: "normal",
      letterSpacing: "1px",
      lineHeight: "7px",
      color: theme.palette.primary.main,
      padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&.activeMenu": {
      color: "#6fcfb9",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "54px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background: "#6fcfb9",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
    },
    "&:hover": {
      color: "#6fcfb9",
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    height: "30px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingLeft: "5px",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },

  iconbutton: {
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
    "& div": {
      height: "5px",
      width: "5px",
      borderRadius: "50%",
      backgroundColor: "#6FCFB9",
      position: "absolute",
      top: "7px",
      right: "8px",
    },
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .leftBox": {
      width: "306px",
      display: "flex",
      padding: "0 15px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "0 !important",
        },
      },
    },
  },
  searchBoxClass: {
    display: "flex",
    width: "70%",
    height: "37px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  notification: {
    margin: "0px 0px",
    "& button": {
      "& svg": {
        color: "#404040",
      },
    },
  },
  addStoryMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "5px 0px",
    "& figure": {
      margin: "0px",
      width: "15px",
      height: "15px",
      "& img": {
        width: "100%",
      },
    },
    "& p": {
      marginLeft: "10px",
    },
  },
  ProfileNotification: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  imageBox: {
    "& img": {
      width: "25px",
      height: "25px",
      position: "relative",
      bottom: "15px",
      zIndex: "1",
      left: "23px",
      border: "1px solid #fff",
      borderRadius: "100px",
    },
  },
  notificationbox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  typo: {
    display: "flex",
    padding: " 12px 0px 14px",
    justifyContent: "space-between",
    alignItems: "center",

    "& Button": {
      color: "#56B3E8",
      fontSize: "12px",
      textDecoration: "underline",
    },
    "& p": {
      fontWeight: "600",
      paddingLeft: "15px",
    },
  },
  searchControl: {
    "& .MuiInputBase-root": {
      boxShadow: "none !important",
      height: "40px !important",
    },
  },
  notificationBoxx: {
    maxHeight: "350px",
    overflow: "scroll",
    minWidth: "330px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "300px",
      maxHeight: "300px",
    },
  },
  GridClass7: {
    display: "flex",
    justifyContent: "right",
    position: "relative",
  },
}));
const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};
TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};
export default TopBar;
export function TopBarData() {
  const {
    menuul,
    searchBoxClass,
    searchControl,
    searchText,
    ProfileNotification,
    mainheader,
    addStoryMenu,
    iconbutton,
    notification,
    inerrMenu,
    menuButton,
    imageBox,
    notificationbox,
    notificationBoxx,
    typo,
    PostIdComment,
    CommentType,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const auth = useContext(AuthContext);
  const [spleteVariable, setSpleteVariable] = useState(
    window.localStorage.getItem("UserLocation")
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationList, setNotificationList] = useState([]);
  const [_follow, setFollow] = useState(false);
  const [profileImage64, setProfileImage64] = useState();
  const [open, setOpen] = useState(false);
  const [open_activate, setOpenActivate] = useState(false);

  const [_openfilter, setOpenFilter] = useState(false);
  const [rightBar, setRightBar] = useState(false);
  const location = useLocation();
  const myContainer = useRef(null);
  const handleSeenNotification = async (values) => {
    try {
      const data = window.sessionStorage.getItem("token");
      const response = await getDataHandlerAPI({ endPoint: "isSeen", token: data });
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClearNotification = async (id) => {
    try {
      const res = await Axios({
        method: "DELETE",
        url: Apiconfigs.clearNotification,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        params: {
          _id: id,
        },
      });
      if (res.data.responseCode === 200) {
        // toast.success(res.data.responseMessage);
        getNotificationList();
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getNotificationList = async () => {
    try {
      const res = await Axios({
        method: "GET",
        url: Apiconfigs.notificationList,
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res?.data?.responseCode === 200) {
        setNotificationList(res?.data?.result?.docs);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNotificationList();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [notificationpopup, setNotificationPopup] = useState(null);
  const notificationhandleClick = (event) => {
    handleSeenNotification();
    setNotificationPopup(event.currentTarget);
  };
  const notificationhandleClose = () => {
    setNotificationPopup(null);
  };
  const [checkLocation, setCheckLocation] = useState(false);
  const [openAddStory, setOpenAddStory] = useState(false);
  const [_delete, setDelete] = useState("");
  const [openCreatePost, setOpenCreatePost] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPost, setOpenModalPost] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [friendModal, setFriendModal] = useState(false);
  const handleformSubmit = () => {
    setFormSubmit(true);
  };
  const [modalData, setModalData] = useState();
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };
  const handlecloseformSubmit = () => {
    setFormSubmit(false);
  };
  const handleClickCloseModal = () => {
    setOpenModal(false);
  };
  const handleClickFriendModal = () => {
    setFriendModal(true);
  };
  const handleClickCloseFriendModal = () => {
    setFriendModal(false);
  };
  const handleClickOpenCreatePost = () => {
    setOpenCreatePost(true);
    handleClose();
  };
  const handleCloseCreatePost = () => {
    setOpenCreatePost(false);
    handleClose();
  };
  const openAddStoryHandler = () => {
    setOpenAddStory(true);
    handleClose();
  };
  const closeAddStoryHandler = () => {
    setOpenAddStory(false);
    handleClose();
  };
  // const handleDrawerOpen = () =>
  //   setState((prevState) => ({ ...prevState, drawerOpen: true }));
  // const handleDrawerClose = () =>
  //   setState((prevState) => ({ ...prevState, drawerOpen: false }));
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const handleClickOpenEdit = (deletemodal) => {
    setOpenEdit(true);
    setOpen(false);
    setRightBar(false);
    setDelete(deletemodal);
  };
  const DeActivate = () => {
    setOpenActivate(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const [openAddmodal, setAddOpenModal] = useState(false);
  const [petCategoryList, setPetCategoryList] = useState([]);
  const [_petcategoryliststate, setPetCalegoryListstate] = useState([]);
  const [_selectpetType, setSelectPetType] = useState(0);
  const [_selectpetType1, setSelectPetType1] = useState("");
  const [_usertypeprofile, setUserProfileData] = useState([]);
  const [_profileId, setProfileId] = useState("");
  const [profileData, setProfileData] = useState("");
  const [_isloading, setIsLoading] = useState(false);
  const [selectRadius, setRadius] = useState(
    window.localStorage.getItem("_miles")
      ? window.localStorage.getItem("_miles")
      : ""
  );
  const [selectCountry, setCountry] = useState("");
  const [breed, setBreed] = useState("0");
  const [selectState, setState1] = useState("");
  const [city, setCity] = useState("");
  useEffect(() => {
    if (selectRadius || selectCountry || selectState || city) {
      window.localStorage.setItem("_country", selectCountry);
      window.localStorage.setItem("_state", selectState);
      window.localStorage.setItem("_city", city);
      window.localStorage.setItem("_miles", selectRadius);
      auth.setCounrty(selectCountry);
      auth.setState(selectState);
      auth.setCity(city);
      auth.setMiles(selectRadius);
    }
  }, [selectRadius, selectCountry, selectState, city]);
  const handleCloseShare = () => {
    setAddOpenModal(false);
  };
  const handleOpenShare = (data) => {
    setAddOpenModal(true);
    setProfileData(data);
  };
  const handleClickOpenFilter = () => {
    setOpenFilter(true);
  };
  const PetCategoryType = async () => {
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.petCategoryList,
      });
      if (res.data.responseCode === 200) {
        setPetCategoryList(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const PetCategoryListFunction = async () => {
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.petCategoryDetails,
        params: {
          _id: _selectpetType?._id || _selectpetType,
        },
      });
      if (res.data.responseCode === 200) {
        setPetCalegoryListstate(res?.data?.result?.petCategoryType);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ChangeProfileFunction = async (id) => {
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.setDefaultUserProfile,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: {
          petCategoryId: id,
        },
      });
      if (res.data.responseCode === 200) {
        setPetCalegoryListstate(res?.data?.result?.petCategoryType);
        let search = location.search.split("?")[1];
        if (search) {
          window.location.href = window.location.href + "?" + search;
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteAccount = async () => {
    try {
      setIsLoading(true);
      const res = await Axios({
        url: Apiconfigs.deleteUserAccount,
        method: "DELETE",
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res) {
        setIsLoading(false);
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("password");
        window.localStorage.removeItem("mobileNumber");
        window.sessionStorage.removeItem("token");
        history.push("/");

        if (res?.data?.responseCode === 200) {
          toast.success(res?.data?.responseMessage);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleActivateAccount = async () => {
    try {
      setIsLoading(true);
      const res = await Axios({
        url: Apiconfigs.deactivateUserAccount,
        method: "Post",
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res) {
        setIsLoading(false);
        window.localStorage.removeItem("email");
        window.localStorage.removeItem("password");
        window.localStorage.removeItem("mobileNumber");
        window.sessionStorage.removeItem("token");
        history.push("/");

        if (res?.data?.responseCode === 200) {
          toast.success(res?.data?.responseMessage);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const PetTypeProfile = async () => {
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.userProfileList,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        setUserProfileData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    PetCategoryType();
    PetTypeProfile();
    if (_selectpetType !== 0) {
      PetCategoryListFunction();
    }
  }, [_selectpetType]);
  function report(state) {
    console.log(`Permission ${state}`);
  }
  function handlePermission() {
    try {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          report(result.state);
        } else if (result.state === "prompt") {
          report(result.state);
          navigator.geolocation.getCurrentPosition(showPosition, (err) => {
            if (err.code === 1) {
              ReactDOM.render(
                <>
                  <LocationModalComponent
                    setCheckLocation={setCheckLocation}
                    checkLocation={checkLocation}
                    getLocation={handlePermission}
                    showPosition={showPosition}
                  />
                </>,
                document.getElementById("rootid")
              );
            }
          });
        } else if (result.state === "denied") {
          report(result.state);
          ReactDOM.render(
            <>
              <LocationModalComponent
                setCheckLocation={setCheckLocation}
                checkLocation={checkLocation}
                getLocation={handlePermission}
                showPosition={showPosition}
              />
            </>,
            document.getElementById("rootid")
          );
        }
        result.addEventListener("change", () => {
          report(result.state);
        });
      });
    } catch (err) {
      console.log("locationerror", err);
    } finally {
      ReactDOM.render(
        <>
          <LocationModalComponent
            setCheckLocation={setCheckLocation}
            checkLocation={checkLocation}
            getLocation={handlePermission}
            showPosition={showPosition}
          />
        </>,
        document.getElementById("rootid")
      );
    }
  }
  const showPosition = async (position) => {
    let res = await getAPIdata({
      endPoint: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${map.googleMapKey}`,
    });
    if (res) {
      let showadd = `${res?.results[0].address_components[
        res?.results[0].address_components.length - 6
      ]
        ? res?.results[0].address_components[
          res?.results[0].address_components.length - 6
        ].short_name
        : ""
        }, ${res?.results[0].address_components[
          res?.results[0].address_components.length - 5
        ]
          ? res?.results[0].address_components[
            res?.results[0].address_components.length - 5
          ].short_name
          : ""
        }, ${res?.results[0].address_components[
          res?.results[0].address_components.length - 3
        ]
          ? res?.results[0].address_components[
            res?.results[0].address_components.length - 3
          ].short_name
          : ""
        }`;
      setSpleteVariable(showadd);
      window.localStorage.setItem("UserLocation", showadd);
      window.localStorage.setItem("UserLatitude", position.coords.latitude);
      window.localStorage.setItem("UserLongitute", position.coords.longitude);
    }
  };
  useEffect(() => {
    if (
      (spleteVariable === "null" ||
        spleteVariable === null ||
        spleteVariable === undefined) &&
      myContainer !== null
    )
      handlePermission();
  }, [myContainer]);
  const ProfileAndNotification = (
    <Box className={ProfileNotification}>
      <Hidden>
        <IconButton className={iconbutton} onClick={handleClick}>
          <img src="./images/Group 1171275091.png" />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={handleClickOpenCreatePost}>
            <Box className={addStoryMenu}>
              <figure>
                <img src="images/story/AddPostIcon.png" />
              </figure>
              <Typography variant="body2">
                {localeFunction("post_story_text")}
              </Typography>
            </Box>
          </StyledMenuItem>
          <StyledMenuItem onClick={openAddStoryHandler}>
            <Box className={addStoryMenu}>
              <figure>
                <img src="images/story/AddStoryIcon.png" />
              </figure>
              <Typography variant="body2">
                {localeFunction("feeds_story_text")}
              </Typography>
            </Box>
          </StyledMenuItem>
          <StyledMenuItem onClick={() => history.push("/add-pets")}>
            <Box className={addStoryMenu}>
              <figure>
                <img src="images/story/AddPetsIcon.png" />
              </figure>
              <Typography variant="body2">
                {localeFunction("favourite_text_3")}
              </Typography>
            </Box>
          </StyledMenuItem>
        </StyledMenu>
        <Badge color="secondary" badgeContent={0}>
          <IconButton
            style={{ padding: "0px", marginRight: "5px" }}
            onClick={() => history.push("/chat-history")}
          >
            <img src="./images/Group (1).png" />
          </IconButton>
        </Badge>
      </Hidden>
      <Box className={notification}>
        <IconButton onClick={notificationhandleClick}>
          <img src="./images/Group 1171275452.png" />
        </IconButton>
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={notificationpopup}
        keepMounted
        open={Boolean(notificationpopup)}
        onClose={notificationhandleClose}
      >
        <Box className={typo}>
          <Typography variant="body2">
            {localeFunction("Notifications")}
          </Typography>

          <Button
            onClick={() =>
              history.push({
                pathname: "/notification-list",
                state: {
                  data: notificationList,
                },
              })
            }
          >
            {localeFunction("feed_seeAll_text")}
          </Button>
        </Box>

        <Box style={{}} className={notificationBoxx}>
          {notificationList &&
            notificationList?.map((data, i) => {
              return (
                <>
                  <StyledMenuItem>
                    <Box className={notificationbox}>
                      <Box display="flex">
                        <Box
                          minWidth="50px"
                          onClick={() =>
                            history.push({
                              pathname: "user-profile",
                              search: data?.actionBy._id,
                            })
                          }
                        >
                          <Avatar
                            src={
                              data?.actionBy?.petPic
                                ? data?.actionBy?.petPic
                                : "images/User.png"
                            }
                          ></Avatar>
                          <Box className={imageBox}>
                            <img
                              src={
                                data?.actionBy?.profilePic
                                  ? data?.actionBy?.profilePic
                                  : "images/User.png"
                              }
                            />
                          </Box>
                        </Box>
                        <Box
                          ml={2}
                          mt={1}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (data?.postId && data?.postId?._id !== null) {
                              setModalData(data?.postId?._id);
                              setOpenModalPost(true);
                            } else if (data?.commentId && data?.commentId?._id !== null) {
                              setModalData(data?.commentId?.postId?._id);
                              setOpenModalPost(true);
                            }
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "break-spaces",
                              fontSize: "12px",
                            }}
                          >
                            {data?.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "break-spaces",
                              fontSize: "12px",
                            }}
                          >
                            {data?.description}
                          </Typography>

                          <Typography
                            variant="body1"
                            style={{ color: "#6C6C6C" }}
                          >
                            {moment(data?.createdAt).startOf("").fromNow()}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <IconButton
                          onClick={() => handleClearNotification(data?._id)}
                        >
                          <CloseIcon style={{ color: "#6FCFB9" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledMenuItem>
                </>
              );
            })}

          {notificationList && notificationList?.length === 0 && (
            <NodatafoundImage type={localeFunction("notification_text")} />
          )}
        </Box>
      </StyledMenu>

      <Box mx={1}>
        <UseProfileNavBar
          handleClickOpenEdit={handleClickOpenEdit}
          open={open}
          setOpen={setOpen}
          setRightBar={setRightBar}
          rightBar={rightBar}
          _isloading={_isloading}
          setIsLoading={setIsLoading}
          setDelete={setDelete}
          open_activate={open_activate}
          setOpenActivate={setOpenActivate}
          DeActivate={DeActivate}
          handleActivateAccount={handleActivateAccount}
        // _isloading={_isloading}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Box className={mainheader}>
        <Grid container alignItems="center">
          <Grid item xs={5} md={2}>
            <Hidden xsDown>
              <Box className="leftBox">
                <Logo width="180" />
              </Box>
            </Hidden>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              sm={7}
              md={6}
              align="left"
              display="flex !important"
              justifyContent="center !important"
              className={classes.GridClass7}
            >
              <SearchBoxField />

              <IconButton
                className={classes.iconbutton}
                onClick={handleClickOpenFilter}
              >
                <FilterListIcon
                  style={{ color: "#6FCFB9", fontSize: "30px" }}
                />
              </IconButton>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={7} align="right">
              {ProfileAndNotification}
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={4} align="right">
              {ProfileAndNotification}
            </Grid>
          </Hidden>
        </Grid>
        {openModalPost === true && (
          <Dialog
            open={openModalPost}
            onClose={() => {
              setOpenModalPost(false);
              setModalData(undefined);
            }}
            fullScreen={true}
          >
            <DialogContent>
              <Comment
                type="NOTIFICATION"
                dataObj={notificationList?.postId}
                modalData={modalData}
                notificationList={notificationList}
                postId={notificationList?.postId?._id}
                setOpenModal={setOpenModalPost}
                PostIdComment={modalData}
              />
            </DialogContent>
          </Dialog>
        )}
        <ModalComponent
          _openfilter={_openfilter}
          setOpenFilter={setOpenFilter}
          handleClickOpenFilter={handleClickOpenFilter}
          selectRadius={selectRadius}
          setRadius={setRadius}
          selectCountry={selectCountry}
          setCountry={setCountry}
          selectState={selectState}
          setState={setState1}
          city={city}
          setCity={setCity}
          breed={breed}
          setBreed={setBreed}
        />
        <CreatePostModal
          openCreatePost={openCreatePost}
          handleCloseCreatePost={handleCloseCreatePost}
          handleClickOpenModal={handleClickOpenModal}
          handleformSubmit={handleformSubmit}
          handleClickFriendModal={handleClickFriendModal}
          handlecloseformSubmit={handlecloseformSubmit}
        />
        <Addlocation
          handleClickCloseModal={handleClickCloseModal}
          openModal={openModal}
        />
        <TagFriend
          handleClickCloseFriendModal={handleClickCloseFriendModal}
          friendModal={friendModal}
          formSubmit={formSubmit}
        />
        <ProfileModal
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
          handleOpenShare1={handleOpenShare}
          auth={auth}
          _usertypeprofile={_usertypeprofile}
          ChangeProfileFunction={ChangeProfileFunction}
          PetCategoryType={PetCategoryType}
          handleDeleteAccount={handleDeleteAccount}
          _isloading={_isloading}
          _delete={_delete}
        />{" "}
        <AddPetProfile
          openEdit={openAddmodal}
          PetCategoryType={PetCategoryType}
          petCategoryList={petCategoryList}
          handleCloseShare={handleCloseShare}
          handleOpenShare={handleOpenShare}
          auth={auth}
          _selectpetType={_selectpetType}
          setSelectPetType={setSelectPetType}
          _petcategoryliststate={_petcategoryliststate}
          _selectpetType1={_selectpetType1}
          setSelectPetType1={setSelectPetType1}
          profileImage64={profileImage64}
          setProfileImage64={setProfileImage64}
          PetTypeProfile={PetTypeProfile}
          profileData={profileData}
          setOpen={setOpen}
          type="LOGIN"
        />
        <Addstory
          openAddStory={openAddStory}
          setOpenAddStory={setOpenAddStory}
          closeAddStoryHandler={closeAddStoryHandler}
        />
      </Box>
    </>
  );
}
