import React from "react";
import { makeStyles, Box, Typography, Card, Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Link as RouterLink } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
const useStyles = makeStyles((theme) => ({
  mainBannerSection: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0px 6px 5px 6px",
    "& h3": {
      fontSize: "22px",
      fontWeight: 800,
      color: "#333333 !important",
    },
    "& .mainCard": {
      background: "white",
      borderRadius: "5px",
      padding: "10px",
      transition: "0.5s ease-in-out",
      boxShadow: "0 4px 8px rgb(0 0 0 / 16%)",
      maxHeight: "200px",
      minHeight: "180px",

      "& img": {
        width: "100%",
        maxHeight: "120px",
        objectFit: "contain",
      },
    },
    "& .abstractText": {
      display: "flex",
      alignItems: "center",
      "& h6": {
        color: "#fff !important",
        fontWeight: 500,
      },
    },

    "& p": {
      color: "#333 !important",
      fontSize: "13px",
      fontWeight: 400,
      padding: "10px 0px",
      textAlign: "center",
    },
  },

  TextBox: {
    "& h4": {
      color: "#333333",
      fontWeight: 600,
      fontSize: "16px",
      paddingBottom: "10px",
    },
  },
  button: {
    color: "#0076FF",
  },
  rating: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: "16px",
      fontWeight: "600",
    },
  },
  productNameClass: {
    fontSize: " 18px !important",
    color: "#000 !important",
    fontWeight: "600 !important",
  },
  IconBox2: {
    "& svg": {
      position: "absolute",
      left: "15px",
      top: "15px",
      padding: "5px",
      background: "#6fcfb9",
      color: "#fff",
      borderRadius: "20px",
      fontSize: "25px",
      cursor: "pointer",
    },
  },
  IconBox: {
    "& svg": {
      position: "absolute",
      right: "15px",
      top: "15px",
      padding: "5px",
      background: "#6fcfb9",
      color: "#fff",
      borderRadius: "20px",
      fontSize: "25px",
      cursor: "pointer",
    },
  },
}));

const PathBodyCard = (props) => {
  const classes = useStyles();
  const { data, ChangeProfileFunction, handleOpenShare1 } = props;
  return (
    <div className={classes.mainBannerSection}>
      <Box className={classes.slideMargin}>
        {data?.isDefaultUserProfile && (
          <>
            <Tooltip title={"Default Pet Profile"}>
              <Box className={classes.IconBox2}>
                <CheckIcon />
              </Box>
            </Tooltip>
          </>
        )}
        <Box className={classes.IconBox}>
          <Tooltip title={"Edit Pet Profile"}>
            <EditIcon onClick={() => handleOpenShare1(data)} />
          </Tooltip>
        </Box>
        <RouterLink
          className="hovertext"
          to="#"
          style={{
            textDecoration: "none",
            color: "#7bb8b0",
          }}
          onClick={() => {
            ChangeProfileFunction(data?.petCategoryId?._id);
          }}
        >
          <Card className="mainCard">
            <Box
              align="center"
              minHeight={"120px"}
              style={{ border: "2px solid black", borderRadius: "5px" }}
            >
              <img src={data?.userProfileImage} alt="bannerImg" />
            </Box>
            <Box className={classes.TextBox}>
              <Typography variant="body1" className={classes.productNameClass}>
                {data?.name}
              </Typography>
            </Box>
          </Card>
        </RouterLink>
      </Box>
    </div>
  );
};

export default PathBodyCard;
