import * as XLSX from "xlsx";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import moment from "moment";
import axios from "axios";
import { store } from "src/Redux/store";
export const socialkeys = {
  google: {
    clientid:
      "983146919205-7s5913op5uv4qg8vdcpnc2g2pn4vsmeh.apps.googleusercontent.com",
    clientsecret: "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",
  },
  facebook: {
    appid: "1317443778826434",
    secret: "93f2b0e0b940e09909ed1c5c691238ec",
  },
};
export const map = {
  googleMapKey: process.env.REACT_APP_GOOGLE_PLACES_KEY,
};
export const getAPIdata = async ({ params, data, endPoint, headers }) => {
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "GET",
      params: params,
      headers: headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          return res?.data;
        }
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return res;
  } catch (error) {
    return false;
  }
};

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}
export function sortAddress2(add) {
  const sortAdd = `${add?.slice(0, 15)}...${add?.slice(add.length - 2)}`;
  return sortAdd;
}

export const convertDateTime = (val) => {
  var tempDate = new Date(val);
  const toDateFormat = moment(tempDate).format("DD-MMM-yyyy hh:mm a");
  return toDateFormat;
};

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}
export function PostStringTitle(add) {
  if (add.length > 250) {
    const sortAdd = `${add?.slice(0, 250)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export function CharecterLengthShort(add) {
  if (add.length > 40) {
    const sortAdd = `${add?.slice(0, 30)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export function CharecterLengthLong(add) {
  if (add.length > 300) {
    const sortAdd = `${add?.slice(0, 300)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / ((1000 / 60) * 60 * 24)),
        hours: Math.floor((difference / ((1000 / 60) * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
export const downloadExcel = (dataToPrint, sheetName) => {
  const workSheet = XLSX.utils.json_to_sheet(dataToPrint);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${sheetName}.xlsx`);
};


export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {};
};
export const resizeBase64Img = async (base64, newWidth, newHeight) => {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement("canvas");
    canvas.style.width = newWidth.toString() + "px";
    canvas.style.height = newHeight.toString() + "px";
    let context = canvas.getContext("2d");
    let img = document.createElement("img");
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
};

export const resizeBase64Img2 = (base64, newWidth, newHeight) => {
  var canvas = base64;
  var ctx = canvas.getContext("2d");
  var maxW = 324;
  var maxH = 324;
  var img = new Image();
  img.onload = function () {
    var iw = img.width;
    var ih = img.height;
    var scale = Math.min(maxW / iw, maxH / ih);
    var iwScaled = iw * scale;
    var ihScaled = ih * scale;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
  };
  img.src = base64;
};
export const handleMultipleFileUpload = async (event) => {
  try {
    let res, formdata;
    formdata = new FormData();
    if (event?.length > 0) {
      event.forEach((file) => {
        formdata.append("files", file);
      });
    } else {
      formdata.append("files", event);
    }

    res = await Axios({
      url: Apiconfigs.uploadMultipleFile,
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res?.status === 200) {
          return res;
        }
      })
      .catch((error) => {
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};
export const SelectLanguageTypeFunction = async (data) => {
  try {
    const res = await Axios({
      url: Apiconfigs?.setDefaultUserLanguage,
      method: "POST",
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: {
        languageCode: data,
      },
    });
    if (res) {
      if (res?.data?.responseCode === 200) {
        return res;
      }
    }
  } catch (error) {
    return error;
  }
};

export const localeFunction = (key) => {
  let str = store.getState("auth");
  let langCode = str.auth.langCode;
  switch (langCode) {
    case "en":
      return require(`src/utils/lang/en`)[key];
    default:
      return require(`src/utils/lang/${langCode}`)[key];
  }
};
