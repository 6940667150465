import React from "react";
import { localeFunction } from "src/utils";
import Loader from "react-js-loader";
export default function DataLoader() {
  return (
    <div>
      <Loader
        type="bubble-spin"
        bgColor={"#535353"}
        title={localeFunction("loading_text")}
        color={"#535353"}
        size={60}
      />
    </div>
  );
}
