import axios from "axios";
import { getToken, deleteData } from "src/utils/sessionStorage";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  STORE_FIRE_TOKEN,
  SET_LANG,
} from "../Auth/actionType";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};
export const loginSuccess = (payload) => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};
export const loginFailure = (payload) => {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
};
export const logOut = () => {
  return {
    type: LOGOUT,
  };
};
export const storeFireToken = (payload) => {
  return {
    type: STORE_FIRE_TOKEN,
    payload,
  };
};
export const changeLanguage = (payload) => {
  return {
    type: SET_LANG,
    payload,
  };
};
export const loginUser = (payload) => (dispatch) => {
  dispatch(loginRequest());
  axios({
    method: "post",
    url: `${baseUrl}login`,
    data: payload,
  })
    .then((res) => {
      dispatch(loginSuccess(res.data));
    })
    .catch((err) => {
      let errMessage = "";
      let isLoggedIn = false;
      if (err.response !== undefined && err.response.status === 500) {
        errMessage = "Internal Server Error";
      } else if (err.response !== undefined && err.response.status === 400) {
        errMessage = err.response.data.message;
        isLoggedIn = err.response.data.isLoggedIn;
      } else {
        errMessage = err.message;
      }
      dispatch(loginFailure({ errMessage, isLoggedIn }));
    });
};
export const logoutUser = (payload) => (dispatch) => {
  axios({
    method: "post",
    url: `${baseUrl}logout`,
    headers: {
      "x-access-token": getToken(),
    },
  })
    .then((res) => {
      dispatch(logOut());
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status === 500) {
        toast.error("Internal Server Error");
      } else if (err.response !== undefined && err.response.status === 400) {
        toast.error(err.response.data.message);
      } else if (
        err.response !== undefined &&
        err.response.status === 401 &&
        err.response.data.logout
      ) {
        deleteData("loginInfo");
        window.location.href = "/login";
      } else {
        toast.error(err.message);
      }
    });
};
