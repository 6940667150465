import {
  makeStyles,
  Paper,
  Typography,
  Box,
  Dialog,
  Grid,
  Button,
  Avatar,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { localeFunction } from "src/utils";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    "& h6": {
      color: "#000",
      paddingBottom: "10px",
    },
    "& .textBox": {
      display: "flex",
      alignItems: "center",
      padding: "10px 0px",
      "& img": {
        paddingRight: "10px",
      },
    },
    "& .active": {
      cursor: "pointer",
    },
    "& .inactive": {
      cursor: "pointer",
      position: "relative",
      zIndex: "1",
      "&::after": {
        content: "''",
        position: "absolute",
        left: "0",
        bottom: "0px",
        display: "block",
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        background: "rgba(0, 0, 0, 0.51)",
      },
    },
  },
  btnBox: {
    maxWidth: "350px",
  },
  avatarsection: {
    width: "100%",
    "& .MuiAvatar-root": {
      borderRadius: "10px !important",
    },
    "& .MuiAvatar-img": {
      width: "100% !important",
      borderRadius: "10px",
    },
  },
  avatarsection1: {
    width: "100%",
    "& .MuiAvatar-root": {
      borderRadius: "10px !important",
    },
    "& .MuiAvatar-img": {
      width: "100% !important",
      borderRadius: "10px",
    },
  },
  avatar: {
    width: "100%",
    minHeight: "calc(100vh - 381px)",
    background: "#b7b7b7",
  },
  avatar1: {
    width: "90px",
    height: "90px",
    background: "#b7b7b7",
  },
}));

export default function StoryPreview({
  handlePostsubmit,
  openStoryPreview,
  closeAddStoryHandler,
  closeStoryPreview,
  setAllImages,
  setImages,
  isLoading,
  images,
}) {
  const classes = useStyles();
  const [tabView, setTabView] = useState();
  const handleClsePriview = () => {
    closeStoryPreview();
    setAllImages([]);
    setImages([]);
    closeAddStoryHandler();
  };
  useEffect(() => {
    setTabView(images[0]?.data_url);
  }, [images[0]?.data_url]);
  return (
    <Dialog open={openStoryPreview} maxWidth="sm" fullWidth>
      <Paper>
        <Box className={classes.mainContainer}>
          <Typography variant="h6">
            {localeFunction("create_story_text4")}
          </Typography>

          <Box className="previewBox">
            <Box className={classes.avatarsection}>
              <Avatar
                src={
                  tabView ? tabView : "/images/MarketDescription/product.png"
                }
                className={classes.avatar}
              />
            </Box>
          </Box>
          <Grid container lg={12} xs={12} spacing={1}>
            {images &&
              images?.map((data) => {
                return (
                  <Grid item style={{ marginTop: "20px" }}>
                    <Box
                      onClick={() => setTabView(data["data_url"])}
                      className={
                        tabView === data["data_url"] ? "active" : "inactive"
                      }
                    >
                      <Box className={classes.avatarsection1}>
                        <Avatar
                          src={data["data_url"]}
                          className={classes.avatar1}
                        />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
          <Box pt={2} align="center">
            <Box className={classes.btnBox}>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleClsePriview}
                  >
                    {localeFunction("create_story_text5")}
                  </Button>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={handlePostsubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <ButtonCircularProgress />
                      </>
                    ) : (
                      <>{localeFunction("create_story_text6")}</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
