import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { localeFunction } from "src/utils";
import { GOOGLE_MAPS_API_KEY } from "src/apiConfig/config";

const useStyles = makeStyles((theme) => ({
  iconbtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  mainbox: {
    "& .typo": {
      paddingBottom: "14px",
      textAlign: "center",
      "& h5": {
        fontWeight: "700",
      },
    },
    "& .minbox": {
      overflow: "scroll",
      padding: "17px 25px",
      background: "#FFFFFF",
      border: "1px solid #DFDFDF",
      borderRadius: "7px",
      minHeight: "calc(50vh - 168px)",
    },
    "& .imgbox": {
      paddingTop: "20px",
      display: "flex",
      alignItems: "center",
    },
    "& .typo2": {
      paddingLeft: "22px",
    },
  },
  btn2: {
    padding: "9px 20px",
  },
  btn3: {
    padding: "9px 50px",
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      padding: "9px 20px",
      fontSize: "13px",
    },
  },
}));

export default function RewardModal({ handleClickCloseModal, openModal }) {
  const classes = useStyles();
  const [_profile, setProfile] = useState("");
  const [_getSerchName, setSearchName] = useState("");
  const [_getLocation, setLocation] = useState();
  const [_Lat, setLat] = useState("");
  const [_Long, setLong] = useState("");
  const [address, setAddress] = useState("");
  const [_place_id, setPlaceId] = useState(
    window.localStorage.getItem("PLACE_Id")
  );
  const [_latcordinate, setLatCordinate] = useState();
  const [_longcordinate, setLongCordinate] = useState();
  const [_getUserSearchValue, setUserSearchValue] = useState(
    window.localStorage.getItem("UserLocation")
  );
  const [currentLocationData, setCurrentLocationData] = useState();

  const handleAddressChange = (add) => {
    setAddress(add);
  };
  const handleSelect = (address) => {
    let add = address.split(","),
      addr;
    if (add.length < 2) {
      addr = address;
    } else {
      addr = `${add[add.length - 3] ? add[add.length - 3] : ""},${
        add[add.length - 2] ? add[add.length - 2] : ""
      },${add[add.length - 1] ? add[add.length - 1] : ""}`;
    }
    geocodeByAddress(address)
      .then((results) => {
        if (results?.length > 1) {
          window.localStorage.setItem(
            "postLocation",
            results[1]?.formatted_address
          );
        } else {
          window.localStorage.setItem(
            "postLocation",
            results[0]?.formatted_address
          );
        }
        window.localStorage.setItem(
          "UserLocation",
          `${
            results[0].address_components[
              results[0].address_components.length - 6
            ]
              ? results[0].address_components[
                  results[0].address_components.length - 6
                ].short_name
              : ""
          }, ${
            results[0].address_components[
              results[0].address_components.length - 5
            ]
              ? results[0].address_components[
                  results[0].address_components.length - 5
                ].short_name
              : ""
          }, ${
            results[0].address_components[
              results[0].address_components.length - 3
            ]
              ? results[0].address_components[
                  results[0].address_components.length - 3
                ].short_name
              : ""
          }`
        );
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setLat(latLng.lat);
        setLong(latLng.lng);
        window.localStorage.setItem("UserLatitude", latLng.lat);
        window.localStorage.setItem("UserLongitute", latLng.lng);
        handleClickCloseModal();
      })
      .catch((error) => console.error("Error", error));
  };

  const ChangeCurrentlocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (location) => {
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coords.latitude},${location.coords.longitude}&key=${GOOGLE_MAPS_API_KEY}`
        );
        let add;
        add = `${
          res?.data?.results[0].address_components[
            res?.data?.results[0].address_components.length - 6
          ]
            ? res?.data?.results[0].address_components[
                res?.data?.results[0].address_components.length - 6
              ].short_name
            : ""
        }, ${
          res?.data?.results[0].address_components[
            res?.data?.results[0].address_components.length - 5
          ]
            ? res?.data?.results[0].address_components[
                res?.data?.results[0].address_components.length - 5
              ].short_name
            : ""
        }, ${
          res?.data?.results[0].address_components[
            res?.data?.results[0].address_components.length - 3
          ]
            ? res?.data?.results[0].address_components[
                res?.data?.results[0].address_components.length - 3
              ].short_name
            : ""
        }`;
        setCurrentLocationData(add);
        setLat(location.coords.latitude);
        setLong(location.coords.longitude);
      });
    } else {
      let res = "Geolocation is not supported by this browser.";
    }
  };
  useEffect(() => {
    if (openModal) {
      handleAddressChange("");
    }
  }, [openModal]);
  return (
    <Dialog
      open={openModal}
      onClose={handleClickCloseModal}
      maxWidth="sm"
      fullWidth
    >
      <Box className={classes.iconbtn}>
        <IconButton onClick={handleClickCloseModal}>
          <CloseIcon style={{ color: "#000" }} />
        </IconButton>
      </Box>
      <DialogContent>
        <Box className={classes.mainbox}>
          <Box className="typo">
            <Typography variant="h5">
              {localeFunction("post_add_location_text")}
            </Typography>
          </Box>
          <PlacesAutocomplete
            className={classes.autocompleteClass}
            value={address}
            onChange={handleAddressChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <Box className="minbox">
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search Location"
                    {...getInputProps({
                      placeholder: `${localeFunction(
                        "post_search_placeholder_text"
                      )}`,
                      className: "location-search-input",
                    })}
                    inputProps={{ "aria-label": "search" }}
                  />
                </Box>

                {loading && (
                  <div style={{ marginTop: "5px" }}>
                    {localeFunction("loading_text")}
                  </div>
                )}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";

                  const style =
                    suggestion.active === true
                      ? {
                          backgroundColor: "#fff",
                          cursor: "pointer",
                          color: "#404040",
                          borderBottom: "1px solid #e7e7e7",
                          marginTop: "9px",
                          fontSize: "14px",
                          fontWeight: "400",
                          padding: "4px 30px 8px 0px",
                        }
                      : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                          color: "#404040",
                          borderBottom: "1px solid #e7e7e7",
                          marginTop: "9px",
                          fontSize: "14px",
                          fontWeight: "400",
                          padding: "4px 30px 8px 0px",
                        };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <Box className="imgbox">
                        <Box className="typo2">
                          <Typography
                            variant="body2"
                            style={{ fontWeight: "600" }}
                          >
                            {suggestion.description}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  );
                })}
              </Box>
            )}
          </PlacesAutocomplete>

          <Box align="center">
            <Box mt={1.5}>
              <Typography
                variant="body2"
                style={{ color: " rgba(0, 0, 0, 0.3)" }}
              >
                {localeFunction("feed_or_text")}
              </Typography>
            </Box>
            <Box mt={1.5}>
              {currentLocationData ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.btn2}
                >
                  <Typography>{currentLocationData}</Typography>
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.btn2}
                  onClick={ChangeCurrentlocation}
                >
                  {localeFunction("feed_location_service_text")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
