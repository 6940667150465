import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { dataPostHandler } from "src/apiConfig/service";
import TagFriendCard from "./TagFriendCard";
import NodatafoundImage from "src/component/NoDataFound";
import { localeFunction } from "src/utils";
const useStyles = makeStyles((theme) => ({
  iconbtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  mainbox: {
    "& .typo": {
      paddingBottom: "14px",
      textAlign: "center",
      "& h5": {
        fontWeight: "700",
      },
    },
    "& .minbox": {
      height: "calc(100vh - 400px)",
      overflow: "scroll",
      padding: "17px 25px",
      background: "#FFFFFF",
      border: "1px solid #DFDFDF",
      borderRadius: "7px",
    },
  },
}));

export default function TagFriend({
  handleClickCloseFriendModal,
  friendModal,
  formSubmit,
}) {
  const classes = useStyles();
  const [search, setSearch] = useState();
  const [tabFriend, setTabFriend] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [tagId, settagId] = useState([]);
  const [_userTag, setUerTag] = useState([]);
  window.localStorage.setItem("tagfnIdd", tagId);
  window.localStorage.setItem("tagfrndiddata", JSON.stringify(tagId));
  useEffect(() => {
    if (_userTag) {
      window.localStorage.setItem("userTagData", JSON.stringify(_userTag));
    }
  }, [_userTag]);
  useEffect(() => {
    if (formSubmit) {
      settagId([]);
      setUerTag([]);
    }
  }, [formSubmit]);
  const handleFormSubmit = async (values) => {
    setLoader(true);
    try {
      const data = search
        ? {
            search: search,
          }
        : "";

      const response = await dataPostHandler("tagPeopleList", data);
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          setTabFriend(response?.data?.result?.docs);
          setLoader(false);
        } else if (response) {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (search > 0) {
      handleFormSubmit();
    } else {
      handleFormSubmit();
    }
  }, [search]);

  return (
    <Dialog
      open={friendModal}
      onClose={handleClickCloseFriendModal}
      maxWidth="sm"
      fullWidth
    >
      <Box className={classes.iconbtn}>
        <IconButton onClick={handleClickCloseFriendModal}>
          <CloseIcon style={{ color: "#000" }} onClick={() => setSearch("")} />
        </IconButton>
      </Box>
      <DialogContent>
        <Box className={classes.mainbox}>
          <Box className="typo">
            <Typography variant="h5">
              {localeFunction("feed_story_text7")}
            </Typography>
          </Box>
          <Box className="minbox">
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={localeFunction("otherUser_profile_12")}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Box>
            {tabFriend &&
              tabFriend?.map((data, i) => {
                return (
                  <>
                    <TagFriendCard
                      data={data}
                      index={i}
                      key={data._id}
                      tagId={tagId}
                      settagId={settagId}
                      tabFriend={tabFriend}
                      setUerTag={setUerTag}
                      _userTag={_userTag}
                    />
                  </>
                );
              })}
            {tabFriend && tabFriend.length === 0 && <NodatafoundImage />}
          </Box>
        </Box>

        <Box pt={2} align="center">
          <Button
            variant="contained"
            style={{ padding: "10px 50px" }}
            color="secondary"
            onClick={() => handleClickCloseFriendModal(tagId)}
          >
            {localeFunction("feed_story_Done_btn")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
