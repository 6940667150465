import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  withStyles,
  MenuItem,
  Menu,
  Hidden,
  Avatar,
  Grid,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { localeFunction } from "src/utils";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#FFFFFF !important",
    minWidth: "120px !important",
    boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
    borderRadius: "5px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    "& h4": {
      fontWeight: "600",
      padding: "0 20px",
    },
    "& h6": {
      fontWeight: "600",
      padding: "15px",
    },
  },
  iconbutton: {
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
    },
    "& div": {
      height: "5px",
      width: "5px",
      borderRadius: "50%",
      backgroundColor: "#6FCFB9",
      position: "absolute",
      top: "7px",
      right: "8px",
    },
  },
  addStoryMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "5px 0px",
    "& figure": {
      margin: "0px",
      width: "15px",
      height: "15px",
      "& img": {
        width: "100%",
      },
    },
    "& p": {
      marginLeft: "10px",
    },
    "& .MuiAvatar-root": {
      width: "18px",
      height: "18px",
    },
  },
  MarketNavbar: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",

    justifyContent: "space-between",
    "& .iconButtons": {
      width: "20px",
      display: "flex",
      alignItems: "center",
    },
  },
}));
function MarketFilterIconHeading({
  heading,
  type,
  servicelist,
  setPrductIdFun,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpenShare = () => {
    setAnchorEl(false);
  };
  return (
    <>
      {type === "MarketNavbar" ? (
        <Box className={classes.MarketNavbar} style={{}}>
          <Typography variant="h4">{heading}</Typography>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={handleClickOpenShare}>
              <Box className={classes.addStoryMenu}>
                <figure>
                  <img src="images/story/AddPostIcon.png" alt="PostIcon" />
                </figure>
                <Typography variant="body2">Post</Typography>
              </Box>
            </StyledMenuItem>
          </StyledMenu>
        </Box>
      ) : (
        <Grid container spacing={1}>
          <Hidden smDown>
            <Grid xs={12} md={5}></Grid>
          </Hidden>
          <Grid xs={12} md={7}>
            <Box className={classes.root}>
              <Typography variant="h4">{heading}</Typography>
              <IconButton className={classes.iconbutton} onClick={handleClick}>
                <FilterListIcon
                  style={{ color: "#6FCFB9", fontSize: "30px" }}
                />
              </IconButton>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {heading === "Product on Sale" && (
                  <Typography
                    variant="h6"
                    style={{ padding: "15px", fontWight: "600" }}
                  >
                    {localeFunction("market_product_text_2")}
                  </Typography>
                )}
                {heading === "Pets on sale" && (
                  <Typography
                    variant="h6"
                    style={{ padding: "15px", fontWight: "600" }}
                  >
                    {localeFunction("MarketFilterIconHeading_service_text_2")}
                  </Typography>
                )}
                {heading === "Services on sale" && (
                  <Typography
                    variant="h6"
                    style={{ padding: "15px", fontWight: "600" }}
                  >
                    {localeFunction("MarketFilterIconHeading_service_text_3")}
                  </Typography>
                )}
                {servicelist &&
                  servicelist.map((values) => {
                    return (
                      <StyledMenuItem onClick={handleClickOpenShare}>
                        <Box
                          className={classes.addStoryMenu}
                          onClick={() => setPrductIdFun(values?._id)}
                        >
                          <figure>
                            <Avatar src={values?.categoryImage} />
                          </figure>
                          <Typography variant="body2">
                            {values?.categoryName}
                          </Typography>
                        </Box>
                      </StyledMenuItem>
                    );
                  })}
              </StyledMenu>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default MarketFilterIconHeading;
