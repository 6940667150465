import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MarketLayout from "src/layouts/DashboardLayout/indexMarket";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LoginButtons")),
  },
  {
    exact: true,
    path: "/signUp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/SignupButton.js")),
  },
  {
    exact: true,
    path: "/signup-user",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup.js")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/reset-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetOtp")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/select-language",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/SelectLanguages/SelectLanguageComponent")
    ),
  },
  {
    exact: true,
    path: "/check-email",
    guard: true,
    component: lazy(() => import("src/views/auth/signup/CheckEmail")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/feeds",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/webvideo",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/webvideo")),
  },
  {
    exact: true,
    path: "/webvoice",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/VideoCall")),
  },
  {
    exact: true,
    path: "/webvoice-test",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/webaudio")),
  },
  {
    exact: true,
    path: "/comment",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Comment")),
  },
  {
    exact: true,
    path: "/view-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile")),
  },
  {
    exact: true,
    path: "/user-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/OtherProfile/OtherProfile")
    ),
  },
  {
    exact: true,
    path: "/add-pets",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MissingPets/AddPets")),
  },
  {
    exact: true,
    path: "/missing-pets",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MissingPets")),
  },
  {
    exact: true,
    path: "/missing-pets-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MissingPets/MissingPetsDetails")
    ),
  },
  {
    exact: true,
    path: "/plans",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/ListPlan")
    ),
  },
  {
    exact: true,
    path: "/add-edit-missing-pets",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/MissingPets/AddEditMissingPets")
    ),
  },

  {
    exact: true,
    path: "/market-product",
    guard: true,
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Product/MarketProduct")),
  },
  {
    exact: true,
    guard: true,
    path: "/pets",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Pets")),
  },
  {
    exact: true,
    path: "/market-pets",
    guard: true,
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Pets/MarketPets")),
  },
  {
    exact: true,
    path: "/market-services",
    guard: true,
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Services/MarketServices")),
  },
  {
    exact: true,
    path: "/rewards",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Rewards/Rewards")),
  },
  {
    exact: true,
    path: "/transaction-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Rewards/TransactionHistory")),
  },
  {
    exact: true,
    path: "/notification-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Notification")),
  },

  {
    exact: true,
    path: "/notification",
    layout: HomeLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Notification/Notification")),
  },
  {
    exact: true,
    path: "/market",
    guard: true,
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Market/Market")),
  },
  {
    exact: true,
    path: "/pet-description",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Market/Pets/PetDescription")),
  },

  {
    exact: true,
    path: "/details",
    guard: true,
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Market/Pets/PetService")),
  },

  {
    exact: true,
    path: "/subscriber-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/SubscriberList")),
  },
  {
    exact: true,
    path: "/trending-list",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/TrendingList")),
  },
  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/StaticPage/TransactionHistory")
    ),
  },
  {
    exact: true,
    path: "/chat-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/chat")),
  },
  {
    exact: true,
    path: "/events",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event")),
  },
  {
    exact: true,
    path: "/event-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventDetails")),
  },
  {
    exact: true,
    path: "/event-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventList")),
  },
  {
    exact: true,
    path: "/add-event",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/AddEvent")),
  },
  {
    exact: true,
    path: "/play-game",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PlayGame")),
  },
  {
    exact: true,
    path: "/event-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventDetails")),
  },
  {
    exact: true,
    path: "/event-list",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Event/EventList")),
  },
  {
    exact: true,
    path: "/tracking-devices",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TrackingDevices")),
  },
  {
    exact: true,
    path: "/help-support",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/HelpFAQ/index.js")),
  },
  {
    exact: true,
    path: "/notification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticPage/Notification")),
  },
  {
    exact: true,
    path: "/about-us",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AboutUs")),
  },
  {
    exact: true,
    path: "/contact-us",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContactUs")),
  },
  {
    exact: true,
    path: "/suggestion",
    guard: true,
    component: lazy(() => import("src/views/pages/Discover/DiscoverPeaple")),
  },

  {
    exact: true,
    path: "/terms&conditions",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy&policy",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/favorites",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Favorite")),
  },
  {
    exact: true,
    path: "/interested",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Interested")),
  },
  {
    exact: true,
    path: "/mightlikeusers",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/SeeAllSuggestionUsersMightLike")
    ),
  },
  {
    exact: true,
    path: "/seeallusers",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/SeeAllSuggestionUsers")),
  },
  {
    exact: true,
    path: "/edit-pet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MissingPets/EditPet.js")),
  },
  {
    exact: true,
    path: "/story-preview",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Story/StoryPreview")
    ),
  },
  {
    exact: true,
    path: "/story-text",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Story/StoryText")),
  },
  {
    exact: true,
    path: "/videocall",
    guard: true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/VideoCall.js")),
  },
  {
    exact: true,
    path: "/market-doctor",
    guard: true,
    layout: MarketLayout,
    component: lazy(() => import("src/views/pages/Vet_Doctor/index.js")),
  },
  {
    exact: true,
    path: "/blocked-doctor",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Vet_Doctor/BlockedDocList.js")),
  },
  {
    exact: true,
    path: "/View-doctor-details",
    guard: true,
    layout: MarketLayout,
    component: lazy(() =>
      import("src/views/pages/Vet_Doctor/View_doctor_details.js")
    ),
  },
  {
    exact: true,
    path: "/add-doctor-appointment",
    guard: true,
    layout: MarketLayout,
    component: lazy(() =>
      import("src/views/pages/Vet_Doctor/Add_appointment.js")
    ),
  },
  {
    exact: true,
    path: "/list-appointment",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Vet_Doctor/AppointmentList.js")
    ),
  },
  {
    exact: true,
    path: "/view-appointment",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Vet_Doctor/ViewAppointment.js")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    guard: true,
    path: "/successful",
    component: lazy(() => import("src/views/payment/PaymentSuccess")),
  },
  {
    exact: true,
    guard: true,
    path: "/failure",
    component: lazy(() => import("src/views/payment/PaymentFail")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
