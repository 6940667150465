import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CgRadioChecked, CgRadioCheck } from "react-icons/cg";
const useStyles = makeStyles((theme) => ({
  maintag: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "& .typo": {
      paddingBottom: "14px",
      textAlign: "center",
      "& h5": {
        fontWeight: "700",
      },
    },

    "& .imgbox": {
      paddingTop: "20px",
      display: "flex",

      alignItems: "center",
    },

    "& .typo2": {
      paddingLeft: "22px",
      cursor: "pointer",
    },
  },
  imageBox: {
    "& img": {
      width: "20px",
      position: "relative",
      bottom: "15px",
      zIndex: "1",
      left: "23px",
      border: "1px solid #6fcfb9",
      borderRadius: "100px",
      height: "20px",
    },
  },
  avtarBox: {
    "& .MuiAvatar-img": {
      width: "auto ",
      height: "auto ",
      objectFit: "cover"
    }
  },
  UserProfile: {
    border: "2px solid #6fcfb9",
  },
}));

export default function TagFriendCard({
  data,
  settagId,
  tagId,
  setUerTag,
  _userTag,
}) {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    const filterFun = tagId?.find((value) => {
      return value === data?._id;
    });
    if (filterFun) {
      setIsChecked(true);
    }
  }, [tagId,data._id]);
  return (
    <div>
      {" "}
      <Box
        className={classes.maintag}
        onClick={() => {
          setIsChecked(!isChecked);
          if (!isChecked) {
            settagId([...tagId, data?._id]);
            setUerTag([..._userTag, data]);
          } else {
            const filterFun = tagId.filter((value) => {
              return value !== data._id;
            });
            settagId(filterFun);
            let userTag = _userTag.filter((value) => {
              return value._id !== data._id;
            });
            setUerTag(userTag);
          }
        }}
      >
        <Box className="imgbox">
          <Box minWidth="65px">
            <Box
              className={
                data?.petPic === undefined
                  ? `${classes.avtarBox} avatarbox1`
                  : `${classes.avtarBox} avatarbox1 `
              }
            >
              <Avatar
                className={classes.UserProfile}
                src={data?.petPic}
              />
            </Box>
            <Box className={classes.imageBox}>
              <img src={data?.profilePic} alt="" />
            </Box>
          </Box>
          {/* <Avatar src={data?.profilePic}></Avatar> */}
          <Box className="typo2">
            <Typography variant="body2" style={{ fontWeight: "600" }}>
              {data?.name}
            </Typography>

            <Typography variant="body2" style={{ color: "#B6B6B6" }}>
              {data?.userName ? data?.userName : "..."}
            </Typography>
          </Box>
        </Box>
        <Box>
          {!isChecked ? (
            <CgRadioCheck style={{ fontSize: "18px", marginTop: "22px" }} />
          ) : (
            <CgRadioChecked style={{ fontSize: "18px", marginTop: "22px" }} />
          )}
        </Box>
      </Box>
    </div>
  );
}
