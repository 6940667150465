import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import PetTypeProfileCard from "src/component/PetTypeProfileCard";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { IoClose } from "react-icons/io5";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { localeFunction } from "src/utils";
const useStyles = makeStyles((theme) => ({
  AddPetPype: {
    background: "#6fcfb9",
    position: "absolute",
    top: "15px",
    color: "#fff",
    "&:hover": {
      background: "#6fcfb9",
      color: "#fff",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#6fcfb9",
  },
  MuiContainerClass: {
    width: "500px",
    height: "500px",
    padding: "0 20px",
    "& h5": {
      // fontSize: "18px",
      // fontWeight: "700",
    },
    "@media(max-width:720px": {
      width: "250px",
      height: "250px",
    },
  },
  photoCancelBtn: {
    color: "red",
  },
}));
const ProfileModal = ({
  openEdit,
  handleCloseEdit,
  auth,
  handleOpenShare1,
  _usertypeprofile,
  ChangeProfileFunction,
  PetCategoryType,
  _delete,
  handleDeleteAccount,
  _isloading,
}) => {
  const classes = useStyles();
  useEffect(() => {
    PetCategoryType();
  }, []);
  return (
    <div>
      <Box>
        <Dialog open={openEdit} onClose={handleCloseEdit} maxWidth="md">
          <DialogContent
            className={classes.MuiContainerClass}
            style={_delete === "" ? {} : { width: "auto", height: "auto" }}
          >
            <Box mb={3} p={"0 20px"}>
              <Box
                display={"flex"}
                justifyContent="end"
                style={{ position: "absolute", top: "0", right: "0" }}
              >
                <IconButton
                  className={classes.photoCancelBtn}
                  onClick={handleCloseEdit}
                >
                  <IoClose className={classes.photoCancelBtn} />
                </IconButton>
              </Box>
              <Typography variant="h5" align="center">
                {_delete === ""
                  ? `${localeFunction("switch_prodile_text_1")}`
                  : `${localeFunction("switch_prodile_text_2")}`}
              </Typography>
              {_delete === "" ? (
                <>
                  {_usertypeprofile?.length < 4 && (
                    <>
                      <Box display={"flex"} justifyContent="end">
                        <IconButton
                          className={classes.AddPetPype}
                          onClick={handleOpenShare1}
                        >
                          <Tooltip title="Add Pet Profile">
                            <AddCircleIcon />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="h5" style={{ marginTop: "20px" }}>
                    {localeFunction("profile_modal_text_1")} <br />
                    {localeFunction("profile_modal_text_2")}
                  </Typography>
                  <Box mt={5} display="flex" justifyContent="center">
                    <Button
                      style={{ background: "#6fcfb9", color: "white" }}
                      color="primary"
                      variant="container"
                      onClick={handleCloseEdit}
                      disabled={_isloading}
                    >
                      {localeFunction("Add_Missing_Pets_81")}{" "}
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      style={{ background: "#6fcfb9", color: "white" }}
                      color="primary"
                      variant="container"
                      onClick={handleDeleteAccount}
                      disabled={_isloading}
                    >
                      {localeFunction("profile_modal_text_3")}{" "}
                      {_isloading && <ButtonCircularProgress color="#fff" />}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
            {_delete === "" && (
              <Grid container spacing={2}>
                {_usertypeprofile?.length !== 0 ? (
                  <>
                    {_usertypeprofile &&
                      _usertypeprofile.map((data, index) => {
                        return (
                          <>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <PetTypeProfileCard
                                handleOpenShare1={handleOpenShare1}
                                data={data}
                                ChangeProfileFunction={ChangeProfileFunction}
                              />
                            </Grid>
                          </>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <Box mt={10}>
                      <Typography variant="h4">
                        {localeFunction("profile_modal_text_4")}
                      </Typography>
                    </Box>
                  </>
                )}
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </div>
  );
};

export default ProfileModal;
