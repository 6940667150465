import {
  Typography,
  Box,
  Paper,
  Grid,
  Button,
  FormControl,
  TextField,
  IconButton,
  Avatar,
  Dialog,
  DialogContent,
  Divider,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useInView } from "react-intersection-observer";
import CommentBox from "./CommentBox";
import Slider from "react-slick";
import MultyUser from "./MultyUser";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { TfiCommentAlt } from "react-icons/tfi";
import { RiShareForwardLine } from "react-icons/ri";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { FiMoreVertical } from "react-icons/fi";
import ShareModal from "./ShareModal";
import ReportModal from "./ReportModal";
import moment from "moment";
import {
  getParticularObjectDataHanndler,
  dataPostHandler,
} from "src/apiConfig/service";
import { AuthContext } from "src/context/Auth";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { toast } from "react-toastify";
import DataLoader from "./DataLoader";
import Comment from "src/views/pages/Dashboard/Comment";
import { localeFunction } from "src/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReadMore from "./ReadMore";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";
const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    minWidth: "85px !important",
    boxShadow: "0px 0px 11px rgb(0 0 0 / 16%)",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF !important",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  PostBox: {
    backgroundColor: "theme",
    border: "0.5px solid #DEDEDE",

    position: "relative",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
    "& p": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },

    "& .postImg": {
      width: "100%",
      margin: "16px 0",
      overflow: "hidden",
      cursor: "pointer",
      height: "100%",
      [theme.breakpoints.up("xs")]: {
        minHeight: "270px",
        maxHeight: "100%",
      },

      [theme.breakpoints.down("xs")]: {
        margin: "5px 0",
      },
      "& img": {
        width: "100%",
        height: "auto",
        objectFit: "fill",
      },
    },
    "& .postImg2": {
      width: "100%",
      margin: "16px 0",
      overflow: "hidden",
      cursor: "pointer",
      height: "400px",
      position: "relative",
      display: "flex !important",
      alignItems: "center",
      justifyContent: "center",
      maxHeight: "700px",
      minHeight: "270px",

      [theme.breakpoints.down("xs")]: {
        margin: "5px 0",
      },
      "& img": {
        width: "100%",
        height: "auto",
        objectFit: "fill",
      },
    },
    "& label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
    "& .commentBox": {
      borderTop: "0.5px solid #E1E1E1",
      borderBottom: "0.5px solid #E1E1E1",
      marginTop: "16px",
      padding: "5px 15px",
      [theme.breakpoints.down("xs")]: {
        padding: "0px 0",
        marginTop: "10px",
      },
      "& button": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px !important",
        },
        "& svg": {
          fontSize: "20px",
          marginRight: "5px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
          },
        },
      },
    },
    "& .searchaddress": {
      padding: "10px 25px 10px",
      // paddingTop: "16px",
      borderBottom: "0.5px solid #E1E1E1",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 0px 10px",
      },

      "& .figure": {
        margin: "0",
        marginRight: "15px",
        position: "relative",
        "& .profileimage": {
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          overflow: "hidden",
          backgroundColor: "#F5F5F5",
          [theme.breakpoints.down("xs")]: {
            height: "30px",
            width: "30px",
          },
          "& img": {
            width: "auto",
            maxWidth: "100%",
            maxHeight: "50px",
            width: "100%",
            [theme.breakpoints.down("xs")]: {
              maxHeight: "30px",
            },
          },
        },
      },
      "& button": {
        backgroundColor: "#6FCFB9",
        height: "49px",
        borderRadius: "7px",
        [theme.breakpoints.down("xs")]: {
          height: "30px",
          "& svg": {
            fontSize: "16px",
          },
        },
      },
      "& .MuiAvatar-root": {
        width: "40px",
        height: "40px",
        background: "#000",
        "@media(max-width:600px)": {
          width: "38px",
          background: "#000",
          height: "38px",
        },
      },
    },
    "& .UserBox": {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      padding: "25px 25px 0px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
        padding: "25px 0px 0px",
      },
      "& a": {
        textDecoration: "none",
        "& h6": {
          fontWeight: "700",
          color: "#202020",
          [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
          },
        },
      },
      "& small": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
      },
      "& figure": {
        margin: "0",
        marginRight: "15px",
        height: "60px",
        width: "60px",
        borderRadius: "50%",
        overflow: "hidden",
        backgroundColor: "#F5F5F5",
        [theme.breakpoints.down("xs")]: {
          height: "40px",
          width: "40px",
        },
        "& img": {
          width: "auto",
          maxWidth: "100%",
          maxHeight: "60px",
          [theme.breakpoints.down("xs")]: {
            maxHeight: "40px",
          },
        },
      },
    },
  },
  menuShare: {
    position: "absolute",
    top: "40px",
    right: "10px",
    [theme.breakpoints.down("xs")]: {
      top: "8px",
    },
  },

  GridClass: {
    display: "flex !important",
  },
  CommentBoxLike: {
    padding: "0 25px",
    maxHeight: "750px",
    // overflow: "scroll",
    "& .thumbLike": {
      color: "white",
      padding: "3px",
      background: "#6FCFB9",
      borderRadius: "50px",
      fontSize: "15px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px",
    },
  },
  SendCommentButton: {
    minWidth: "48px",
  },
  commentCountUser: {
    cursor: "pointer",
  },
  MaxHeightBox: {
    maxHeight: "auto",
  },
  MinHeightBox: {
    maxHeight: "830px",
    overflow: "hidden",
  },
  SeeMoreComment: {
    padding: "0px 20px",
  },
  spanClass: {
    wordBreak: "break-word",
    fontSize: "16px",
    fontWeight: "700",
    cursor: "pointer",
  },
  locationTextBox: {
    "& h6": {
      fontSize: "18px",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: ".9375rem",
      },
      "& span": {
        fontSize: "16px",
        [theme.breakpoints.down("xs")]: {
          fontSize: ".9375rem",
        },
      },
    },
    "& span": {
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: ".9375rem",
      },
    },
    "& body2": {
      fontSize: "16px",
      [theme.breakpoints.down("xs")]: {
        fontSize: ".9375rem",
      },
    },
  },
}));

export default function (props) {
  const {
    data,
    typeData,
    showHideMore,
    handleFormSubmit1,
    MyPostKey,
    CommentType,
    PostIdComment,
    modalData1,
    otherPostKey,
    type,
    typeComment,
    postList,
    finalarraydata,
    MyPostList,
  } = props;

  const history = useHistory();
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [comment, setComment] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [reportData, setReportData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [_height, setHeight] = useState(false);
  const [_height1, setHeight1] = useState(false);
  const [_showTextField, setShowTextField] = useState(false);
  const [commentHideShow, setCommentHideShow] = useState(
    CommentType || PostIdComment !== undefined ? CommentType : false
  );
  const [_shareId, setShaeId] = useState("");
  const [_currVid, setcurrvid] = useState();
  const [_readMore, setReadMore] = useState(true);
  const [reportType, setReportType] = useState("");
  const [_postId, setPOstID] = useState("");
  const MyProfileId = auth?.userData?._id;
  const [openShare, setOpenShare] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [likeBtn, setLikeBtn] = useState(data?.isLiked);
  const settings = {
    dots: false,
    slidesToShow: 1,
    arrows: true,
    centerMode: true,
    centerPadding: 0,
    autoplay: false,
    autoplaySpeed: false,
    infinite: true,
  };
  const toggleCommentBtn = () => {
    setCommentHideShow((current) => !current);
  };
  const toggleLikeBtn = () => {
    setLikeBtn(!likeBtn);
  };
  const commentHandler = async (id) => {
    if (comment !== "") {
      setComment("");
      try {
        const res = await Axios({
          method: "POST",
          url: Apiconfigs.addComment,
          headers: {
            token: sessionStorage.getItem("token"),
          },
          data: {
            typeOfPost: data?.postType,
            comment: comment,
            postId: id,
          },
        });
        if (res.data.responseCode === 200) {
          setIsLoading(false);
          commentListHandler(data?._id);
          handleFormSubmit(commentData[0]?._id);
          setCommentHideShow(true);

          setComment("");
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      toast.warn("Please enter your comment");
    }
  };
  const handleFormSubmit = async (id) => {
    try {
      const data = {
        commentId: id,
      };
      const endpoint = "repliesList";
      const response = await getParticularObjectDataHanndler(endpoint, data);
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleHidePost = async (id) => {
    try {
      const data = {
        _id: id,
      };
      const endpoint = "userPostHide";
      const response = await dataPostHandler(endpoint, data);
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          if (postList) {
            postList();
          }
          handleCloseReport();
          auth.handleRemovePost(id);

          auth.homePagePostListHandler(
            window.sessionStorage.getItem("token"),
            finalarraydata,
            auth.hometotalCurrentPage
          );
          toast.success("Post hidden successfully");
          setOpenModal(false);

          if (typeData === "modalcino") {
            setOpenModal(false);
          }
          history.push("/feeds");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const commentListHandler = async (id) => {
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.commentList,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          postId: id || PostIdComment || modalData1,
        },
      });
      if (res.data.responseCode === 200) {
        setCommentData(res.data.result.docs);
        // auth.homePagePostListHandler(
        //   window.sessionStorage.getItem("token"),
        //   finalarraydata,
        //   auth.hometotalCurrentPage
        // );
        setCommentHideShow(true);
        if (postList) {
          postList();
        }
        if (MyPostKey === "MYPOST") {
          handleFormSubmit1();
        }
      }
    } catch (error) {
      setCommentLoading(false);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOpenShare = () => {
    setOpenShare(true);
    setAnchorEl(false);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };
  const handleClickOpenReport = () => {
    setOpenReport(true);
    setAnchorEl(false);
  };
  const handleCloseReport = () => {
    setOpenReport(false);
  };
  const likeHandler = async (id) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.addLike,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          typeOfPost: data?.postType,
          postId: id,
        },
      });
      if (res.data.responseCode === 200) {
        setIsLoading(false);
        if (postList) {
          postList();
        }
        // auth.homePagePostListHandler(
        //   window.sessionStorage.getItem("token"),
        //   finalarraydata,
        //   auth.hometotalCurrentPage
        // );
        setComment("");
        if (typeData === "modalcino") {
          MyPostList();
        }
        if (MyPostKey === "MYPOST") {
          handleFormSubmit1();
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const reportdata = (data) => {
    setReportData(data);
  };
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      commentHandler(id);
    }
  };
  useEffect(() => {
    if (entry?.target) {
      if (inView) {
        setcurrvid(entry.target);
        entry.target.play();
      }
    }
  }, entry);
  useEffect(() => {
    if (_currVid && inView === false) {
      _currVid.pause();
    } else if (_currVid && inView === true) {
      _currVid.play();
    }
  }, [inView]);
  useEffect(() => {
    if (PostIdComment !== undefined) {
      commentListHandler();
    }
  }, [PostIdComment]);
  useEffect(() => {
    if (modalData1) {
      commentListHandler();
    }
  }, [modalData1]);
  const deletePostFuntion = async (id) => {
    try {
      const res = await Axios({
        url: Apiconfigs?.removePost,
        method: "DELETE",
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        params: {
          _id: id,
        },
      });
      if (res) {
        handleCloseReport();
        auth.homePagePostListHandler(
          window.sessionStorage.getItem("token"),
          finalarraydata,
          auth.hometotalCurrentPage
        );
        if (postList) {
          postList();
        }
        handleClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Paper elevation={0}>
      <LazyLoad height={200} offset={100}>
        <Box className={classes.PostBox}>
          <Box className="UserBox">
            <Avatar
              onClick={() => {
                if (MyProfileId === data?.userId?._id) {
                  history.push({
                    pathname: "/view-profile",
                  });
                } else {
                  history.push({
                    pathname: "/user-profile",
                    search: data?.userId?._id,
                  });
                }
              }}
              src={data?.userId?.profilePic}
              style={{
                width: "50px",
                height: "50px",
                cursor: "pointer",
                background: "#000",
              }}
            />
            &nbsp;&nbsp;
            <Box ml={1} className={classes.locationTextBox}>
              <Typography variant="h6">
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (MyProfileId === data?.userId?._id) {
                      history.push({
                        pathname: "/view-profile",
                      });
                    } else {
                      history.push({
                        pathname: "/user-profile",
                        search: data?.userId?._id,
                      });
                    }
                  }}
                >
                  {data?.userId?.name}{" "}
                </span>
                {data?.tagPeople?.length > 0 ? (
                  <>
                    <span
                      style={{
                        wordBreak: "break-word",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      {localeFunction("feeds_tag_text")}
                      &nbsp;
                      <span
                        className={classes.spanClass}
                        onClick={() => {
                          history.push({
                            pathname: "/user-profile",
                            search: data?.tagPeople[0]?._id,
                          });
                        }}
                      >
                        {data?.tagPeople[0]?.name}
                      </span>
                      <span
                        className={classes.spanClass}
                        onClick={() => {
                          history.push({
                            pathname: "/user-profile",
                            search: data?.tagPeople[1]?._id,
                          });
                        }}
                      >
                        {data?.tagPeople?.length > 1 && (
                          <>,{data?.tagPeople[1]?.name}</>
                        )}
                      </span>{" "}
                      {data?.tagPeople?.length > 2 && (
                        <span>
                          &nbsp;{localeFunction("feed_and_text")} &nbsp;
                          {data?.tagPeople?.length - 2}&nbsp;
                          {localeFunction("feed_other_text")}
                        </span>
                      )}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Typography>
              <Box display={"flex"}>
                {data?.address?.length > 0 && (
                  <>
                    <span
                      style={{
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                    >
                      {localeFunction("feed_in_text")}
                    </span>{" "}
                    &nbsp;
                    <Typography
                      style={{
                        wordBreak: "break-word",
                        fontWeight: "500",
                      }}
                      variant="body2"
                    >
                      {data?.address}
                    </Typography>
                  </>
                )}
              </Box>
              <Typography variant="body2" component="small">
                {moment(data?.createdAt).local().fromNow()}
              </Typography>
            </Box>
            <IconButton
              onClick={(e) => {
                handleClick(e);
                reportdata(data);
              }}
              aria-controls="customized-menu"
              aria-haspopup="true"
              className={classes.menuShare}
            >
              <FiMoreVertical />
            </IconButton>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleClickOpenShare}>
                <ListItemText primary={localeFunction("feed_share_text")} />
              </StyledMenuItem>
              {MyProfileId !== data?.userId?._id && (
                <StyledMenuItem
                  onClick={() => {
                    handleClickOpenReport();
                    setReportType("reportpost");
                  }}
                >
                  <ListItemText primary={localeFunction("feed_report_text")} />
                </StyledMenuItem>
              )}
              <StyledMenuItem
                onClick={() => {
                  handleClickOpenReport();
                  setReportType("hidepost");
                }}
              >
                <ListItemText primary={localeFunction("feed_hide_text")} />
              </StyledMenuItem>
              {MyProfileId === data?.userId?._id && (
                <StyledMenuItem
                  onClick={() => {
                    setReportType("deletepost");
                    handleClickOpenReport()
                    // deletePostFuntion(data?._id);
                  }}
                >
                  <ListItemText primary={localeFunction("feed_delete_text")} />
                </StyledMenuItem>
              )}
            </StyledMenu>
          </Box>
          {showHideMore !== true && data?.title.length > 250 ? (
            <ReadMore setReadMore={setReadMore}>
              <Typography
                variant="body2"
                style={{
                  wordBreak: "break-word",
                  padding: "0px 25px ",
                  fontWeight: "700",
                  marginTop: "10px",
                }}
              >
                {_readMore && _readMore ? (
                  <>{data?.title.slice(0, 270)}...</>
                ) : (
                  <>{data?.title}</>
                )}
              </Typography>
            </ReadMore>
          ) : (
            <>{(data?.title.trim() !== "\"\"") && (<>
              <Typography
                variant="body2"
                style={{
                  wordBreak: "break-word",
                  padding: "0 25px ",
                  fontWeight: "700",
                  marginTop: "10px",
                }}
              >
                {data?.title}
              </Typography>
            </>)}
            </>
          )}

          {data?.images?.length === 0 && data?.videos?.length === 0 ? (
            ""
          ) : (
            <Box>
              <Grid container spacing={0}>
                {data?.mediaUrls?.length > 1 ? (
                  <>
                    <Slider {...settings}>
                      {data?.mediaUrls &&
                        data?.mediaUrls.map((value) => {
                          return (
                            <figure
                              className="postImg2"
                              onClick={() => {
                                if (typeData !== "modalcino") {
                                  setModalData(data?._id);
                                  setPOstID(data?._id);
                                  setOpenModal(true);
                                  setCommentHideShow(true);
                                }
                              }}
                            >
                              {value?.type === "image" ? (
                                <LazyLoad>
                                  <LazyLoadImage
                                    width={"100%"}
                                    style={{ height: "100%" }}
                                    effect="blur"
                                    alt=""
                                    src={value?.media?.mediaUrlWeb}
                                  />
                                </LazyLoad>
                              ) : (
                                <LazyLoad>
                                  <video
                                    ref={ref}
                                    controls="false"
                                    muted
                                    style={{
                                      width: "100%",
                                      minHeight: "270px",
                                      maxHeight: "700px",
                                      background: "#000",
                                      height: "400px",
                                    }}
                                  >
                                    <source
                                      src={value?.media?.mediaUrlWeb}
                                      type="video/mp4"
                                    />
                                  </video>
                                </LazyLoad>
                              )}
                            </figure>
                          );
                        })}
                    </Slider>
                  </>
                ) : (
                  <figure
                    className="postImg"
                    onClick={() => {
                      if (typeData !== "modalcino") {
                        setModalData(data?._id);
                        setCommentHideShow(true);
                        setOpenModal(true);
                      }
                    }}
                  >
                    {data && data?.mediaUrls[0]?.type === "image" ? (
                      <LazyLoad>
                        {" "}
                        <LazyLoadImage
                          width={"100%"}
                          height={"100%"}
                          style={{ height: "100%" }}

                          effect="blur"
                          alt=""
                          src={data?.mediaUrls[0]?.media?.mediaUrlWeb}
                        />
                      </LazyLoad>
                    ) : (
                      <LazyLoad>
                        <video
                          ref={ref}
                          controls="false"
                          muted
                          height={400}

                          style={{
                            width: "100%",
                            maxHeight: "700px",
                            minHeight: "270px",
                            background: "#000",
                            height: "400px"
                          }}
                        >
                          <source
                            src={data?.mediaUrls[0]?.media?.mediaUrlWeb}
                            type="video/mp4"
                          />
                        </video>
                      </LazyLoad>
                    )}
                  </figure>
                )}
              </Grid>
            </Box>
          )}
          <Box className={classes.CommentBoxLike}>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                {" "}
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  mt={1}
                  style={
                    (data?.mediaUrls && data?.mediaUrls.length > 0) ||
                      (data?.videos && data?.videos.length > 0)
                      ? {}
                      : { marginTop: "20px" }
                  }
                >
                  <MultyUser like={data} MyProfileId={MyProfileId} />
                </Box>
              </Grid>
              <Grid item xs={7} align="right">
                <Box>
                  <label
                    className={classes.commentCountUser}
                    onClick={() => {
                      commentListHandler(data?._id);
                      setShowTextField(true);
                      toggleCommentBtn();
                    }}
                  >
                    {data?.commentCount !== 0 ? data?.commentCount : ""}
                    &nbsp;{localeFunction("feed_comments_text")}
                  </label>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>{localeFunction("feed_share_text")} </label>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="commentBox">
            <Grid container>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  size="large"
                  onClick={() => {
                    likeHandler(data?._id);
                    toggleLikeBtn();
                  }}
                >
                  {(likeBtn) ? (
                    <AiFillHeart style={{ color: "red" }} />
                  ) : (
                    <AiOutlineHeart />
                  )}{" "}
                  {data?.likeCount === 0 ? (
                    <>{localeFunction("feed_like_text")}</>
                  ) : data?.likeCount === 1 ? (
                    <>
                      {data?.likeCount}&nbsp;{localeFunction("feed_like_text")}
                    </>
                  ) : (
                    <>
                      {data?.likeCount}&nbsp;{localeFunction("feed_likes_text")}
                    </>
                  )}
                </Button>
              </Grid>
              <Grid item xs={4} align="center">
                <Button
                  color="primary"
                  size="large"
                  onClick={() => {
                    commentListHandler(data?._id);
                    setShowTextField(true);
                    toggleCommentBtn();
                  }}
                >
                  {" "}
                  <TfiCommentAlt style={{ transform: "rotateY(180deg)" }} />
                  {localeFunction("feed_comments_text")}
                </Button>
              </Grid>
              <Grid item xs={4} align="right">
                <Button
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleClickOpenShare();
                  }}
                >
                  {" "}
                  <RiShareForwardLine />
                  {localeFunction("feed_share_text")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="searchaddress">
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2} md={2} lg={2} xl={1} display={"flex"}>
                <Box display={"flex"}>
                  <Avatar src={auth?.userData?.profilePic} />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Box>
              </Grid>
              <Grid item xs={7} md={8} lg={8} xl={10} align="center">
                <FormControl fullWidth>
                  <Box display={"flex"}>
                    <TextField
                      style={
                        commentData?.length === 0 && _showTextField
                          ? {
                            border: "2px solid #6fcfb9",
                            borderRadius: "12px",
                          }
                          : {}
                      }
                      id="outlined-basic"
                      variant="outlined"
                      name="Text Field"
                      placeholder={localeFunction("Write_comment")}
                      type="text"
                      value={comment}
                      inputProps={{ maxLength: 900 }}
                      fullWidth
                      onChange={(e) => setComment(e.target.value)}
                      onKeyPress={(e) => handleKeyPress(e, data?._id)}
                      multiline
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={3} md={2} lg={2} xl={1} align="center">
                <Button
                  disabled={isLoading}
                  onClick={() => commentHandler(data?._id)}
                  className={classes.SendCommentButton}
                >
                  <SendIcon disabled={isLoading} style={{ color: "#FFFFFF" }} />{" "}
                </Button>
              </Grid>
            </Grid>
          </Box>
          {commentLoading ? (
            <DataLoader />
          ) : (
            <Box
              style={_height1 === "HIDE" ? { maxHeight: "0px !important" } : {}}
              className={
                !_height === true
                  ? `${classes.MaxHeightBox}`
                  : `${classes.MinHeightBox}`
              }
            >
              {commentHideShow ? (
                <>
                  <Box
                    className={classes.CommentBoxLike}
                    pt={"15px !important"}
                  >
                    {commentData &&
                      commentData.map((value, index) => {
                        if (
                          PostIdComment === undefined &&
                          commentData?.length > 3
                        ) {
                          if (index < 3 && typeComment !== "COMMENT") {
                            return (
                              <>
                                <CommentBox
                                  _height={_height}
                                  setHeight={setHeight}
                                  commentData={value}
                                  commentListHandler={() =>
                                    commentListHandler(data?._id)
                                  }
                                  commitiddd={data?._id}
                                  comment={comment}
                                  handleKeyPress={handleKeyPress}
                                />
                                <Divider />
                              </>
                            );
                          }
                        } else {
                          return (
                            <>
                              <CommentBox
                                _height={_height}
                                setHeight={setHeight}
                                commentData={value}
                                commentListHandler={() =>
                                  commentListHandler(data?._id)
                                }
                                commitiddd={data?._id}
                                comment={comment}
                                handleKeyPress={handleKeyPress}
                              />
                              <Divider />
                            </>
                          );
                        }
                      })}
                  </Box>

                  {PostIdComment === undefined && (
                    <>
                      {commentData?.length > 2 && (
                        <Box pl={3}>
                          <Button
                            onClick={() => {
                              if (typeData !== "modalcino") {
                                setModalData(data?._id);
                                setOpenModal(true);
                                setCommentHideShow(true);
                              }
                            }}
                          >
                            {localeFunction("feed_view_more_text")}
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </Box>
          )}
        </Box>
      </LazyLoad>

      <ShareModal
        openShare={openShare}
        handleCloseShare={handleCloseShare}
        url={window.location.href}
        _shareId={_shareId}
      />
      <ReportModal
        setOpenModal={setOpenModal}
        openReport={openReport}
        handleCloseReport={handleCloseReport}
        handleHidePost={handleHidePost}
        postId={data?._id}
        reportData={reportData}
        type={reportType}
        deletePostFuntion={deletePostFuntion}
      />
      {openModal === true && (
        <Dialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setModalData(undefined);
          }}
          fullScreen={true}
        >
          <DialogContent>
            <Comment
              dataObj={data}
              modalData={modalData}
              handleHidePost={handleHidePost}
              postId={data?._id}
              setOpenModal={setOpenModal}
              PostIdComment={modalData}
            />
          </DialogContent>
        </Dialog>
      )}
    </Paper>
  );
}
