import React, { useState, useEffect, useContext } from "react";
import { Container, IconButton, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "src/component/Page";
import { AuthContext } from "src/context/Auth";
import PostCard from "src/component/PostCard";
import NodatafoundImage from "src/component/NoDataFound";
import { MdCancel } from "react-icons/md";
import { localeFunction } from "src/utils";
import AdvertisementPostcard from "src/component/AdvertisementPostcard";
const useStyles = makeStyles((theme) => ({
  commentfullBox: {
    position: "absolute",
    top: "-17px",
    right: "8px",
    "& .canelIcon": {
      fontSize: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      top: "-22px",
      right: "0px",
    },
  },
}));

export default function ({
  dataObj,
  modalData,
  handleFormSubmit,
  MyPostKey,
  setOpenModal,
  PostIdComment,
  type,
  notificationList,
  otherPostKey,
  postList,
  _typeEvent,
  typeEvent,
  MyPostList,
  postId
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [arrayofpost, setarrayofpost] = useState([]);
  console.log("data?._idcdfsdf", dataObj, "postId", postId, "PostIdComment", PostIdComment, "notificationList", notificationList,"arrayofpost",arrayofpost);

  useEffect(() => {
    if (dataObj !==undefined) {
      setarrayofpost(dataObj);
    } else {
      if (typeEvent === "Event") {
        const filterdata1 = auth?.eventData?.filter((data, i) => {
          console.log("data?._id--->", data?._id);
          if (data?._id === modalData) {
            setarrayofpost(data);
          }
        });
      } else {
        const filterdata = auth?.homeList1?.filter((data, i) => {
          if (data?._id === modalData) {
            setarrayofpost(data);
          }
        });
      }
    }
  }, [auth.homeList1, dataObj, _typeEvent]);

  useEffect(() => {
    if (PostIdComment && notificationList) {
      const filterdata = notificationList?.filter((data, i) => {
        if (data?.postId?._id === PostIdComment) {
          setarrayofpost(data?.postId);
        } else if(data?.commentId?.postId===PostIdComment){
          setarrayofpost(data?.commentId?.postId);
        }
      });
    }
  }, [notificationList, PostIdComment])

  return (
    <Page title={localeFunction("feed_viewPost_text")}>
      {arrayofpost.length !== 0 ? (
        <Container maxWidth="md" style={{ position: "relative" }}>
          {typeEvent === "Event" ? (
            <>
              {" "}
              <AdvertisementPostcard data={arrayofpost} />
            </>
          ) : (
            <>
              <PostCard
                MyPostKey={MyPostKey}
                handleFormSubmit1={handleFormSubmit}
                data={arrayofpost}
                type="card"
                typeData="modalcino"
                showHideMore={true}
                CommentType={true}
                PostIdComment={PostIdComment}
                modalData={modalData}
                modalData1={modalData}
                otherPostKey={otherPostKey}
                typeComment="COMMENT"
                postList={postList}
                MyPostList={MyPostList}
              />
            </>
          )}

          <Box className={classes.commentfullBox}>
            <IconButton
              className="canelIcon"
              onClick={() => setOpenModal(false)}
            >
              <MdCancel style={{ color: "red" }} />
            </IconButton>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="md" style={{ position: "relative" }}>
          <NodatafoundImage type={"post"} />
          <Box className={classes.commentfullBox}>
            <IconButton
              className="canelIcon"
              onClick={() => setOpenModal(false)}
            >
              <MdCancel style={{ color: "red" }} />
            </IconButton>
          </Box>
        </Container>
      )}
    </Page>
  );
}
