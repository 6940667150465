import React, { useContext, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { MdCancel } from "react-icons/md";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { toast } from "react-toastify";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    color: "#6fcfb9",
    position: "absolute",
    top: 0,
    right: 0,
  },

  textField: {
    "& .MuiOutlinedInput-inputMultiline": {
      paddingTop: "10px",
    },
  },
  ReportTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
}));

export default function ShareModal({
  openReport,
  handleCloseReport,
  reportData,
  handleHidePost,
  type,
  deleteHandler,
  Id,
  postId,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const reportHandler = async (id) => {
    if (type === "hidepost") {
      if (auth?.userData?._id !== reportData?.userId?._id) {
        handleHidePost(postId);
      }
    } else {
      if (auth?.userData?._id === reportData?.userId?._id) {
        toast.warn("You can't report on your post");
      } else {
        if (description === "") {
          toast.warn("Please enter your message");
        } else {
          try {
            setIsLoading(true);
            const res = await Axios({
              method: "POST",
              url: Apiconfigs.createReport,
              headers: {
                token: sessionStorage.getItem("token"),
              },
              data: {
                postId: id,
                description: description,
              },
            });
            if (res.data.responseCode === 200) {
              toast.success(res.data.responseMessage);
              setIsLoading(false);
              handleCloseReport();
            }
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
          }
        }
      }
    }
  };

  return (
    <Box>
      <Dialog
        open={openReport}
        onClose={() => {
          if (!isLoading) {
            handleCloseReport();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <IconButton className={classes.cancelBtn} onClick={handleCloseReport}>
            <MdCancel />
          </IconButton>

          <Box mb={3}>
            <Typography
              variant="body2"
              style={{
                fontSize: "12px",
                padding: "0 25px",
                wordBreak: "break-word",
                fontWeight: "600",
              }}
            >
              {`Are you sure you want to delete this ${type}?`}
            </Typography>
          </Box>
          <Box mb={2} align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseReport}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={() => deleteHandler(Id, postId)}
              variant="contained"
              disabled={isLoading}
              color="secondary"
              style={{ marginLeft: "10px" }}
            >
              Delete {isLoading && <ButtonCircularProgress />}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
