import {
  makeStyles,
  Dialog,
  Box,
  Avatar,
  Button,
  Typography,
  DialogContent,
  Container,
  IconButton,
  TextField,
  FormControl,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { IoClose } from "react-icons/io5";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";
import DataLoader from "./DataLoader";
import NodatafoundImage from "./NoDataFound";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  mainconatiner: {
    height: "calc(100vh - 90px)",
  },
  searchBoxmodal: {
    position: "absolute",
    top: "0px",
    right: "8px",
    "& svg": {
      color: "#000",
      "@media(max-width:375px)": {
        marginRight: "-17px",
      },
    },
  },
  IconBox: {
    "& svg": {
      color: "#000",
      transform: "rotate(90deg)",
    },
  },
  dflex: {
    display: "flex",
    alignItems: "unset",
    cursor: "pointer",
    marginBottom: "10px",
    height: "54px",

    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      borderRadius: "12px",
      padding: "0px 5px",
    },
    "& h6": {
      cursor: "pointer",
      fontWeight: "500",
      width: "300px",
      textOverflow: "ellipsis",
      maxWidth: "300px",
      overflow: "hidden",
    },
    "& body2": {
      cursor: "pointer",
    },
  },
  dflex1: {
    display: "flex",
    alignItems: "self-start",
    justifyContent: "space-between",
  },

  imageBox: {
    "& img": {
      width: "25px",
      height: "25px",
      position: "relative",
      bottom: "15px",
      zIndex: "1",
      left: "23px",
      border: "1px solid #fff",
      borderRadius: "100px",
    },
  },

  searchControl: {
    position: "sticky",
    top: "0px",
    zIndex: 11,
  },
  userListBox: {
    padding: "16px 6px 0px",
    "@media(max-width:375px)": {
      padding: "16px 2px 0px",
    },
  },
  globally: {
    fontWeight: "600",
    paddingBottom: "12px",
  },
  mainBox: {
    "& .MuiDialogContent-root": {
      "@media(max-width:375px)": {
        padding: "8px 4px",
      },
    },
  },
}));
export default function GlobalSearchField({
  openEdit,
  handleCloseEdit,
  setOpenEdit,
}) {
  const {
    IconBox,
    searchBoxmodal,
    imageBox,
    mainconatiner,
    dflex,
    dflex1,
    searchControl,
    globally,
    searchBoxcontainer,
    userListBox,
    mainBox,
  } = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [allUser, setAllUser] = useState([]);
  const [following, setFollowing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoading, setdataLoading] = useState(true);
  const [globalSearchData, setGlobalSearchData] = useState({});
  const [Followsigleuser, setFollowSinglieUser] = useState(false);
  const followUnfollowUserHandler = async (id) => {
    setIsLoading(true);
    try {
      const res = await Axios({
        method: "PUT",
        url: Apiconfigs.followUnfollowUser + id,
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        toast.success(res.data.responseMessage);
        setIsLoading(false);
        auth.getUserProfileDatahandler(window.sessionStorage.getItem("token"));
        listUserHandler();
        setFollowSinglieUser(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const listUserHandler = async (search) => {
    try {
      const res = await Axios({
        method: "POST",
        url: Apiconfigs.globalSearch,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: search
          ? {
              search: search,
            }
          : {},
      });
      if (res?.data?.responseCode === 200) {
        setdataLoading(false);
        setGlobalSearchData({
          category: res?.data?.result?.categories,
          pets: res?.data?.result?.pets,
          products: res?.data?.result?.products,
          services: res?.data?.result?.services,
          sub_categories: res?.data?.result?.sub_categories,
        });
        if (res.data.result.users) {
          setAllUser(res.data.result.users);
        }
      } else {
        setAllUser([]);
        setdataLoading(false);
      }
    } catch (error) {
      setAllUser([]);
      setdataLoading(false);
    }
  };

  useEffect(() => {
    if (search || openEdit) {
      listUserHandler(search);
    }
  }, [search, openEdit]);

  return (
    <Dialog
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      className={mainBox}
    >
      <Box className={searchBoxmodal}>
        <IconButton onClick={() => handleCloseEdit(false)}>
          <IoClose />
        </IconButton>
      </Box>
      <DialogContent>
        <Container maxWidth="sm">
          <Box className={mainconatiner}>
            <Box className={searchBoxcontainer} pt={3.3}>
              <FormControl fullWidth className={searchControl}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                />
              </FormControl>
              {dataLoading ? (
                <DataLoader />
              ) : (
                <Box className={userListBox}>
                  {allUser &&
                    allUser?.map((data) => (
                      <Box className={dflex1}>
                        <Box
                          className={dflex}
                          onClick={() => {
                            history.push({
                              pathname: "user-profile",
                              search: data?._id,
                            });
                            setOpenEdit(false);
                          }}
                        >
                          <Box minWidth="65px">
                            <Avatar src={data?.petPic} />
                            <Box className={imageBox}>
                              <img
                                src={
                                  data?.profilePic
                                    ? data?.profilePic
                                    : "images/User.png"
                                }
                                alt=""
                              />
                            </Box>
                          </Box>
                          <Box width="100%">
                            <Typography variant="h6">
                              {data?.name ? data?.name : ""}
                            </Typography>
                            <Typography variant="body2">
                              {data?.userName ? data?.userName : ""}
                            </Typography>
                          </Box>
                        </Box>
                        {data?.isRequested === true ? (
                          <Button
                            disabled={isLoading}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              followUnfollowUserHandler(data?._id);
                            }}
                          >
                            Requested
                          </Button>
                        ) : (
                          <>
                            {data?.isFollow !== true ? (
                              <>
                                <Button
                                  disabled={isLoading}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    followUnfollowUserHandler(data?._id);
                                  }}
                                >
                                  Follow
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  disabled={isLoading}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    followUnfollowUserHandler(data?._id);
                                  }}
                                >
                                  Unfollow
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    ))}
                  {globalSearchData?.pets?.length !== 0 ? (
                    <Typography className={globally} variant="h3">
                      Pets
                    </Typography>
                  ) : (
                    ""
                  )}
                  {globalSearchData?.pets &&
                    globalSearchData?.pets?.map((data) => (
                      <Box className={dflex1}>
                        <Box className={dflex}>
                          <Box minWidth="65px">
                            <Avatar src={data?.petImage[0]} />
                            <Box className={imageBox}>
                              <img
                                src={
                                  data?.userId?.profilePic
                                    ? data?.userId?.profilePic
                                    : "/images/User.png"
                                }
                                alt=""
                              />
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              onClick={() =>
                                history.push({
                                  pathname: "/pet-description",
                                  search: data?._id,
                                  state: {
                                    heading: "Pets description",
                                    backbutton: "home",
                                  },
                                })
                              }
                            >
                              {data?.petName ? data?.petName : "..."}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {globalSearchData?.products?.length !== 0 ? (
                    <Typography className={globally} variant="h3">
                      Products
                    </Typography>
                  ) : (
                    ""
                  )}
                  {globalSearchData?.products &&
                    globalSearchData?.products?.map((data) => (
                      <Box className={dflex1}>
                        <Box className={dflex}>
                          <Box minWidth="65px">
                            <Avatar src={data?.productImage[0]} />
                            <Box className={imageBox}>
                              <img
                                src={
                                  data?.userId?.profilePic
                                    ? data?.userId?.profilePic
                                    : "/images/User.png"
                                }
                                alt=""
                              />
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              onClick={() =>
                                history.push({
                                  pathname: "/details",
                                  search: data?._id,
                                  state: {
                                    heading: "Product description",
                                    backbutton: "market-product",
                                  },
                                })
                              }
                            >
                              {data?.productName ? data?.productName : "..."}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  {globalSearchData?.services?.length !== 0 ? (
                    <Typography className={globally} variant="h3">
                      Services
                    </Typography>
                  ) : (
                    ""
                  )}
                  {globalSearchData?.services &&
                    globalSearchData?.services?.map((data) => (
                      <Box className={dflex1}>
                        <Box className={dflex}>
                          <Box minWidth="65px">
                            <Avatar src={data?.serviceImage[0]} />
                            <Box className={imageBox}>
                              <img
                                src={
                                  data?.userId?.profilePic
                                    ? data?.userId?.profilePic
                                    : "/images/User.png"
                                }
                                alt=""
                              />
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="h6"
                              onClick={() =>
                                history.push({
                                  pathname: "/details",
                                  search: data?._id,
                                  state: {
                                    heading: "Service description",
                                    backbutton: "market-services",
                                  },
                                })
                              }
                            >
                              {data?.serviceName ? data?.serviceName : "..."}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </Box>
              )}
              {!dataLoading &&
                allUser &&
                allUser.length === 0 &&
                following.length === 0 &&
                globalSearchData?.products?.length === 0 &&
                globalSearchData?.services?.length === 0 &&
                globalSearchData?.pets?.length === 0 && (
                  <NodatafoundImage type={"search"} />
                )}
            </Box>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
