import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Apiconfigs, { GOOGLE_MAPS_API_KEY } from "src/apiConfig/config";
import { getDataHandlerAPI } from "src/apiConfig/service";
import { calculateTimeLeft } from "src/utils/index";
import { dataPostHandler } from "src/apiConfig/service";
import { useSelector } from "react-redux";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
export const AuthContext = createContext();
const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};
function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const PathVariableName = window.location?.pathname.slice(1);
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [listStorydata, setlistStorydata] = useState([]);
  const [isLoadinghome, setLoaderHome] = useState(true);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [homeList, setHomeList] = useState([]);
  const [homeList1, setHomeList1] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [chatMessageData, setChatMessageData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const [address, setAddress] = useState("");
  const [_Lat, setLat] = useState("");
  const [_Long, setLong] = useState("");
  const [locationdata, setLocationdata] = useState({});
  const [welcomePop, setWelcomePop] = useState();
  const [_pettypecategory, setPetTypeCategory] = useState([]);
  const [_country, setCounrty] = useState("");
  const [_state, setState] = useState("");
  const [_city, setCity] = useState("");
  const [_miles, setMiles] = useState("");
  const [_breed, setBreed] = useState("");
  const [_applyfilter, setAplyFilter] = useState(false);
  const [_filterTypePage, setFilterTypePage] = useState("");
  const [productCategoryId, setProductCategory] = useState("");
  const [serviceCategory, setServiceCategory] = useState("");
  const [productdata, setProductdata] = useState([]);
  const [isLoading1, setIsLoading] = useState(true);
  const [_filterType, setFilterType] = useState("");
  const [petdata, setPetdata] = useState([]);
  const [servicedata, setServicedata] = useState([]);
  const [productList, setProductList] = useState([]);
  const [servicelist, setServiceList] = useState([]);
  const [loader, setLoaderMarket] = useState(true);
  const [_currencyCode, setCurrencyCode] = useState("");
  const [_ipaddress, setIpAddress] = useState("");
  const [_showinformation, setShowInformation] = useState("");
  const [_homepagination, setHomePagination] = useState(1);
  const [hometotalPages, setHometotalPages] = useState(1);
  const [hometotalCurrentPage, setHometotalCurrentPage] = useState(1);
  const fireToken = useSelector((state) => state.auth.firebaseToken);

  useEffect(()=>{
    if(!endTime){
      setEndtime(window.sessionStorage.getItem("otp-timer"))
    }
  },[window.sessionStorage.getItem("otp-timer")])
  const GetIpAddress = async () => {
    try {
      const res = await axios({
        url: Apiconfigs?.ipGet,
        method: "GET",
      });
      if (res) {
        setIpAddress(res?.data?.result?.ip);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const GetCurrencyCode = async () => {
    try {
      const res = await axios({
        url: Apiconfigs?.getCurrencyOne,
        method: "GET",
        params: {
          ip: _ipaddress,
        },
      });
      if (res) {
        setCurrencyCode(res?.data?.result?.currency);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (_ipaddress !== "") {
      GetCurrencyCode();
    }
  }, [_ipaddress]);
  useEffect(() => {
    // if (_ipaddress !== "") {
    GetIpAddress();
    // GetCurrencyCode();
    // }
  }, []);
  useEffect(() => {
    window.localStorage.removeItem("TAB_NAME");
    window.localStorage.removeItem("TAB_NUMBER");
  }, []);
  const CloseApplyFilterFUnction = () => {
    setAplyFilter(false);
    setFilterType("");
  };
  const handleLocatonFilter = () => {
    setLocationdata({
      lat: _Lat,
      long: _Long,
      mainadd: currentLocation,
    });
  };
  const handleRemovePost = (Id) => {
    setHomeList1(homeList1.filter((usr) => usr._id !== Id));
  };
  const getHomePagePosts = () => {
    return homeList1;
  };
  const getGetProductCategory = () => {
    return productCategoryId;
  };
  const getGetServiceCategory = () => {
    return serviceCategory;
  };
  const handleAddressChange = (add) => {
    setAddress(add);
  };
  const handleSelect = (address) => {
    window.localStorage.removeItem("_country");
    window.localStorage.removeItem("_state");
    window.localStorage.removeItem("_city");
    window.localStorage.removeItem("_miles");
    setLocationdata({
      lat: "",
      long: "",
      mainadd: "",
    });
    let add = address.split(","),
      addr;
    if (add.length < 2) {
      addr = address;
    } else {
      addr = `${add[add.length - 3] ? add[add.length - 3] : ""},${add[add.length - 2] ? add[add.length - 2] : ""
        },${add[add.length - 1] ? add[add.length - 1] : ""}`;
    }
    geocodeByAddress(address)
      .then((results) => {
        setCurrentLocation(
          `${results[0].address_components[
            results[0].address_components.length - 6
          ]
            ? results[0].address_components[
              results[0].address_components.length - 6
            ].short_name
            : ""
          }, ${results[0].address_components[
            results[0].address_components.length - 5
          ]
            ? results[0].address_components[
              results[0].address_components.length - 5
            ].short_name
            : ""
          }, ${results[0].address_components[
            results[0].address_components.length - 3
          ]
            ? results[0].address_components[
              results[0].address_components.length - 3
            ].short_name
            : ""
          }`
        );
        window.localStorage.setItem("userLocationSearch", results);
        window.localStorage.setItem(
          "UserLocation",
          `${results[0].address_components[
            results[0].address_components.length - 6
          ]
            ? results[0].address_components[
              results[0].address_components.length - 6
            ].short_name
            : ""
          }, ${results[0].address_components[
            results[0].address_components.length - 5
          ]
            ? results[0].address_components[
              results[0].address_components.length - 5
            ].short_name
            : ""
          }, ${results[0].address_components[
            results[0].address_components.length - 3
          ]
            ? results[0].address_components[
              results[0].address_components.length - 3
            ].short_name
            : ""
          },${results[0].address_components[
            results[0].address_components.length - 2
          ]
            ? results[0].address_components[
              results[0].address_components.length - 2
            ].short_name
            : ""
          },${results[0].address_components[
            results[0].address_components.length - 1
          ]
            ? results[0].address_components[
              results[0].address_components.length - 1
            ].short_name
            : ""
          }`
        );
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setLat(latLng.lat);
        setLong(latLng.lng);
        setLocationdata({
          lat: latLng?.lat,
          long: latLng?.lng,
          mainadd: currentLocation,
        });
        window.localStorage.setItem("UserLatitude", latLng.lat);
        window.localStorage.setItem("UserLongitute", latLng.lng);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  const ChangeCurrentlocation = async () => {
    setLocationdata({
      lat: "",
      long: "",
      mainadd: "",
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (location) => {
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coords.latitude},${location.coords.longitude}&key=${GOOGLE_MAPS_API_KEY}`
        );
        let add;
        add = `${res?.data?.results[0].address_components[
          res?.data?.results[0].address_components.length - 6
        ]
          ? res?.data?.results[0].address_components[
            res?.data?.results[0].address_components.length - 6
          ].short_name
          : ""
          }, ${res?.data?.results[0].address_components[
            res?.data?.results[0].address_components.length - 5
          ]
            ? res?.data?.results[0].address_components[
              res?.data?.results[0].address_components.length - 5
            ].short_name
            : ""
          }, ${res?.data?.results[0].address_components[
            res?.data?.results[0].address_components.length - 3
          ]
            ? res?.data?.results[0].address_components[
              res?.data?.results[0].address_components.length - 3
            ].short_name
            : ""
          }`;
        setCurrentLocation(add);
        setLat(location.coords.latitude);
        setLong(location.coords.longitude);
        window.localStorage.setItem("UserLocation", add);
        window.localStorage.setItem("UserLatitude", location.coords.latitude);
        window.localStorage.setItem("UserLongitute", location.coords.longitude);
      });
    } else {
      let res = "Geolocation is not supported by this browser.";
    }
  };
  const getUserProfileDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.profile,
        headers: {
          token: token,
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res?.data?.result);
        window.localStorage.setItem(
          "languageId",
          res?.data?.result?.languageId
        );
        window.localStorage.setItem("userName", res?.data?.result?.userName);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.responseCode === "403") {
          window.sessionStorage.removeItem("token");
        }
      }
    }
  };
  const handleFormSubmit = async (values) => {
    try {
      const data = window.sessionStorage.getItem("token");
      const response = await getDataHandlerAPI({ endPoint: "listMyStory", token: data });
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          setlistStorydata(response.data.result);

          setLoaderMarket(false);
        } else if (response) {
          setLoaderMarket(false);
        }
      } else {
        setLoaderMarket(false);
      }
    } catch (error) {
      console.log(error);
      setLoaderMarket(false);
    }
  };
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
      if (
        PathVariableName === ("market" || "market-product" || "market-services")
      ) {
        listCategoryHandler();
        productlist();
      }

      if (PathVariableName === "feeds") {
        listEventHandler();
      }
    }
  }, [window.sessionStorage.getItem("token")]);
  const listCategoryHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.listCategory,
        params: {
          type: "PET",
        },
      });
      if (res) {
        setCategory(res.data.result.docs);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const listSubCategoryHandler = async (selectedId) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.listSubCategory,
        params: {
          categoryId: selectedId !== "0" ? selectedId : undefined,
        },
      });
      if (res) {
        setSubCategory(res.data.result.docs);
      }
    } catch (error) { }
  };
  const homePagePostListHandler = async (token, OLD_DATA, _homepagination) => {
    try {
      const res = await axios({
        method: "POST",
        url: Apiconfigs.homePagePostList,
        headers: {
          token: token,
        },
        data: {
          page: _homepagination,
          limit: "10",
          country: _country,
          state: _state,
          city: _city,
          radius: _miles.toString(),
          petBreedId: _breed !== "0" ? _breed : undefined,
          lat: window.localStorage.getItem("UserLatitude")
            ? window.localStorage.getItem("UserLatitude")
            : undefined,
          long: window.localStorage.getItem("UserLongitute")
            ? window.localStorage.getItem("UserLongitute")
            : undefined,
        },
      });
      if (res.data.responseCode === 200) {
        setLoaderHome(false);
        let temp = OLD_DATA ? OLD_DATA : [];
        setHomeList([...temp, ...res.data.result.docs]);
        setHometotalPages(res.data.result.totalPages);
        setHometotalCurrentPage(res.data.result.page);
        setLoaderMarket(false);
      }
    } catch (error) {
      setLoaderHome(false);

      setLoaderMarket(false);
    }
  };
  useEffect(() => {
    if (window.sessionStorage.getItem("token") && PathVariableName === "feeds") {
      homePagePostListHandler(window.sessionStorage.getItem("token"), [], 1);
    }
  }, [window.sessionStorage.getItem("token"), PathVariableName]);
  const listEventHandler = async () => {
    try {
      // setLoaderHome(true);
      const res = await axios({
        method: "POST",
        url: Apiconfigs.listEvent,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: {
          page: "1",
          limit: "10",
          country: _country,
          state: _state,
          city: _city,
          radius: _miles.toString(),
          lat: window.localStorage.getItem("UserLatitude"),
          long: window.localStorage.getItem("UserLongitute"),
        },
      });
      if (res.data.responseCode === 200) {
        setLoaderHome(false);

        setEventData(res.data.result.docs);
      }
    } catch (error) {
      setLoaderHome(false);

      console.log(error);
      if (error?.response?.data?.responseCode === 404) {
        setLoaderHome(false);

        setEventData([]);
      }
    }
  };
  const listPetTypeCategoryHandler = async (token) => {
    try {
      const res = await axios({
        method: "POST",
        url: Apiconfigs.petCategoryList,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        params: {
          page: 1,
          limit: 10,
        },
      });
      if (res) {
        setPetTypeCategory(res?.data?.result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // pet function
  const [tabView_pet, setTabView_pet] = useState("PetTab");
  const [likesData_pet, setLikesData_pet] = useState([]);
  const [isLoading_pet, setIsLoading_pet] = useState(false);
  const [page_pet, setPage_pet] = useState(1);
  const [noOfPages_pet, setNoOfPages_pet] = useState(1);
  const mylikeListHndler_pet = async (Like) => {
    try {
      if (Like === undefined) {
        setIsLoading_pet(true);
      }
      const res = await axios({
        method: "GET",
        url: Apiconfigs.myFavPets,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        params: {
          country: _country ? _country : undefined,
          state: _state ? _state : undefined,
          city: _city ? _city : undefined,
          radius: _miles ? _miles : undefined,
          lat: window.localStorage.getItem("UserLatitude")
            ? window.localStorage.getItem("UserLatitude")
            : undefined,
          long: window.localStorage.getItem("UserLongitute")
            ? window.localStorage.getItem("UserLongitute")
            : undefined,
          page: page_pet,
          limit: "12",
        },
      });
      if (res.data.responseCode === 200) {
        setLikesData_pet(res.data.result.docs);
        setNoOfPages_pet(res.data.result.totalPages);
        setIsLoading_pet(false);
      }
      setIsLoading_pet(false);
    } catch (error) {
      setIsLoading_pet(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (page_pet && PathVariableName === "favorites") {
      mylikeListHndler_pet();
    }
  }, [page_pet, PathVariableName]);
  // Service function
  const [likesData_service, setLikesData_service] = useState([]);
  const [isLoading_service, setIsLoading_service] = useState(false);
  const [page_service, setPage_service] = useState(1);
  const [noOfPages_service, setNoOfPages_service] = useState(1);
  const mylikeListHndler_service = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.myLikesServices,
        headers: {
          token: sessionStorage.getItem("token"),
        },

        params: {
          country: _country.toString() ? _country.toString() : undefined,
          state: _state.toString() ? _state.toString() : undefined,
          city: _city.toString() ? _city.toString() : undefined,
          radius: _miles.toString() ? _miles.toString() : undefined,
          lat: window.localStorage.getItem("UserLatitude")
            ? window.localStorage.getItem("UserLatitude").toString()
            : undefined,
          long: window.localStorage.getItem("UserLongitute")
            ? window.localStorage.getItem("UserLongitute").toString()
            : undefined,
          page: page_service.toString(),
          limit: "12",
        },
      });
      if (res.data.responseCode === 200) {
        setLikesData_service(res.data.result.docs);
        setNoOfPages_service(res.data.result.totalPages);
        setIsLoading_service(false);
      }
      setIsLoading_service(false);
    } catch (error) {
      setIsLoading_service(false);
      console.log(error);
    }
  };
  // product function
  const [likesData_product, setLikesData_product] = useState([]);
  const [isLoading_product, setIsLoading_product] = useState(false);
  const [page_product, setPage_product] = useState(1);
  const [noOfPages_product, setNoOfPages_product] = useState(1);
  const mylikeListHndler_product = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.myLikesProduct,
        headers: {
          token: sessionStorage.getItem("token"),
        },

        params: {
          country: _country.toString() ? _country.toString() : undefined,
          state: _state.toString() ? _state.toString() : undefined,
          city: _city.toString() ? _city.toString() : undefined,
          radius: _miles.toString() ? _miles.toString() : undefined,
          lat: window.localStorage.getItem("UserLatitude")
            ? window.localStorage.getItem("UserLatitude").toString()
            : undefined,
          long: window.localStorage.getItem("UserLongitute")
            ? window.localStorage.getItem("UserLongitute").toString()
            : undefined,
          page: page_product.toString(),
          limit: "12",
        },
      });
      if (res.data.responseCode === 200) {
        setLikesData_product(res?.data?.result?.docs);
        setNoOfPages_product(res?.data?.result?.totalPages);
        setIsLoading_product(false);
      }
      setIsLoading_product(false);
    } catch (error) {
      setIsLoading_product(false);
      console.log(error);
    }
  };
  // list intrest
  const [likesData_intrest, setLikesData_intrest] = useState([]);
  const [isLoading_intrest, setIsLoading_intrest] = useState(true);
  const [page_intrest, setPage_intrest] = useState(1);
  const [noOfPages_intrest, setNoOfPages_intrest] = useState(1);
  const [typesdata_intrest, settypesdata_intrest] = useState("PET");
  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [likesData_soctorvet, setLikesData_doctorvet] = useState([]);
  const [noOfPages_soctorvet, setNoOfPages_doctorvet] = useState(1);
  const [isLoading_soctorvet, setIsLoading_doctorvet] = useState(false);

  const mylikeListHndler = async () => {
    try {
      if (
        _filterTypePage !== "INTRESTED" ||
        _filterTypePage !== "SERVICE" ||
        _filterTypePage !== "productId"
      ) {
        // setIsLoading_intrest(true);
      }
      const res = await axios({
        method: "POST",
        url: Apiconfigs.listInterested,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {
          type: typesdata_intrest,
          page: `${page_intrest}`,
          limit: "12",
          country: _country.toString(),
          state: _state.toString(),
          city: _city.toString(),
          radius: _miles.toString(),
          lat: window.localStorage.getItem("UserLatitude")
            ? window.localStorage.getItem("UserLatitude").toString()
            : undefined,
          long: window.localStorage.getItem("UserLongitute")
            ? window.localStorage.getItem("UserLongitute").toString()
            : undefined,
        },
      });
      if (res.data.responseCode === 200) {
        setLikesData_intrest(res.data.result.docs);
        setNoOfPages_intrest(res.data.result.pages);
        setIsLoading_intrest(false);
      }
      setIsLoading_intrest(false);
    } catch (error) {
      setIsLoading_intrest(false);
      console.log(error);
    }
  };

  const listVendorHndler = async () => {
    try {
      if (_filterTypePage === "DOCTORVET") {
        const res = await axios({
          method: "POST",
          url: Apiconfigs.listVendor,
          headers: {
            token: sessionStorage.getItem("token"),
          },
          data: {
            page: `${page_intrest}`,
            limit: "12",
            country: _country.toString(),
            state: _state.toString(),
            city: _city.toString(),
            radius: _miles.toString(),
            userTypes: "VENDORDOCTORVET",
            lat: window.localStorage.getItem("UserLatitude")
              ? window.localStorage.getItem("UserLatitude").toString()
              : undefined,
            long: window.localStorage.getItem("UserLongitute")
              ? window.localStorage.getItem("UserLongitute").toString()
              : undefined,
          },
        });
        if (res.data.responseCode === 200) {
          setLikesData_doctorvet(res.data.result.docs);
          setNoOfPages_doctorvet(res.data.result.pages);
          // setIsLoading_doctorvet(false);
        }
        setIsLoading_intrest(false);
      }
    } catch (error) {
      setIsLoading_intrest(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (typesdata_intrest && PathVariableName === "interested") {
      mylikeListHndler();
    }
  }, [typesdata_intrest, PathVariableName]);
  const [_deletepost, setDeletePost] = useState("");
  const productlist = async (id) => {
    try {
      // setIsLoading(true);
      const data = {
        categoryId: id ? id : undefined,
        lat: locationdata?.lat
          ? locationdata?.lat
          : window.localStorage.getItem("UserLatitude"),
        lng: locationdata?.long
          ? locationdata?.long
          : window.localStorage.getItem("UserLongitute"),
        country: window.localStorage.getItem("_country"),
        state: window.localStorage.getItem("_state"),
        city: window.localStorage.getItem("_city"),
        radius: window.localStorage.getItem("_miles"),
        search: search?.length > 1 ? search : "",
      };

      const response = await dataPostHandler("listProduct", data);
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          setProductdata(response.data.result.docs);

          setIsLoading(false);
        } else if (response) {
          setIsLoading(false);
        }
      } else {
        setProductdata([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const listservice = async (id) => {
    try {
      const data = {
        categoryId: id ? id : undefined,
        lat: locationdata?.lat
          ? locationdata?.lat
          : window.localStorage.getItem("UserLatitude"),
        long: locationdata?.long
          ? locationdata?.long
          : window.localStorage.getItem("UserLongitute"),
        country: window.localStorage.getItem("_country")
          ? window.localStorage.getItem("_country")
          : "",
        state: window.localStorage.getItem("_state")
          ? window.localStorage.getItem("_state")
          : "",
        city: window.localStorage.getItem("_city")
          ? window.localStorage.getItem("_city")
          : "",
        radius: window.localStorage.getItem("_miles")
          ? window.localStorage.getItem("_miles")
          : "",
        search: search1?.length > 1 ? search1 : "",
      };

      const response = await dataPostHandler("listService", data);
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          setServicedata(response?.data?.result?.docs);
          setLoaderMarket(false);
        } else if (response) {
          setLoaderMarket(false);
        }
      } else {
        setServicedata([]);

        setLoaderMarket(false);
      }
    } catch (error) {
      console.log(error);
      setServicedata([]);

      setLoaderMarket(false);
    }
  };
  const listCategoryHandlerService = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.listCategory,
        params: {
          type: "SERVICE",
        },
      });
      if (res.data.responseCode === 200) {
        setServiceList(res.data.result.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const listCategoryProductHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.listCategory,
        params: {
          type: "PRODUCT",
        },
        data: {
          country: window.localStorage.getItem("_country"),
          state: window.localStorage.getItem("_state"),
          city: window.localStorage.getItem("_city"),
          radius: window.localStorage.getItem("_miles"),
        },
      });
      if (res.data.responseCode === 200) {
        setProductList(res.data.result.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const petLisiting = async () => {
    try {
      const data = {
        lat: locationdata?.lat
          ? locationdata?.lat
          : window.localStorage.getItem("UserLatitude"),
        lng: locationdata?.long
          ? locationdata?.long
          : window.localStorage.getItem("UserLongitute"),

        country: window.localStorage.getItem("_country")
          ? window.localStorage.getItem("_country")
          : undefined,
        state: window.localStorage.getItem("_state")
          ? window.localStorage.getItem("_state")
          : undefined,
        city: window.localStorage.getItem("_city")
          ? window.localStorage.getItem("_city")
          : undefined,
        radius: window.localStorage.getItem("_miles")
          ? window.localStorage.getItem("_miles")
          : undefined,
      };

      const response = await dataPostHandler("listPet", data);
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          setPetdata(response.data.result.docs);
          setLoaderMarket(false);
        } else if (response) {
          setLoaderMarket(false);
        }
      } else {
        setLoaderMarket(false);
      }
    } catch (error) {
      console.log(error);
      setLoaderMarket(false);
    }
  };
  // Contact Details API
  const ContactUsFunction = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.contact_us_details,
        method: "GET",
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (res) {
        setShowInformation(res?.data?.result);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (PathVariableName === ("market-pets" || "market" || "pets")) {
      listCategoryHandlerService();
      petLisiting();
    }
    ContactUsFunction();
    if (
      !productCategoryId &&
      PathVariableName === ("market" || "market-product")
    ) {
      productlist();
      listCategoryHandlerService();
    }
    listCategoryProductHandler();
    if (productCategoryId) {
      productlist(productCategoryId);
    }
    if (
      serviceCategory &&
      PathVariableName === ("market" || "market-services")
    ) {
      listCategoryHandlerService();
      listservice(serviceCategory);
    }
  }, [productCategoryId || serviceCategory]);

  useEffect(() => {
    if (
      window.sessionStorage.getItem("token") ||
      typesdata_intrest ||
      page_intrest ||
      page_pet ||
      page_service ||
      page_product
    ) {
      window.localStorage.setItem("_country", _country);
      window.localStorage.setItem("_state", _state);
      window.localStorage.setItem("_city", _city);
      window.localStorage.setItem("_miles", _miles);
      if (PathVariableName === "feeds") {
        homePagePostListHandler(window.sessionStorage.getItem("token"), [], 1);
        listEventHandler();
      }
      handleUserModal();
      ChangeCurrentlocation();
      if (PathVariableName === "favorites") {
        mylikeListHndler_pet();
        mylikeListHndler_service();
        mylikeListHndler_product();
      }
      if (PathVariableName === "interested") {
        mylikeListHndler();
      }
    }
    // }
  }, [
    window.sessionStorage.getItem("token"),
    // page_intrest,
    typesdata_intrest,
    page_intrest,
    page_pet,
    page_service,
    page_product,
    _applyfilter,
    PathVariableName,
    _breed,
  ]);

  const ApplyFunction = () => {
    if (
      window.localStorage.getItem("_country") !== "" ||
      window.localStorage.getItem("_state") !== "" ||
      window.localStorage.getItem("_city") !== "" ||
      window.localStorage.getItem("_miles") !== "" ||
      _breed !== "0"
    ) {
      if (_filterTypePage === "HOME") {
        homePagePostListHandler(window.sessionStorage.getItem("token"), [], 1);
        listEventHandler();
        ChangeCurrentlocation();
      } else if (_filterTypePage === "MARKET") {
        setFilterType("MARKET");
        petLisiting();
        productlist();
        listservice();
      } else if (_filterTypePage === "MARKETSERVICE") {
        setFilterType("MARKETSERVICE");
      } else if (_filterTypePage === "FAVOURITE") {
        mylikeListHndler_pet();
        mylikeListHndler_service();
        mylikeListHndler_product();
      } else if (_filterTypePage === "PETS") {
        setFilterType("PETS");
      } else if (_filterTypePage === "INTRESTED") {
        setFilterType("INTRESTED");
      } else if (_filterTypePage === "PET") {
        mylikeListHndler();
      } else if (_filterTypePage === "SERVICE") {
        mylikeListHndler();
      } else if (_filterTypePage === "PRODUCT") {
        mylikeListHndler();
      } else if (_filterTypePage === "EVENT") {
        setFilterType("EVENT");
      } else if (_filterTypePage === "DOCTORVET") {
        listVendorHndler();
      } else {
        ChangeCurrentlocation();
        mylikeListHndler_pet();
        mylikeListHndler_service();
        mylikeListHndler_product();
      }
    }
  };
  useEffect(() => {
    if (PathVariableName === ("market" || "market-services")) {
      listservice();
    }
  }, [PathVariableName]);
  useEffect(() => {
    if (PathVariableName === ("market" || "market-product")) {
      listPetTypeCategoryHandler();
    }
    if (selectedId && PathVariableName === "market") {
      listCategoryHandler();
      listSubCategoryHandler(selectedId);
    }
  }, [selectedId, PathVariableName]);

  const handleUserModal = async (id) => {
    try {
      const data = window.sessionStorage.getItem("token");
      if (data !== "" && data !== undefined && data !== null) {
        const endpoint = "viewStatus";
        const response = await getDataHandlerAPI({ endPoint: endpoint, token: data });
        if (
          response?.data !== undefined &&
          response?.data?.responseCode !== undefined
        ) {
          if (response.data.responseCode === 200) {
            setWelcomePop(response?.data?.result?.status);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logOutHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.logOut,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        params: {
          fireToken: fireToken,
        },
      });
      if (res) {
        window.localStorage.removeItem("token");
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (search) {
      productlist();
    }
  }, [search]);
  useEffect(() => {
    if (search1) {
      if (PathVariableName === ("market" || "market-services")) {
        listservice();
      }
    }
  }, [search1, PathVariableName]);
  useEffect(() => {
    if (PathVariableName === ("market-pets" || "market" || "pets")) {
      petLisiting();
    }

    if (PathVariableName === ("market" || "market-services")) {
      listservice();
    }
  }, [PathVariableName]);
  let data = {
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    setTimeLeft,
    listStorydata,
    setEndtime,
    setSelectedId,
    homeList,
    homeList1,
    eventData,
    chatMessageData,
    currentLocation,
    setChatMessageData,
    setCurrentLocation,
    setLat,
    setLong,
    _Lat,
    address,
    _Long,
    locationdata,
    setLocationdata,
    welcomePop,
    _pettypecategory,
    setCounrty,
    setState,
    setCity,
    setMiles,
    setBreed,
    tabView_pet,
    likesData_pet,
    isLoading_pet,
    page_pet,
    noOfPages_pet,
    setPage_pet,
    setNoOfPages_pet,
    likesData_service,
    isLoading_service,
    page_service,
    noOfPages_service,
    setNoOfPages_service,
    likesData_product,
    isLoading_product,
    page_product,
    noOfPages_product,
    isLoading_intrest,
    page_intrest,
    noOfPages_intrest,
    typesdata_intrest,
    likesData_intrest,
    likesData_soctorvet,
    isLoading_soctorvet,
    noOfPages_soctorvet,
    _applyfilter,
    _filterType,
    setFilterTypePage,
    setProductCategory,
    setServiceCategory,
    productdata,
    getGetProductCategory,
    getGetServiceCategory,
    petdata,
    servicedata,
    productList,
    servicelist,
    isLoading1,
    loader,
    serviceCategory,
    isLoadinghome,
    _filterTypePage,
    search,
    setSearch,
    search1,
    setSearch1,
    _currencyCode,
    _showinformation,
    _homepagination,
    hometotalPages,
    hometotalCurrentPage,
    setHomePagination: (page) => setHomePagination(page),
    setPage_service: () => setPage_service(),
    mylikeListHndler: () => mylikeListHndler(),
    petLisiting: () => petLisiting(),
    productlist: () => productlist(),
    listservice: (id) => listservice(id),
    CloseApplyFilterFUnction: () => CloseApplyFilterFUnction(),
    ApplyFunction: (id) => ApplyFunction(id),
    setDeletePost: (id) => setDeletePost(id),
    setPage_intrest: (p) => setPage_intrest(p),
    setNoOfPages_product: (p) => setNoOfPages_product(p),
    settypesdata_intrest: (values) => settypesdata_intrest(values),
    mylikeListHndler_service: () => mylikeListHndler_service(),
    mylikeListHndler_pet: (value) => mylikeListHndler_pet(value),
    mylikeListHndler_product: () => mylikeListHndler_product(),
    handleUserModal: handleUserModal,
    handleRemovePost: (Id) => handleRemovePost(Id),
    getHomePagePosts: getHomePagePosts,
    handleLocatonFilter: handleLocatonFilter,
    handleAddressChange: handleAddressChange,
    handleSelect: handleSelect,
    ChangeCurrentlocation: ChangeCurrentlocation,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    handleStyorylistApi: (data) => handleFormSubmit(data),
    homePagePostListHandler: (token, OLD_DATA, page) =>
      homePagePostListHandler(token, OLD_DATA, page),
    logOutHandler: (data) => logOutHandler(data),
    listEventHandler: (data) => listEventHandler(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    category,
    subCategory,
  };
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
