import React, { useContext, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  makeStyles,
  Typography,
  TextField,
} from "@material-ui/core";
import { MdCancel } from "react-icons/md";
import Axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { toast } from "react-toastify";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { AuthContext } from "src/context/Auth";
import { localeFunction } from "src/utils";
const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    color: "#6fcfb9",
    right: 0,
  },

  textField: {
    "& .MuiOutlinedInput-inputMultiline": {
      paddingTop: "10px",
    },
  },
  ReportTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
}));

export default function ShareModal({
  openReport,
  handleCloseReport,
  reportData,
  handleHidePost,
  postId,
  type,
  setOpenModal,
  deletePostFuntion,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // console.log('sdkjfsdjf',reportData)
  const reportHandler = async (id) => {
    if (type === "hidepost") {
      handleHidePost(postId);
    } else if (type === "deletepost") {
      deletePostFuntion(postId);
    } else {
      if (auth?.userData?._id === reportData?.userId?._id) {
        toast.warn(localeFunction("feed_report_warn_text"));
      } else {
        if (description === "") {
          toast.warn(localeFunction("feed_report_warn_text1"));
        } else {
          try {
            setIsLoading(true);
            const res = await Axios({
              method: "POST",
              url: Apiconfigs.createReport,
              headers: {
                token: sessionStorage.getItem("token"),
              },
              data: {
                postId: !reportData?.eventName ? postId : undefined,
                eventId: reportData?.eventName ? reportData?._id : undefined,
                description: description,
              },
            });
            if (res.data.responseCode === 200) {
              if (!reportData?.eventName) {
                auth.homePagePostListHandler(
                  window.sessionStorage.getItem("token"),
                  [],
                  1
                );
              } else {
                auth.listEventHandler();
              }
              handleCloseReport();
              toast.success(res.data.responseMessage);
              setIsLoading(false);
              setOpenModal(false);
            }
            setIsLoading(false);
          } catch (error) {
            setIsLoading(false);
          }
        }
      }
    }
  };
  return (
    <Box>
      <Dialog
        open={openReport}
        onClose={() => {
          if (!isLoading) {
            handleCloseReport();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" className={classes.ReportTitle}>
          {type === "hidepost"
            ? `${localeFunction("feed_hide_text")}`
            : type === "deletepost"
            ? "Delete post"
            : `${localeFunction("feed_reportPost_text")}`}
        </DialogTitle>
        <DialogContent>
          <IconButton className={classes.cancelBtn} onClick={handleCloseReport}>
            <MdCancel />
          </IconButton>

          <Box mb={3}>
            <Typography
              variant="body2"
              style={{
                fontSize: "12px",
                padding: "0 25px",
                wordBreak: "break-word",
                fontWeight: "600",
              }}
            >
              {type === "hidepost"
                ? `${localeFunction("feed_alert_hide_post_text")}`
                : type === "deletepost"
                ? `${localeFunction("my_Post_text_1")}`
                : `${localeFunction("feed_alert_remove_post_text")}`}
            </Typography>
            {type !=="hidepost" && type !=="deletepost" && (
              <Box mt={2} p={"0 25px"}>
                <formControl fullWidth className={classes.textField}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="Text Field"
                    placeholder={localeFunction(
                      "feed_describe_placeholder_text"
                    )}
                    type="text"
                    fullWidth
                    disabled={isLoading}
                    multiline
                    rowsMax={5}
                    rows={5}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </formControl>
              </Box>
            )}
          </Box>
          <Box mb={2} align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseReport}
              disabled={isLoading}
            >
              {localeFunction("feed_cancel_btn_text")}
            </Button>
            <Button
              onClick={() => reportHandler(reportData?._id)}
              variant="contained"
              disabled={isLoading}
              color="secondary"
              style={{ marginLeft: "10px" }}
            >
              {type === "hidepost"
                ? `${localeFunction("feed_hide_text")}`
                : type === "deletepost"
                ? `${localeFunction("Delete")}`
                : `${localeFunction("feed_reportPost_text")}`}
              {isLoading && <ButtonCircularProgress />}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
