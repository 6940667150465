import { Box, Typography } from "@material-ui/core";
import React from "react";
import { localeFunction } from "src/utils";

export default function NodatafoundImage({ dataFound, type }) {
  const Message = (type) => {
    switch (type) {
      case "follower":
        return `${localeFunction("No_Followers")}`;
      case "following":
        return `${localeFunction("No_Followers")}`;
      case "request":
        return `${localeFunction("your_have_no_requist")}`;
      case "post":
        return `${localeFunction("No_post_available")}`;
      case "pet":
        return `${localeFunction("no_pet_available")}`;
      case "otherPost":
        return `${localeFunction("No_post")}`;
      case "otherPet":
        return `${localeFunction("no_pet_available")}`;
      case "comment":
        return `${localeFunction("No_comment")}`;
      case "reward":
        return `${localeFunction("no_comment_show")}`;
      case "suggestion":
        return `${localeFunction("no_requist")}`;
      case "notification":
        return `${localeFunction("notification")}`;
      case "search":
        return `${localeFunction("No_search_results")}`;
      case "follow":
        return `${localeFunction("no_Followers")}`;
      case "following":
        return `${localeFunction("no_following")}`;
      case "followers":
        return `${localeFunction("no_request")}`;
      case "request":
        return `${localeFunction("no_requist_available")}`;
      case "MISSING":
        return `${localeFunction("missing_pet")}`;
      case "MYMISSING":
        return `${localeFunction("no_pet_missing")}`;

      default:
        return `${localeFunction("No_data")}`;
    }
  };
  return (
    <Box align="center" mt={4} mb={5} width="100%">
      {" "}
      <Box mt={3}>
        <Typography
          variant="h6"
          style={{
            marginTop: "-15px",
          }}
        >
          {dataFound ? dataFound : Message(type)}
        </Typography>
      </Box>
    </Box>
  );
}
