/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import GlobalSearchField from "src/component/GlobalSearchField";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  FormControl,
  TextField,
  Hidden,
  List,
  InputAdornment,
  IconButton,
  ListSubheader,
  makeStyles,
  Divider,
  Typography,
  Avatar,
} from "@material-ui/core";
import { AuthContext } from "src/context/Auth";
import { localeFunction } from "src/utils";
import { IoSearchOutline } from "react-icons/io5";
import Logo from "src/component/Logo";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import { BsHeartFill } from "react-icons/bs";
import { MdOutlineTrackChanges } from "react-icons/md";
import { MdEmojiEvents } from "react-icons/md";
import { AiFillContacts, AiOutlineHome } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaSearchengin } from "react-icons/fa";
import { RiTodoFill } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { BsHeartHalf } from "react-icons/bs";
import { MdPets } from "react-icons/md";
import NavItem from "./NavItem";
import HomeIcon from "@material-ui/icons/Home";
const drawerSections = [
  {
    items: [

      {
        title: `${localeFunction("nav_drawer_text")}`,
        icon: MdPets,
        items: [
          {
            title: `${localeFunction("nav_drawer_text_2")}`,
            icon: BsHeartHalf,
            href: "/favorites",
          },
          {
            title: `${localeFunction("nav_drawer_text_4")}`,
            icon: BsHeartFill,
            href: "/interested",
          },
          {
            title: `${localeFunction("nav_drawer_text_6")}`,
            icon: FaSearchengin,
            href: "/missing-pets",
          },
          {
            title: `${localeFunction("nav_drawer_text_7")}`,
            icon: MdOutlineTrackChanges,
            href: "/tracking-devices",
          },
        ]
      },
      {
        title: `${localeFunction("nav_drawer_text_13")}`,
        icon: LocalHospitalIcon,
        items: [
          {
            title: `${localeFunction("nav_drawer_text_13")}`,
            icon: LocalHospitalIcon,
            href: "/market-doctor",
          },
          {
            title: `${localeFunction("nav_drawer_text_12")}`,
            icon: MdEmojiEvents,
            href: "/list-appointment",
          },
          {
            title: `${localeFunction("nav_drawer_text_14")}`,
            icon: LocalHospitalIcon,
            href: "/blocked-doctor",
          },
        ]

      },
      {
        title: `${localeFunction("nav_drawer_text_1")}`,
        icon: GiReceiveMoney,
        items: [
          {
            title: `${localeFunction("nav_drawer_text_1")}`,
            icon: GiReceiveMoney,
            href: "/market",
          },
          {
            title: `${localeFunction("nav_drawer_text_8")}`,
            icon: GiReceiveMoney,
            href: "/rewards",
          },

        ]
      },
      {
        title: `${localeFunction("nav_drawer_text_5")}`,
        icon: MdEmojiEvents,
        href: "/events",
      },
      {
        title: `${localeFunction("nav_drawer_text_3")}`,
        icon: RiTodoFill,
        href: "/pets",
      },
      {
        title: `${localeFunction("nav_drawer_text_9")}`,
        icon: BsFillInfoCircleFill,
        href: "/about-us",
      },
      {
        title: `${localeFunction("nav_drawer_text_10")}`,
        icon: AiFillContacts,
        href: "/contact-us",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}
function reduceChildRoutes({ acc, pathname, item, depth }) {
  if (pathname.slice(1) !== "interested" && pathname.slice(1) !== "favorites") {
    window.localStorage.removeItem("TAB_NAME");
  }
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.img}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.img}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 270,
    top: 72,
    left: "30px",
    height: "100%",
    background: "#f0f2f5",
    borderRight: "0",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [openSearch, setOpenSearch] = React.useState(false);
  const auth = useContext(AuthContext);
  const handleClickOpenSearch = () => {
    setOpenSearch(true);
  };
  const sections = [
    {
      items: [
        {
          title: `${localeFunction("top_serach_field_text_7")}`,
          img: AiOutlineHome,
          href: "/feeds",
        },
        {
          title: `${auth?.userData?.name
            ? auth?.userData?.name
            : ""
            }`,
          img: `${auth?.userData?.profilePic
            ? auth?.userData?.profilePic
            : "images/profile-placeholder.jpeg"
            }`,
          href: "/view-profile",
        },
        {
          title: `${localeFunction("nav_drawer_text")}`,
          img: "./images/petimagenew.png",
          items: [
            {
              title: `${localeFunction("nav_drawer_text_2")}`,
              img: "./images/StarIMage.svg",
              href: "/favorites",
            },
            {
              title: `${localeFunction("nav_drawer_text_4")}`,
              img: "./images/intrerest.png",
              href: "/interested",
            },
            {
              img: "./images/PetSearch.svg",
              title: `${localeFunction("nav_drawer_text_6")}`,
              href: "/missing-pets",
            },
            {
              title: `${localeFunction("nav_drawer_text_3")}`,
              img: "./images/PetsIMage2.svg",
              href: "/pets",
            },
            {
              title: `${localeFunction("nav_drawer_text_7")}`,
              img: "./images/tr.png",
              href: "/tracking-devices",
            },
          ],
        },
        {
          title: `${localeFunction("nav_drawer_text_13")}`,
          img: "./images/hospitalimage.jpg",
          items: [
            {
              title: `${localeFunction("nav_drawer_text_13")}`,
              img: "./images/output_image.jpg",
              href: "/market-doctor",
            },
            {
              title: `${localeFunction("nav_drawer_text_14")}`,
              img: "./images/output_image.jpg",
              href: "/blocked-doctor",
            },
            {
              title: `${localeFunction("nav_drawer_text_12")}`,
              img: "./images/Event (1).png",
              href: "/list-appointment",
            },
          ],
        },
        {
          title: `${localeFunction("nav_drawer_text_1")}`,
          img: "./images/marketimage.jpg",
          items: [
            {
              title: `${localeFunction("nav_drawer_text_1")}`,
              img: "./images/Market.png",
              href: "/market",
            },
            {
              title: `${localeFunction("nav_drawer_text_8")}`,
              img: "./images/reward.png",
              href: "/rewards",
            },
          ]
        },
        {
          title: `${localeFunction("nav_drawer_text_5")}`,
          img: "./images/Event (1).png",
          href: "/events",
        },
        {
          title: `${localeFunction("nav_drawer_text_11")}`,
          img: "./images/animalcarenew.png",
          href: "/play-game",
        },
        {
          title: `${localeFunction("feed_aboutUs_text")}`,
          img: "./images/AboutUsImage.svg",
          href: "/about-us",
        },
        {
          title: `${localeFunction("feed_contactUs_text")}`,
          img: "./images/contact_number_book_1_side 2.png",
          href: "/contact-us",
        },
      ],
    },
  ];
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={4} pb={9}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  const drawerSections = [
    {
      items: [
        {
          title: `${localeFunction("nav_drawer_text")}`,
          icons: MdPets,
          items: [
            {
              title: `${localeFunction("nav_drawer_text_2")}`,
              icon: BsHeartHalf,
              href: "/favorites",
            },
            {
              title: `${localeFunction("nav_drawer_text_4")}`,
              icon: BsHeartFill,
              href: "/interested",
            },
            {
              title: `${localeFunction("nav_drawer_text_6")}`,
              icon: FaSearchengin,
              href: "/missing-pets",
            },
            {
              title: `${localeFunction("nav_drawer_text_7")}`,
              icon: MdOutlineTrackChanges,
              href: "/tracking-devices",
            },
          ],
        },
        {
          title: `${localeFunction("nav_drawer_text_13")}`,
          icon: LocalHospitalIcon,
          href: "/market-doctor",
          items: [
            {
              title: `${localeFunction("nav_drawer_text_13")}`,
              icon: LocalHospitalIcon,
              href: "/market-doctor",
            },
            {
              title: `${localeFunction("nav_drawer_text_12")}`,
              icon: MdEmojiEvents,
              href: "/list-appointment",
            },
            {
              title: `${localeFunction("nav_drawer_text_14")}`,
              icon: LocalHospitalIcon,
              href: "/blocked-doctor",
            },
          ]
        },
        {
          title: `${localeFunction("nav_drawer_text_1")}`,
          icon: GiReceiveMoney,
          href: "/market",
          items: [
            {
              title: `${localeFunction("nav_drawer_text_1")}`,
              icon: GiReceiveMoney,
              href: "/market",
            },
            {
              title: `${localeFunction("nav_drawer_text_8")}`,
              icon: GiReceiveMoney,
              href: "/rewards",
            },
          ]
        },
        {
          title: `${localeFunction("nav_drawer_text_5")}`,
          icon: MdEmojiEvents,
          href: "/events",
        },

        {
          title: `${localeFunction("nav_drawer_text_3")}`,
          icon: RiTodoFill,
          href: "/pets",
        },
        {
          title: `${localeFunction("nav_drawer_text_9")}`,
          icon: BsFillInfoCircleFill,
          href: "/about-us",
        },
        {
          title: `${localeFunction("nav_drawer_text_10")}`,
          icon: AiFillContacts,
          href: "/contact-us",
        },
      ],
    },
  ];
  const drawerContent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={1} align="center">
          <Logo width="175" />
        </Box>
        <Divider />
        <Box p={2}>
          <FormControl fullWidth>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="Search"
              onClick={handleClickOpenSearch}
              type="search"
              placeholder={localeFunction("Add_Missing_Pets_29")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start" onClick={handleClickOpenSearch}>
                      <IoSearchOutline />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <GlobalSearchField
              handleCloseEdit={handleCloseSearch}
              openEdit={openSearch}
            />
          </FormControl>
        </Box>
        <Box>
          {drawerSections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        // class="visible-scrollbar"
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        // class="visible-scrollbar"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
